/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const getOrderDetails = (data) => {
  return Api.post("orders", data).then((res) => res.data);
};
export const customerDetail = (id) => {
  return Api.get("customer/" + id).then((res) => res.data);
};
export const updateCustomer = (customerData) => {
  return Api.put("customer", customerData).then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
