/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Collapse } from "reactstrap";
import logo from "../../assets/images/logo.png";
import appStore from "../../assets/images/app_store.png";
import playstore from "../../assets/images/play_store.png";
import CopyRight from "./common/copyright";
import { Link } from "react-router-dom";
const FooterTwo = (props) => {
  const [isOpen, setIsOpen] = useState();
  const [collapse, setCollapse] = useState(0);
  const width = window.innerWidth < 750;
  useEffect(() => {
    const changeCollapse = () => {
      if (window.innerWidth < 750) {
        setCollapse(0);
        setIsOpen(false);
      } else setIsOpen(true);
    };

    window.addEventListener("resize", changeCollapse);

    return () => {
      window.removeEventListener("resize", changeCollapse);
    };
  }, []);
  return (
    <footer className="footer-light">
      <section className="section-b-space light-layout">
        <Container>
          <Row className="footer-theme partition-f row">
            <div className="col-md-5 col-lg-3 footer-new">
              <div
                className={`footer-title ${
                  isOpen && collapse == 1 ? "active" : ""
                } footer-mobile-title`}
              >
                <h4
                  onClick={() => {
                    setCollapse(1);
                    setIsOpen(!isOpen);
                  }}
                >
                  about
                  <span className="according-menu"></span>
                </h4>
              </div>
              <Collapse
                isOpen={width ? (collapse === 1 ? isOpen : false) : true}
                className="footer-center"
              >
                <div className="footer-contant">
                  <div className="footer-logo">
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      <img src={logo} alt="" className="img-fluid" />
                    </Link>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="offset-xl-1 col">
              <div className="sub-title">
                <div
                  className={`footer-title ${
                    isOpen && collapse == 2 ? "active" : ""
                  } `}
                >
                  <h4
                    onClick={() => {
                      setCollapse(2);
                      setIsOpen(!isOpen);
                    }}
                  >
                    my account<span className="according-menu"></span>
                  </h4>
                </div>
                <Collapse
                  isOpen={width ? (collapse === 2 ? isOpen : false) : true}
                >
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link
                          to={{
                            pathname: "/",
                          }}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          target='blank'
                          to={{
                            pathname: process.env.REACT_APP_PRIVACY_POLICY_URL,
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          target='blank'
                          to={{
                            pathname: process.env.REACT_APP_TERMS_OF_USE_URL,
                          }}
                        >
                          Terms & Condition
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
            <div class="col">
              <div class="sub-title">
                <div
                  className={`footer-title ${
                    isOpen && collapse == 4 ? "active" : ""
                  }`}
                >
                  <h4
                    onClick={() => {
                      setCollapse(4);
                      setIsOpen(!isOpen);
                    }}
                  >
                    Contact Us<span className="according-menu"></span>
                  </h4>
                </div>

                <Collapse
                  isOpen={width ? (collapse === 4 ? isOpen : false) : true}
                >
                  <div class="footer-contant">
                    <ul class="contact-list">
                      <li>
                        <i class="fa fa-map-marker"></i>PO Box 851 Prosper TX 75078 
                      </li>
                      <li>
                        <i class="fa fa-phone"></i>Call Us: 469-261-8179
                      </li>
                      <li>
                        <i class="fa fa-envelope-o"></i>Email Us:{" "}
                        <a style={{ "text-transform":"none" }}>support@buygroup.com</a>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
            <div class="col">
              <div class="sub-title">
                <div
                  className={`footer-title ${
                    isOpen && collapse == 3 ? "active" : ""
                  } `}
                >
                  <h4
                    onClick={() => {
                      setCollapse(3);
                      setIsOpen(!isOpen);
                    }}
                  >
                    Download Now on<span className="according-menu"></span>
                  </h4>
                </div>

                <Collapse
                  isOpen={width ? (collapse === 3 ? isOpen : false) : true}
                >
                  <div class="footer-contant">
                    <ul className="">
                      <li className="download_icon">
                        <a href={process.env.REACT_APP_APP_STORE_URL}>
                          <img src={appStore} alt="not Found" />
                        </a>
                      </li>
                      <li className="download_icon">
                        <a href={process.env.REACT_APP_PLAY_STORE_URL}>
                          <img src={playstore} alt="not Found" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <CopyRight layout={props.layoutClass} />
    </footer>
  );
};

export default FooterTwo;
/* Copyright Information: Created by BuyGroup */