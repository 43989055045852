/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { isLoggedIn } from "../utils/index";
import { connect } from "react-redux";
import { getCart, deleteCart, editCart } from "../actions/cart";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();

const Cart = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [disabledCheckoutButton, setDisabledCheckoutButton] = useState(false);
  const [BillingAddressId, setBillingAddressId] = useState();
  const updateQuantityTimeout = useRef(null);

  useEffect(() => {
    if (isLoggedIn()) {
      console.log("BillingAddressId", BillingAddressId);
      props.getCart(getCartCallBack, BillingAddressId);
    } else {
      props.history.push(`/login`);
    }
  }, [BillingAddressId]);

  const getCartCallBack = (res) => {
    setIsLoading(false);
  };

  const deleteCart = (id) => {
    setIsLoading(true);
    props.deleteCart(id, removeCartCallBack);
  };

  const removeCartCallBack = () => {
    toast.success("Successfully removed an item from your cart.", {
      autoClose: 3000,
      hideProgressBar: true,
    });
    props.getCart(getCartCallBack);
  };

  const truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const updateQuantity = (e, id, buy_group_id, prevValue) => {
    clearTimeout(updateQuantityTimeout.current);
    updateQuantityTimeout.current = setTimeout(() => {
      if (e.target.value > 0) {
        setDisabledCheckoutButton(true);
        if (prevValue !== e.target.value) {
          e.target.parentElement.parentElement.classList.add("disabled_row");
          const data = {
            qty: e.target.value,
            group_id: buy_group_id,
            cart_item_id: id,
          };
          props.editCart(id, data, updateCartCallBack, e);
        } else {
          setDisabledCheckoutButton(false);
        }
      } else {
        setDisabledCheckoutButton(false);
        toast.error("Not allowed negative value.", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        e.target.value = prevValue;
      }
    }, 500);
  };

  const updateCartCallBack = (e, res) => {
    if (res.status === 200) {
      setTimeout(() => {
        e.target.parentElement.parentElement.classList.remove("disabled_row");
        toast.success(res.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }, 1000);
    } else {
      setTimeout(() => {
        e.target.parentElement.parentElement.classList.remove("disabled_row");
        toast.error(res.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }, 1000);
    }
    setDisabledCheckoutButton(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="col-md-12">
          <div className="custom-loader">
            <div role="status" animation="border" className="spinner-border">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <p className="text-center mt-3">Please wait...</p>
        </div>
      ) : props.cart.line_item_list.length > 0 ? (
        <div>
          <div className="theme-card theme-card-icon">
            <h5 className=""></h5>
            <div className="hidden">
              <i className="fa fa-th grid-layout-view mr-2"></i>
              <i className="fa fa-list-ul list-layout-view"></i>
            </div>
          </div>
          <div className="cart_listing cart-section">
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table cart-table ">
                    <thead>
                      <tr className="table-head">
                        <th
                          style={{
                            width: "100px",
                          }}
                        >
                          Image
                        </th>
                        <th className="text-left">Product Name</th>
                        <th>Price</th>
                        <th
                          style={{
                            width: "70px",
                          }}
                        >
                          Quantity
                        </th>
                        <th>Total</th>
                        <th>Shipping</th>
                        <th>Tax Amount</th>
                        <th>Action</th>
                        <th
                          style={{
                            width: "100px",
                          }}
                        >
                          Grand Total
                        </th>
                      </tr>
                    </thead>

                    {isLoading ? (
                      <tbody>
                        <tr className="text-center table_loader">
                          <td colSpan="8">
                            <div className="custom-loader">
                              <Spinner animation="border" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {props.cart.line_item_list.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="clearfix">
                                  <img
                                    className="img-responsive"
                                    src={item.buy_group.product_image}
                                    alt={item.buy_group.product_image}
                                  />
                                </div>
                              </td>
                              <td className="text-left">
                                <Link to={`/product/${item.buy_group.id}`}>
                                  {truncateString(item.buy_group.name, 90)}
                                </Link>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                $
                                {parseFloat(item.price).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="number"
                                  defaultValue={item.qty}
                                  name="item_qty"
                                  min="1"
                                  onKeyDown={blockInvalidChar}
                                  onChange={(e) =>
                                    updateQuantity(
                                      e,
                                      item.cart_item_id,
                                      item.buy_group.id,
                                      item.qty
                                    )
                                  }
                                />
                              </td>
                              <td style={{ textAlign: "right" }}>
                                $
                                {parseFloat(
                                  item.qty * item.price
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                $
                                {parseFloat(item.shipping).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                $
                                {parseFloat(item.tax_price).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                <i
                                  onClick={() => deleteCart(item.cart_item_id)}
                                  className="cart-table-remove-icon fa fa-times-circle"
                                ></i>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                $
                                {parseFloat(
                                  item.price * item.qty +
                                    item.tax_price +
                                    parseInt(item.shipping)
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
                {!isLoading ? (
                  <table className="table cart-table table-responsive-md">
                    <tfoot>
                      <tr>
                        <td>total price :</td>
                        <td style={{ textAlign: "right" }}>
                          <h2>
                            $
                            {parseFloat(props.cart.grand_total).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </h2>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                ) : null}
              </div>
            </div>
            {!isLoading ? (
              <div className="cart-buttons row">
                <div className="col-7">
                  <Link className="btn btn-solid" to="/">
                    continue shopping
                  </Link>
                </div>
                <div className="col-5">
                  <Link
                    className={`btn btn-solid ${
                      disabledCheckoutButton ? "button_disabled" : ""
                    }`}
                    to={{
                      pathname: "/checkout",
                      state: {
                        cart: props.cart,
                      },
                    }}
                    disabled={disabledCheckoutButton}
                  >
                    check out
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          <div style={{ textAlign: "center" }}>
            <h3>Your cart is empty</h3>
            <Link className="btn btn-solid" to="/">
              continue shopping
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  getCart,
  deleteCart,
  editCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
