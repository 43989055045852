/* Copyright Information: Created by BuyGroup */
import { FETCH_DATA_SUCCESS_CATEGORY } from "./actions";
import { categoryList } from "../services/CategoryServices";

export const fetchCategorySuccess = (data) => ({
  type: FETCH_DATA_SUCCESS_CATEGORY,
  category: data,
});

/* This function call category List Api */
export const getCategory = (callBack) => {
  return (dispatch) => {
    categoryList()
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCategorySuccess(res.data));
          callBack(res.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
};
/* Copyright Information: Created by BuyGroup */