/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { updateCustomer } from "../services/AccountServices";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { City } from "country-state-city";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import Select from "react-select";
import PhoneInput from "react-phone-number-input/input";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
  Button,
  Col,
} from "reactstrap";
const UpdateAddress = (props) => {
  const {
    openmodal,
    initialValues,
    UpdateBillingAddress,
    colsedModal,
    isLoadingButton,
  } = props;

  const history = useHistory();
  let location = useLocation();

  // /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState(initialValues.phone_number);

  /* const "genderValue" store  selected gender Value   */
  const [genderValue, setGenderValue] = useState();

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  const [isDisableUpdate, setIsDisableUpdate] = useState(false);

  const genderOption = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  useEffect(() => {
    getCountryList();
    getStateList();
    getCityList();
  }, []);

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /* This function will  set all state for default country  */

  const getStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.country) {
        return country;
      }
    });
    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;
    setCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.country,
    });
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.country) {
        return country;
      }
    });
    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;
    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });
    const defaultstateIsoCode = getdefaultState[0].isoCode;
    setStatesValues({ vlaue: defaultstateIsoCode, label: initialValues.state });
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode,
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("country", selectedCountry.label);
    setFieldTouched("country", false);
    setFieldValue("state", "");
    setFieldValue("city", "");

    const countryisoCode = selectedCountry.value;

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode == countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("state", selectedState.label);
    setFieldValue("city", "");
    setFieldTouched("state", false);

    const stateisoCode = selectedState.value;

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("city", selectCity.label);
    setFieldTouched("city", false);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   */

  const handlePhoneNumber = (selectphone, setFieldValue, setFieldTouched) => {
    setPhoneValue(selectphone);
    setFieldValue("phone_number", selectphone);
  };

  /** / This function handle gender value
   * @param  {genderValue} genderValue string value
   */

  /* This Function update customer detail */

  const Submit = (customerAddress) => {
    UpdateBillingAddress(customerAddress);
  };
  console.log("openmodal", openmodal);
  return (
    <Modal
      isOpen={openmodal}
      toggle={colsedModal}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={colsedModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Supplier
        </h5>
      </ModalHeader>

      <Formik initialValues={initialValues} onSubmit={Submit}>
        {({ setFieldValue, handleBlur, values, setFieldTouched, errors }) => (
          <Form>
            <ModalBody>
              <h4>Billing Address</h4>
              {console.log("errors", errors)}
              {console.log("value", values)}
              <FormGroup>
                <Label className="col-form-label">First Name</Label>
                <Field
                  type="text"
                  className="form-control"
                  id="first_name"
                  name="first_name"
                />
                <ErrorMessage name="first_name">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Last Name</Label>
                <Field
                  type="text"
                  className="form-control"
                  id="last_name"
                  name="last_name"
                />
                <ErrorMessage name="last_name">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Comapny Name</Label>
                <Field
                  type="text"
                  className="form-control"
                  id="company_name"
                  name="company_name"
                />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Title</Label>
                <Field
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Phone Number
                </Label>

                <PhoneInput
                  country="US"
                  value={phoneValue}
                  onBlur={handleBlur}
                  id="phone_number"
                  maxLength={14}
                  name="phone_number"
                  className="form-control"
                  onChange={(value) =>
                    handlePhoneNumber(value, setFieldValue, setFieldTouched)
                  }
                />
                {errors ? (
                  <label className="err-msg">{errors.phone_number}</label>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Email</Label>

                <Field
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  disabled
                />

                <ErrorMessage name="email">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Country
                </Label>

                <Select
                  value={countriesValue}
                  noOptionsMessage={() => "No country found"}
                  defaultValue={{ value: "", label: initialValues.country }}
                  onChange={(value) =>
                    handleCountry(value, setFieldValue, setFieldTouched)
                  }
                  options={countries}
                  name="country"
                  placeholder="Select Country"
                />

                <ErrorMessage name="country">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>{" "}
              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  State
                </Label>

                <Select
                  value={statesValues}
                  noOptionsMessage={() =>
                    noDataStateMessage
                      ? "No State Found"
                      : "First select country"
                  }
                  defaultValue={{ value: "", label: initialValues.state }}
                  onChange={(value) =>
                    handleState(value, setFieldValue, setFieldTouched)
                  }
                  options={filterStates}
                  name="state"
                  placeholder="Select State"
                />

                <ErrorMessage name="state">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  City
                </Label>

                <Select
                  value={cityValues}
                  defaultValue={{ value: "", label: initialValues.city }}
                  noOptionsMessage={() =>
                    noDataCityMessage ? "No City Found" : "First select state"
                  }
                  onChange={(value) =>
                    handleCity(value, setFieldValue, setFieldTouched)
                  }
                  options={filterCity}
                  name="city"
                  placeholder="Select City"
                />

                <ErrorMessage name="city">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Street</Label>

                <Field
                  type="text"
                  className="form-control"
                  id="street"
                  name="street"
                />

                <ErrorMessage name="street">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Zip Code</Label>
                <Field
                  type="text"
                  id="zip_code"
                  className="form-control"
                  name="zip_code"
                />
                <ErrorMessage name="zip_code">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              {/* <Button color="primary" type="submit" disabled={isLoadingButton}>
                {isLoadingButton ? "Please Wait..." : "Add"}
              </Button> */}

              <button
                className="btn btn-solid "
                type="submit"
                style={{padding:"4px"}}
                disabled={isLoadingButton}
              >
                 {isLoadingButton ? "Please Wait..." : "Update"}
              </button>

              <Button type="button" color="light" onClick={colsedModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdateAddress;
/* Copyright Information: Created by BuyGroup */