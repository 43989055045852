/* Copyright Information: Created by BuyGroup */
import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
const EditCard = (props) => {
  /* const "cardChecked" store information about checkbox */
  const [cardChecked, setCardChecked] = useState(props.defaultCardStatus);

  /* This Function set card default */
  const handledefaultCard = (e, setFieldValue) => {
    const defaultCard = JSON.parse(e.target.value);
    setCardChecked(!defaultCard);
    setFieldValue("default_cart", !defaultCard);
  };

  return (
    <Modal className="add_card_popup" isOpen={props.openEditCard} centered>
      <ModalHeader toggle={props.hideModal}>Update Card</ModalHeader>
      <Formik
        initialValues={{
          id: props.editCardId,
          default_cart: props.defaultCardStatus,
        }}
        onSubmit={(values) => {
          props.UpdateCard(values);
        }}
        validationSchema={Yup.object().shape({
          default_cart: Yup.bool().oneOf([true], "please select checkbox"),
        })}
      >
        {({ setFieldValue }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Field name="id" type="hidden"></Field>
              </FormGroup>
              <FormGroup>
                <Field
                  name="default_cart"
                  type="checkbox"
                  value={cardChecked}
                  checked={cardChecked}
                  onChange={(e) => handledefaultCard(e, setFieldValue)}
                ></Field>

                <Label>Use this as default card for payment</Label>
                <div>
                  <ErrorMessage name="default_cart">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </div>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Button
                className="btn btn-solid btn-xs"
                type="submit"
                disabled={props.editcardLoading}
              >
                {props.editcardLoading ? "Please Wait..." : "Update"}
              </Button>

              <Button
                type="button"
                className="btn btn-secondary btn-xs"
                onClick={props.closedEditCard}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditCard;
/* Copyright Information: Created by BuyGroup */
