/* Copyright Information: Created by BuyGroup */
import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Button, FormGroup, Label, Row, Col, Container } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../../services/AuthServices";
import { getAuthRedirectUrl } from "../../utils/General";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
const Login = () => {
  const history = useHistory();

  // This is used for loading and disable the button of login
  const [isLoading, setLoading] = useState(false);
  //This is used for error message
  const [errorMessage, setErrorMessage] = useState("");

  /**  This function will call on Login button click
   *@param {userData} userData
   */
  const onSubmit = (user, onSubmitProps) => {
    setLoading(true);
    login(user)
      .then((response) => {
        if (response !== undefined && response.token !== undefined) {
          setLoading(false);
          localStorage.setItem("token", response.token);
          console.log("response", response);
          const shipping_zip_code = response.zip_code;
          localStorage.setItem("user", JSON.stringify(response));
          localStorage.setItem("shipping_zip_code", response.zip_code);
          let role = [];
          role.push(response.role);
          localStorage.setItem("role", JSON.stringify(role));
          history.push(getAuthRedirectUrl());
          /*    // window.location.reload(); */
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
        // onSubmitProps.resetForm();
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <section className="login-page section-b-space">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center" style={{ marginBottom: "25px" }}>
                  <img src={logo} alt="" className="img-fluid" />
                </div>
                <h3 className="text-center">Login</h3>
                <p className="text-center lead_login_txt">
                  Doesn't have an account yet?{" "}
                  <Link
                    to={{
                      pathname: "/register",
                    }}
                  >
                    Register
                  </Link>
                </p>
                <div className="theme-card">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={(values, onSubmitProps) => {
                      onSubmit(values, onSubmitProps);
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Please enter a valid email")
                        .required("Please enter email address"),
                      password: Yup.string().required("Please enter password"),
                    })}
                  >
                    {(props) => {
                      const { handleSubmit } = props;
                      return (
                        <Form
                          onSubmit={handleSubmit}
                          className="form-horizontal auth-form"
                        >
                          {errorMessage && (
                            <p className="alert alert-danger">{errorMessage}</p>
                          )}

                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Email
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="email"
                            />
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Password
                            </Label>
                            <Field
                              type="password"
                              className="form-control"
                              name="password"
                            />
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                          <Row className="align-items-center mt-4 my_account_footer">
                            <Col md="6">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                              >
                                {isLoading ? "Loading.." : "Login"}
                              </Button>
                            </Col>
                            <Col md="6" className="forgot-link">
                              <Link to="/forgot-password">Forgot Password</Link>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default withRouter(Login);
/* Copyright Information: Created by BuyGroup */
