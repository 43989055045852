/* Copyright Information: Created by BuyGroup */
import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";


const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div class="policy-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div className="logo-format">
              <img src={logo} alt="" className="img-fluid" />
            </div>
            <h1>Terms Of Use</h1>
            <div>
              <p>Thank you for choosing BuyGroup.com, the BuyGroup app and/or its affiliates (collectively with Consolidated
                Purchasing, LLC, "BuyGroup" or the “Company”). BuyGroup is a group purchasing organization that helps people
                get better pricing on products by grouping similar orders together to get bulk pricing for each party placing the
                order. </p>
              <p>
                By using the BuyGroup websites, apps and other products, services, applications and/or software provided by
                BuyGroup (collectively, "BuyGroup Services") you hereby acknowledge and agree to these Terms of Use on behalf
                of yourself, your organization, business or employer and/or all members associated with you or who use the
                Services under your account (if any), (collectively, “You” or “you”). Please read the Terms of Use carefully before
                you start to use the Website.<strong>By using the Services or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use,incorporated herein by reference. </strong>. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Services. For information regarding our privacy practices in with the
                Services, please review our Privacy Policy. The Services are only offered and available to users who are 18 years of
                age or older, and reside in the United States or any of its territories or possessions. By using the Services, you
                represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the
                foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the
                Services. We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
                effective immediately when we post them, and apply to all access to and use of the Services thereafter. Your
                continued use of the Services following the posting of revised Terms of Use means that you accept and agree to
                the changes. You are expected to check this page each time you access the Services so you are aware of any
                changes, as they are binding on you.
              </p>
              <h4>USING THE SERVICES:</h4>
              <p>
                To access the Services you may be asked to provide certain registration details or other information to create an
                account ("Profile"). It is a condition of your use of the Services that all the information you provide is correct,
                current and complete. You agree that all information you provide to register or otherwise, including but not
                limited to through the use of any interactive features, is governed by our Privacy Policy, and you consent to all
                actions we take with respect to your information consistent with our Privacy Policy. If you choose to register, or
                are provided with, a user name, password or any other piece of information as part of our security procedures, you
                must treat such information as confidential, and you must not disclose it to any other person or entity. You also
                acknowledge that your Profile is personal to you and agree not to provide any other person with access to the
                Services or portions of it using your username, password or other security information. You agree to notify us
                immediately of any unauthorized access to or use of your username or password or any other breach of security.
                You also agree to ensure that you exit from your Profile at the end of each session. You should use particular
                caution when accessing your Profile from a public or shared computer so that others are not able to view or record
                your password or other personal information. You acknowledge and agree that you are responsible for all activities
                that occur under your Profile. We have the right to disable any user name, password or other identifier, whether
                chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion,
                you have violated any provision of these Terms of Use. You agree that you may be required to grant certain
                permissions to us for your device when using our app. Most mobile devices provide you with information about
                these permissions.
                In connection with certain Services you may be required to be logged in to the account and have a valid payment
                method associated with it. If there is a problem charging your selected payment method, we may charge any other
                valid payment method associated with your account. If you wish to purchase any product or service made available
                through the Services (each such purchase, a “Transaction”), you may be asked to supply certain information
                relevant to your Transaction including, without limitation, your credit card number, the expiration date of your
                credit card, your billing address, and delivery information. You represent and warrant that you have the legal right
                to use any credit card(s) or other payment method(s) utilized in connection with any Transaction. By submitting
                such information, you grant BuyGroup the right to provide such information to third parties for purposes of
                facilitating the completion of Transactions initiated by you or on your behalf. Verification of information may be
                required prior to the acknowledgment or completion of any Transaction. You agree to accept responsibility for and
                pay for all activities that occur under your account or password. BuyGroup reserves the right to cancel orders in its
                sole discretion and without prior notice.
              </p>
              <h4>INTERMEDIARY STATUS, RETURNS AND REFUNDS AND PRICING AND PRODUCT DESCRIPTIONS:</h4>
              <p>
                BuyGroup does not sell its own products but rather acts as an intermediary between buyers and sellers of
                products. As such, we may provide links to the sites of affiliated companies and certain other businesses. If you
                purchase any of the products or services offered by these businesses or individuals, you are purchasing directly
                from those third parties, not from BuyGroup despite the fact that the Transaction is handled by BuyGroup on
                behalf of such third party sellers or suppliers.
                <strong>We are not responsible for examining or evaluating, and we do not
                  warrant, the offerings of any of these businesses or individuals (including the content of their Web sites, if any).
                  BuyGroup does not assume any responsibility or liability and expressly disclaims any responsibility and liability
                  for the actions, product, and content of all these and any other third parties and you should carefully review
                  their privacy statements and other conditions of use, if any.</strong> All descriptions, images, references, features,
                content, specifications, products, and prices of products and services described or depicted are subject to change
                at any time without notice. Certain descriptions are approximate and are provided for convenience purposes only.
                BuyGroup does attempt to be as accurate as possible, however, we do not warrant that product descriptions is
                accurate, complete, reliable, current, or error-free.
              </p>
              <p>
                The inclusion of any products or services on the BuyGroup Services does not necessarily imply or warrant that
                these products or services will be available and BuyGroup further reserves the right, with or without prior notice,
                to do any one or more of the following: (i) limit the available quantity of or discontinue any product or service; (ii)
                impose conditions on the honoring of any pricing, coupon, coupon code, promotional code, or other similar
                promotion; (iii) bar any user from making or completing any or all Transaction(s); and (iv) refuse to provide any
                user with any product or service. The Services may contain information about products that are not available in
                every location. A reference to a product does not imply or guarantee that it is or will be available in your location
                or at the time of your order.
                All purchases on BuyGroup are made pursuant to a shipment contract between you and the seller/supplier. This
                means that the risk of loss and title for such items pass to you upon our delivery to the carrier which will deliver it
                to you. You agree that by placing an order using the Services, you are entering into a binding contract with
                BuyGroup and agree to pay all charges that may be incurred by you or on your behalf through the Website, at the
                price(s) in effect when the order is place and such charges are incurred including, without limitation, all delivery
                charges. If the price of the order drops after your order is placed BuyGroup will issue a refund to you within 48
                hours of the final closing of the Buying Group for the product(s) you purchased. In addition, you remain
                responsible for any taxes that may be applicable to your Transactions. In the event of an error, we reserve the
                right, in our sole discretion and subject to these Terms of Use, to correct such errors and revise your order
                accordingly (including charging the correct price) or to cancel your order and issue you a credit refund. Each
                seller/supplier handles refunds differently and you will be required to work directly with such seller/supplier to
                facilitate any required returns or replacements. At our discretion, a refund may be issued without requiring a
                return.
              </p>
              <h4>LIMITED LICENSE TO USE THE SERVICES: </h4>
              <p>Subject to your agreement to comply with these Terms of Use, BuyGroup grants you a limited, non-exclusive, nontransferable, non-sublicensable license to access and make personal and non-commercial use of the Services. This
                license does not include any resale or commercial use of any of the Services (except as expressly authorized by
                BuyGroup), or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use
                of any of the Services or their contents; any downloading, copying, or other use of account information for the
                benefit of any third party whatsoever; or any use of data mining, robots, or similar data gathering and extraction
                tools. All rights not expressly granted to you in these Terms of Use are reserved and retained by BuyGroup or its
                licensors, suppliers, publishers, rightsholders, or other content providers and none of the Services, nor any part
                thereof, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
                purpose without express written consent of BuyGroup. Furthermore, you may not frame or utilize framing
                techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout,
                or form) and you may not use any meta tags or any other "hidden text" utilizing BuyGroup’s name or logo without
                express written consent of BuyGroup. You may only use the Services only as permitted by these Terms of Use and
                by law and any licenses granted by BuyGroup hereunder shall terminate immediately if you do not comply with
                these Terms of Use. We reserve the right to change or remove any feature, product, service or material we provide
                related to the Services, in our sole discretion without notice. We will not be liable if for any reason all or any part
                of the Services are unavailable at any time or for any period. From time to time, we may restrict access to some or
                all of the Services, or to certain users. You are responsible for your conduct while accessing or using the Service
                and ensuring that all persons who access the Services through your account, internet connection or device are
                aware of these Terms of Use and comply with them.</p>

              <h4>PROHIBITED USES:</h4>
              <p>You may use the Services only for lawful purposes and in accordance with these Terms of Use. You agree not to
                use the Services:</p>
              <ul>
                <li>In any way that violates any applicable federal, state, local or international law or regulation (including,
                  without limitation, any laws regarding the export of data or software to and from the U.S. or other
                  countries);</li>
                <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing
                  them to inappropriate content, asking for personally identifiable information or otherwise;</li>
                <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with
                  these Terms of Use;</li>
                <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written
                  consent, including any "junk mail", "chain letter" or "spam" or any other similar solicitation;</li>
                <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any
                  other person or entity (including, without limitation, by using e-mail addresses or screen names
                  associated with any of the foregoing); or</li>
                <li> To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or
                  which, as determined by us, may harm the Company or users of the Services or expose them to liability.</li>

                <p>You agree not to:</p>
                <li>
                  Use the Services in any manner that could disable, overburden, damage, or impair the site or interfere
                  with any other party's use of the Services, including their ability to engage in real time activities through
                  the Services;
                </li>
                <li>
                  Use any robot, spider or other automatic device, process or means to access the Services for any purpose,
                  including monitoring or copying any of the material on the Services;
                </li>
                <li>Use any manual process to monitor or copy any of the material on the Services or for any other
                  unauthorized purpose without our prior written consent;</li>
                <li>Use any device, software or routine that interferes with the proper working of the Services; </li>
                <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or
                  technologically harmful;</li>
                <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the
                  server on which the Services are stored, or any server, computer or database connected to the Services;</li>
                <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack; or</li>
                <li>Otherwise attempt to interfere with the proper working of the Services.</li>
              </ul>

              <h4>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT:</h4>
              <p>You may be asked to post reviews, comments and submit suggestions or ideas and may do so long as the content is
                not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights
                (including publicity rights), or otherwise injurious to third parties or objectionable, and does not consist of or
                contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form
                of "spam" or unsolicited commercial electronic messages. You may not use a false e-mail address, impersonate any
                person or entity, or otherwise mislead as to the origin of a card or other content. If you do post content or submit
                material, and unless we indicate otherwise, you grant BuyGroup a nonexclusive, royalty-free, perpetual,
                irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, perform, translate, create
                derivative works from, distribute, and display such content throughout the world in any media and you represent
                and warrant that you own or otherwise control all of the rights to the content that you post; that the content is
                accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or
                entity; and that you will indemnify BuyGroup for all claims resulting from content you supply. </p>

              <h4>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY:</h4>
              <p>THE SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER
                SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES ARE PROVIDED BY
                BUYGROUP ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. BUYGROUP
                MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
                THE SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER
                SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU, UNLESS OTHERWISE SPECIFIED IN WRITING.
                YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES AND YOUR PURCHASE OF ANY PRODUCTS THROUGH THE
                SERVICES IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY LAW, BUYGROUP DISCLAIMS ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. BUYGROUP DOES NOT WARRANT THAT THE
                SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
                INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES, ARE FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, BUYGROUP WILL NOT BE LIABLE FOR
                ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY SERVICE, OR FROM ANY INFORMATION, CONTENT,
                MATERIALS, PRODUCTS OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
                ANY SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL
                DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. IN NO EVENT WILL THE TOTAL LIABILITY OF THE
                COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
                AGENTS, OFFICERS OR DIRECTORS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
                OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT EXCEED FIVE
                HUNDRED DOLLARS ($500). THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH
                ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN THE
                COMPANY AND YOU.</p>
              <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED
                UNDER APPLICABLE LAW.</p>
              <p><strong>BINDING ARBITRATION NOTICE; GOVERNING LAW, JURISDICTION & VENUE: Note that these terms provide for
                binding arbitration in disputes, controversies, or claims (collectively “Disputes”) arising between you and
                BuyGroup that limit the remedies available to you in the event of a Dispute. </strong></p>

              <p>At the Company's sole discretion, we may require you to submit any disputes arising from the use of these Terms
                of Use or the Services, including disputes arising from or concerning their interpretation, violation, invalidity, nonperformance, or termination, to final and binding arbitration under the Rules of Arbitration of the American
                Arbitration Association applying Texas law. ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
                CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED. All
                matters relating to the Services and these Terms of Use and any dispute or claim arising therefrom or related
                thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in
                accordance with the internal laws of the State of Texas without giving effect to any choice or conflict of law
                provision or rule (whether of the State of Texas or any other jurisdiction). Any legal suit, action or proceeding
                arising out of, or related to, these Terms of Use or the Services shall be instituted exclusively in the federal courts
                of the United States or the courts of the State of Texas, in each case located in the City of Dallas and County of
                Dallas, although we retain the right (in our sole and absolute discretion, which may be withheld, conditioned, or
                delayed) to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of
                residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you
                by such courts and to venue in such courts.</p>

              <p> <h5>NOTICES: </h5>All notices may be sent to:</p>
              <pre>
                BuyGroup
                PO Box 851
                Prosper, TX 75078
              </pre>
              <h5>ELECTRONIC COMMUNICATIONS: </h5>
              <p> When you use the Services, or send e-mails, text messages, and other communications from your desktop or
                mobile device to us, you may be communicating with us electronically. you consent to receive communications
                from us electronically, such as e-mails, texts, mobile push notices, or notices and messages and you agree that all
                agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal
                requirement that such communications be in writing.</p>

              <h5>COPYRIGHTS AND TRADEMARKS:</h5>
              <p>All content included in or made available through any Services, such as text, graphics, logos, button icons, images,
                audio clips, digital downloads, data compilations, and software is the property of BuyGroup or its content suppliers
                and protected by United States and international copyright laws. The compilation of all content included in or
                made available through any Service is the exclusive property of BuyGroup and protected by U.S. and international
                copyright laws. In addition, graphics, logos, page headers, button icons, scripts, and service names included in or
                made available through any Services are trademarks or trade dress of BuyGroup or its affiliates or sellers/suppliers
                or third parties in the U.S. and other countries and such trademarks and trade dress may not be used in
                connection with any product or service, in any manner that is likely to cause confusion among customers, or in any
                manner that disparages or discredits the owner thereof. </p>

              <h5>WAIVER, SEVERABILITY, ASSIGNMENT AND ENTIRE AGREEMENT: </h5>
              <p>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or
                continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the
                Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or
                provision. If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be
                invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum
                extent such that the remaining provisions of the Terms of Use will continue in full force and effect. The Terms of
                Use and our Privacy Policy constitute the sole and entire agreement between you and the Company with respect
                to the Services and supersede all prior and contemporaneous understandings, agreements, representations and
                warranties, both written and oral, with respect to the Services and you may not assign or transfer these Terms of
                Use, by operation of law or otherwise, without the Company’s prior written consent. Any attempt by you to assign
                or transfer these Terms without such consent will be null but the Company may freely assign or transfer these
                Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties,
                their successors and permitted assigns.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
/* Copyright Information: Created by BuyGroup */