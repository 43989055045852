/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import "./invoice.scss";
import { Spinner } from "reactstrap";
import { orderInvoice } from "../services/PlaceOrderServices";
import logo from "../assets/images/logo.png";
import { toast } from "react-toastify";
toast.configure();
const Invoice = (props) => {
  /* loader for invoice */
  const [isLoading, setLoading] = useState(false);

  /* const "orderInfo" store order information*/
  const [orderInfo, setOrderInfo] = useState({});

  useEffect(() => {
    getOrderDetail();
  }, []);

  /* This function will give order information of respective id */
  const getOrderDetail = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsValue = params.get("orderid");
    setLoading(true);
    const data = {
      order_id: paramsValue,
    };

    orderInvoice(data)
      .then((response) => {
        if (response.status == 200) {
          setOrderInfo(response.data);
          setLoading(false);
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setLoading(false);
      });
  };

  console.log("orderInfo", orderInfo);
  return (
    <div className="invoice_container">
      <div className="container">
        {isLoading ? (
          <div className="custom-loader">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <div className="row justify-content-center align-items-center invoice_header">
              <div className="col-12">
                <img src={logo} className="img-rounded logo" />
                <div  style={{marginTop:"20px"}}><strong >Address</strong>

                </div>
                
                <address style={{marginTop:"3px"}}>{orderInfo.order_list?.buy_group?.address+","+orderInfo.order_list?.buy_group?.zip_code}</address>
                
                
              </div>
              <div className="col-6"></div>
            </div>
            <div className="row">
              <div className="col-12">
                <h2>Invoice</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 invoice-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Product</th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Qty
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Total
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Tax Amount
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Shipping Amount
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Grand Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Image">
                        <img
                          src={orderInfo.order_list?.buy_group?.product_image}
                          className="img-responsive"
                          alt="not found"
                        />
                      </td>
                      <td data-label="Product">
                        {orderInfo.order_list?.buy_group?.product_name}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Qty"
                      >
                        {orderInfo.order_list?.quantity}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Price"
                      >
                        ${parseFloat(orderInfo.order_list?.price).toFixed(2)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Total"
                      >
                        ${parseFloat(orderInfo.total).toFixed(2)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Tax Amount"
                      >
                        ${parseFloat(orderInfo.total_tax).toFixed(2)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Shipping Amount"
                      >
                        ${parseFloat(orderInfo.total_shipping).toFixed(2)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Grand Total"
                      >
                        ${parseFloat(orderInfo.grand_total).toFixed(2)}
                      </td>
                    </tr>
                    <tr className="hide_mobile">
                      <td colSpan="7"></td>
                    </tr>
                    <tr className="hide_mobile">
                      <td colSpan="6">&nbsp;</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Total"
                      >
                        <strong>Total</strong>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <strong>
                          ${parseFloat(orderInfo.grand_total).toFixed(2)}
                        </strong>
                      </td>
                    </tr>

                    <tr className="show_mobile">
                      <td
                        style={{
                          textAlign: "right",
                        }}
                        data-label="Grand Total"
                      >
                        <strong>
                          ${parseFloat(orderInfo.grand_total).toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-12 invoice-thank">
                <h5>Thank You!</h5>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-6">
                <strong>Phone:</strong>+91-124-111111
              </div>
              <div className="col-6">
                <strong>Email:</strong>
                <a href="info@buygroup.com">info@buygroup.com</a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Invoice;
/* Copyright Information: Created by BuyGroup */
