/* Copyright Information: Created by BuyGroup */
import * as Yup from "yup";
import valid from "card-validator";
// const validationSchema = Yup.object().shape({
//   card_number: Yup.string()
//     .required("Please enter card number")
//     .test(
//       "test-number",
//       "Credit Card number is invalid",
//       (value) => valid.number(value).isValid
//     ),
//   card_holder_name: Yup.string().required("Please enter name"),
//   cvv: Yup.string()
//     .required("Please enter CVV")
//     .matches(/^[0-9]+$/, "Please enter correct cvv")
//     .min(3, "Please enter correct cvv")
//     .max(4, "Please enter correct cvv"),
//   month: Yup.string().required("Please select month"),
//   year: Yup.string().required("Please select year"),
// });

const validationSchema = Yup.object().shape({
  card_number: Yup.boolean().required().oneOf([true],"Card number is required"),
  default_card: Yup.boolean().required(),
  card_holder_name: Yup.string().required("Please enter name"),
  cvv: Yup.boolean().required().oneOf([true],"Please enter correct cvv"),
  valid_thru: Yup.boolean().required().oneOf([true],"Please enter correct month and year"),
});

export default validationSchema;
/* Copyright Information: Created by BuyGroup */
