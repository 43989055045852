/* Copyright Information: Created by BuyGroup */
import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";


const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div class="policy-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div className="logo-format">
              <img src={logo} alt="" className="img-fluid" />
            </div>
            <h1>Privacy Policy</h1>
            <div class="">
              <h2>Introduction</h2>
              <p>Consolidated Purchasing, LLC (dba “BuyGroup™”) and its affiliates and subsidiaries (collectively, <strong>“Company,” “We,” “Our”</strong>) respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes: The types of information we may collect or that you may provide when you visit our website www.buygroup.com (the "Website"), or when you download, install, register with, access, or use the BuyGroup Mobile App (the “App”, and together with the Website, the "Service"); and Our practices for collecting, using, maintaining, protecting, and disclosing that information. This policy applies to information we collect through the Service and in email, text, and other electronic communications sent through or in connection with the Service. This policy DOES NOT apply to information that we collect offline or on any other Company apps or websites, including websites you may access through the Service; or You provide to or is collected by any third party (see "THIRD-PARTY INFORMATION COLLECTION"). Our websites and apps, and these third parties may have their own privacy policies, which we encourage you to read before providing information on or through them. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use the Service. By accessing, downloading, registering with, or using the Service, you agree to this privacy policy. This policy may change from time to time (see "CHANGES TO OUR PRIVACY POLICY"). Your continued use of the Service after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
              <p><strong>Children Under the Age of 13.</strong> The Service is not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information to the Service or on or through any of its features. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at <a href="mailto:support@buygroup.com">support@buygroup.com</a>.</p>
              <p><strong>Information We Collect and How We Collect It.</strong> We collect information from and about Users of the Service:</p>
              <ul>
                <li>Directly from you when you provide it to us; and</li>
                <li>Automatically when you use the Service, including usage details, IP addresses, and information collected through cookies.</li>
              </ul>
              <p><strong>Information You Provide to Us.</strong> When you download, register with, or use the Service, we may ask you provide information:</p>
              <ul>
                <li>By which you may be personally identified, such as name, postal address, delivery address, email address, telephone number, and credit card information ("personal information");</li>
                <li>That is about you but individually does not identify you, such as demographic information (gender, age, etc) and order history; and</li>
                <li>About your internet connection, the equipment you use to access the Service, and usage details.</li>
                <li>This information includes:</li>
                <li>Information that you provide by filling in forms through the Service. This includes information provided at
                  the time of registering to use the Service, placing a cookie delivery order, using certain features of the
                  Service, posting or publishing material to public areas of the Service, or requesting further services. We
                  may also ask you for information when you enter a contest or promotion sponsored by us, and when you
                  report a problem with the Service.</li>
                <li>Records and copies of your correspondence (including email addresses and phone numbers), if you
                  contact us</li>
                <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                <li>Details of transactions you carry out through the Service and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the Service.</li>
                <li>Your search queries on the Service.</li>
                <li>You may provide information to be published or displayed ("Posted") on public areas of the Service (collectively, "User Contributions"). Your User Contributions are Posted and transmitted to others at your own risk. Although we may limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</li>
              </ul>
              <p><strong>Automatic Information Collection and Tracking.</strong> As you navigate through, download, access, use, and interact with the Service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
              <p><strong>Usage Details.</strong> When you access and use the Service, we may automatically collect certain details of your access to and use of the Service, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the Service.</p>
              <p><strong>Device Information.</strong> We may collect information about your computer, mobile device and internet connection,
                including the device's unique device identifier, IP address, operating system, browser type, mobile network
                information, and the device's telephone number.</p>
              <p><strong>Stored Information and Files.</strong> The Service also may access metadata and other information associated with other
                files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts,
                and address book information.</p>
              <p><strong>Location Information.</strong> The Service collects real-time information about the location of your device in order to
                provide you with the nearest store, pre-populate your delivery location, and track the delivery driver’s progress to
                the delivery location. If you do not want us to collect this information do not download the App or use the Service
                or delete the App from your device. For more information, see “CHOICES ABOUT HOW WE USE AND DISCLOSE
                YOUR INFORMATION”. Note, however, that opting out of the Service's collection of location information will cause
                its location-based features to be disabled. The information we collect automatically may include personal
                information, or we may maintain it or associate it with personal information we collect in other ways or receive
                from third parties.</p>
              <p><strong>Information Collection and Tracking Technologies.</strong> The technologies we use for automatic information collection
                may include:</p>
              <p><strong>Cookies (or browser/mobile cookies).</strong> A cookie is a small file placed on the hard drive of your computer or your
                smartphone. It may be possible to refuse to accept browser/mobile cookies by activating the appropriate setting
                on your browser or smartphone. However, if you select this setting you may be unable to access certain parts of
                our Service. Unless you have adjusted your browser/mobile setting so that it will refuse cookies, our system will
                issue cookies when you direct your browser to our Website or access our App.</p>
              <p><strong>Web Beacons.</strong> Pages of the Service may contain small electronic files known as web beacons (also referred to as
                clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count Users who have visited
                those pages and for other related website or app statistics (for example, recording the popularity of certain
                website or app content and verifying system and server integrity).</p>
              <p><strong>Third Party Analytics Tools.</strong> We use certain analytics tools, including Google Analytics. For example, Google
                Analytics helps us understand how our Website is used. Google Analytics places cookies on your device that
                generate information we select about your use of our Website, including your device’s IP address. That
                information, which is transmitted directly to and stored by Google, allows us to evaluate the use of our Website,
                compile reports on activity on our Website, and utilize other services relating to activity on, and usage of, our
                Website. Google may also transfer this information to third parties where required to do so by law, or where such
                third parties process the information on Google’s behalf. Google’s practices with respect to the personal
                information collected through Google Analytics are covered by Google’s privacy policy, available
                at <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>. Google allows you to opt out of Google Analytics. You may opt-out by
                visiting <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.</p>
              <p><strong>Third-Party Information Collection.</strong> When you use the Service or its content, certain third parties may use
                automatic information collection technologies to collect information about you or your device. These third parties
                may include:</p>
              <ul>
                <li>Advertisers, ad networks, and ad servers;</li>
                <li>Analytics companies;</li>
                <li>Your mobile device manufacturer; and</li>
                <li>Your mobile service provider.</li>
                <li>These third parties may use tracking technologies to collect information about you when you use the
                  Service. The information they collect may be associated with your personal information or they may
                  collect information, including personal information, about your online activities over time and across
                  different websites, apps, and other online services websites. They may use this information to provide
                  you with interest-based (behavioral) advertising or other targeted content.</li>
                <li>
                  Our application utilizes Stripe for payment processing. As part of this process, credit card information is securely transmitted to Stripe in accordance with their PCI DSS (Payment Card Industry Data Security Standard) compliance requirements. Stripe is a certified PCI Level 1 Service Provider, ensuring the highest level of security for handling payment information. We prioritize the protection of your sensitive data and adhere to industry standards to safeguard your privacy and security.
                </li>
              </ul>
              <p>We do not control these third parties' tracking technologies or how they may be used. If you have any
                questions about an advertisement or other targeted content, you should contact the responsible provider
                directly. For information about how you can opt out of receiving targeted advertising from many
                providers, see "CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION".</p>
              <p><strong>How We Use Your Information.</strong> We use information that we collect about you or that you provide to us, including
                any personal information, to:</p>
              <ul>
                <li>To process and fulfill your order of cookies or of any other product or service purchased through our site;</li>
                <li>Provide you with the Website, App and their contents, and any other information, products or services
                  that you request from us;</li>
                <li>Fulfill any other purpose for which you provide it;</li>
                <li>Give you notices about your account, including expiration and renewal notices;</li>
                <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and
                  us, including for billing and collection;</li>
                <li>To allow you to participate in interactive features on the Service;</li>
                <li>To notify you about changes to our Website and any products or services we offer or provide through it;</li>
                <li>Notify you when App updates are available, and of changes to any products or services we offer or
                  provide though it.</li>
                <li>In any other way we may describe when you provide the information; and</li>
                <li>For any other purpose with your consent.</li>
                <li>The information we collect helps us to improve our Service and to deliver a better and more personalized
                  experience by enabling us to:</li>
                <li>Estimate our audience size and usage patterns;</li>
                <li>Store information about your preferences, allowing us to customize the Service according to your
                  individual interests;</li>
                <li>Speed up your searches; and</li>
                <li>Recognize you when you use or return to the Website or App.</li>
                <li>We use location information we collect to provide you with the nearest store, pre-populate your delivery
                  location, and track the delivery driver’s progress to the delivery location.</li>
                <li>We may also use your information to contact you about our own and third parties' goods and services
                  that may be of interest to you. For more information, see "CHOICES ABOUT HOW WE USE AND DISCLOSE
                  YOUR INFORMATION".</li>
                <li>We may use the information we collect to display advertisements to our advertisers' target audiences.
                  Even though we do not disclose your personal information for these purposes without your consent, if
                  you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its
                  target criteria.</li>
              </ul>
              <p><strong>Disclosure of Your Information.</strong> We may disclose aggregated information about our Users and information that
                does not identify any individual or device, without restriction. In addition, we may disclose personal information
                that we collect or you provide:</p>
              <ul>
                <li>To contractors, service providers, and other third parties (e.g. advertising networks, email service
                  providers, fulfillment partners payment card processors, etc.) we use to support our business of delivering
                  cookies and other products;</li>
                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                  dissolution, or other sale or transfer of some or all of the Company's assets, whether as a going concern or
                  as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the
                  Company about our App Users is among the assets transferred;</li>
                <li>To fulfill the purpose for which you provide it (for example, to process and fulfill your cookie delivery
                  order or your order of any other product or service);</li>
                <li>For any other purpose disclosed by us when you provide the information;</li>
                <li>With your consent and at your direction;</li>
                <li>To comply with any court order, law, or legal process, including to respond to any government or
                  regulatory request;</li>
                <li>To enforce our rights arising from any contracts entered into between you and us, including the Website
                  and Terms of Service, terms of sale, and for billing and collection; and</li>
                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
                  Company, our customers or others (which includes exchanging information with other companies and
                  organizations for the purposes of fraud protection and credit risk reduction).</li>
              </ul>
              <p><strong>Your Choices About Our Collection, Use, and Disclosure of Your Information.</strong> We strive to provide you with
                choices regarding the personal information you provide to us. This section describes mechanisms we provide for
                you to control certain uses and disclosures of over your information.</p>

              <p><strong>User Data Collection Upon Sign Up:</strong> Upon signing up for our Service, we collect certain user data including email address, phone number, city, state, document (optional) and zip code. This information is collected to facilitate account creation, provide personalized services, and improve user experience.</p>

              <p><strong>Account Deletion Option:</strong> Users have the ability to delete their accounts by contacting us at <a href="mailto:support@buygroup.com">support@buygroup.com</a>. Upon receiving a request for account deletion, we will promptly deactivate the user's account and remove their personal information from our active databases. Please note that some information may be retained in backup or archival copies for legal or compliance purposes.</p>


              <p><strong>Tracking Technologies.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when
                cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts
                of the Service may then be inaccessible or not function properly.</p>
              <p><strong>Location Information.</strong> You can choose whether or not to allow the App to collect and use real-time information
                about your device’s location through the device’s privacy settings. If you block the use of location information,
                some parts of the App may then be inaccessible or not function properly.</p>
              <p><strong>Promotion by the Company.</strong>  If you do not want us to use your contact information to promote our own or third
                parties’ products or services, you can opt-out by turning off app notifications or adjusting your User preferences in
                your account profile. We do not control third parties’ collection or use of your information to serve interest-based
                advertising. However, these third parties may provide you with ways to choose not to have your information
                collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising
                Initiative (“NAI”) on the NAI’s <a href="http://www.networkadvertising.org/managing/opt_out.asp" target="_blank">website</a>.</p>
              <p><strong>Accessing and Correcting Your Personal Information.</strong> You can review and change your personal information by
                logging into the App or Website and visiting your account profile page. You may also send us an email at
                support@buygroup.com to request access to, correct, or delete any personal information that you have provided
                to us. We cannot delete your personal information except by also deleting your User account. We may not
                accommodate a request to change information if we believe the change would violate any law or legal
                requirement or cause the information to be incorrect. If you delete your User Contributions from the Service,
                copies of your User contributions may remain viewable in cached and archived pages, or might have been copied
                or stored by other Service Users. Proper access and use of information provided on the Service, including User
                contributions, is governed by our Terms of Use.</p>

              <p><strong>Data Security.</strong> We have implemented measures designed to secure your personal information from accidental loss
                and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our
                secure servers behind firewalls. Any payment transactions and order transactions will be encrypted using SSL
                technology.The safety and security of your information also depends on you. Where we have given you (or where
                you have chosen) a password for access to certain parts of our Service, you are responsible for keeping this
                password confidential. We ask you not to share your password with anyone. We urge you to be careful about
                giving out information in public areas of the Service like message boards. The information you share in public areas
                may be viewed by any User of the Service. Unfortunately, the transmission of information via the internet and
                mobile platforms is not completely secure. Although we do our best to protect your personal information, we
                cannot guarantee the security of your personal information transmitted through our Service. Any transmission of
                personal information is at your own risk. We are not responsible for circumvention of any privacy settings or
                security measures we provide.</p>
              <p><strong>Changes to Our Privacy Policy.</strong> We may update our privacy policy from time to time. If we make material changes
                to how we treat our Users' personal information, we will post the new privacy policy on this page and notify you by
                email and/or text message to the email address and/or phone number specified in your account.
                The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we
                have an up-to-date active and deliverable email address and/or phone number for you and for periodically visiting
                this privacy policy to check for any changes.</p>

              <p><strong>FOR CALIFORNIA USERS:</strong> BuyGroup, and its related, affiliated, or subsidiary companies, (collectively “BuyGroup,” “we,” “us,” or “our”) provides this Privacy Notice for California Consumers (the “CCPA Privacy Notice”) to supplement the information
                contained in the BuyGroup Privacy Policy. The CCPA privacy notice applies only to visitors, users, and others who
                are Consumers residing in the State of California from whom we collect personal information, however collected
                ("Consumers" or "you"). This CCPA Privacy Notice sets forth our privacy practices as required by the California
                Consumer Privacy Act of 2018 (“CCPA”), and any terms defined in the CCPA have the same meaning when used in
                this notice. Information We Collect: We may collect information from you in a variety of different situations,
                including, but not limited to on our website, app, your mobile device, through email, in stores, through the mail,
                and/or over the telephone, that identifies, relates to, describes, references, is capable of being associated with, or
                could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal information").
                More specifically, BuyGroup has collected the following categories of personal information from its consumers:</p>

              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Examples of Personal Information Collected</th>
                      <th>Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A. Identifiers.</td>
                      <td>A real name, alias, postal address, unique personal identifier,
                        online identifier, Internet Protocol address, email address,
                        account name, and similar identifiers.</td>
                      <td>Website/App/Mobile Device/In
                        Store/email/
                        mail/telephone</td>
                    </tr>
                    <tr>
                      <td>B. Personal
                        information
                        categories listed
                        in the California
                        Customer
                        Records statute
                        (Cal. Civ. Code § 1798.80(e)). </td>
                      <td>A name, signature, characteristics or description, address,
                        telephone number, education, credit card number, debit card
                        number, or any other financial information
                        Some personal information included in this category may
                        overlap with other categories.</td>
                      <td>Website/App/Mobile Device/In
                        Store/email/
                        mail/telephone</td>
                    </tr>
                    <tr>
                      <td>C. Commercial
                        information.</td>
                      <td>Records of personal property, products or services
                        purchased, obtained, or considered, or other purchasing or
                        consuming histories or tendencies.</td>
                      <td>Website/App/Mobile Device/In
                        Store/email/
                        mail/telephone</td>
                    </tr>
                    <tr>
                      <td>D. Internet or
                        other similar
                        network
                        activity.</td>
                      <td>Browsing history, search history, information on a
                        consumer's interaction with a website, application, or
                        advertisement.</td>
                      <td>Website/App/Mobile Device/In
                        Store/email/
                        mail/telephone</td>
                    </tr>
                    <tr>
                      <td>E. Inferences
                        drawn from
                        other personal
                        information.</td>
                      <td>Profile reflecting a person's preferences, characteristics,
                        psychological trends, predispositions, behavior, attitudes,
                        intelligence, abilities, and aptitudes.</td>
                      <td>Website/App/Mobile Device/In
                        Store/email/
                        mail/telephone</td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <p><strong>Personal information does not include:</strong></p>
              <ul>
                <li>Publicly available information from government records.</li>
                <li>Deidentified or aggregated consumer information.</li>
                <li>BuyGroup obtains the categories of personal information listed above from the following categories of
                  sources:</li>
                <li>Directly from you. For example, from forms you complete or products you purchase.</li>
                <li>Indirectly from you. For example, from observing your actions on our Website or through our App.</li>
              </ul>

              <p><strong>How We Use Personal Information:</strong> We may use, share or disclose the personal information we collect from you
                or about you with our Service Providers with whom we have a written contract that includes specific requirements
                of the CCPA, for one or more of the following business purposes:</p>
              <ul>
                <li>To fulfill or meet the purpose for which you provided the information. For example, if you share your
                  name and contact information to ask us a question or inquire about our products or services, we will use
                  that personal information to respond to your inquiry. If you provide your personal information to
                  purchase a product or service, we will use that information to process your payment and facilitate any
                  applicable delivery. When you order products and services, we may also save your information to
                  facilitate new product orders or process returns. </li>
                <li>To contact you and to inform you about products, services, promotions, special offers, and/or events that
                  may interest you.</li>
                <li>To inform you about products and services you have purchased from us such as product recalls and
                  warranty information.</li>
                <li>To provide, support, personalize, and develop our Website and App, products, and services.</li>
                <li>To create, maintain, customize, and secure your account with us.</li>
                <li>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
                <li>To provide you with support and to respond to your inquiries, including to investigate and address your
                  concerns and monitor and improve our responses.</li>
                <li>To personalize your Website and/or App experience and to deliver content and product and service
                  offerings relevant to your interests, including targeted offers and ads through our Website, third-party
                  sites, and via email or text message (with your consent, where required by law).</li>
                <li>To help maintain the safety, security, and integrity of our Website and App, products and services,
                  databases and other technology assets, and business.</li>
                <li>For research, analysis, and business development, including to develop and improve our Website, App,
                  products, and services.</li>
                <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental
                  regulations.</li>
                <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA or
                  subsequently agreed to by you.</li>
                <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of BuyGroup assets, whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which personal information held by BuyGroup about our Consumers
                  is among the assets transferred.</li>
              </ul>
              <p>BuyGroup will not collect additional categories of personal information or use the personal information we
                collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
              <p><strong>How We Share Personal Information:</strong> BuyGroup does not sell your personal information but BuyGroup may
                disclose or share your personal information to a third party for a business purpose. When we disclose personal
                information for a business purpose, we enter a contract that describes the purpose and requires the recipient to
                both keep that personal information confidential and not process it for any other purpose except on our behalf
                while performing a contract.</p>
              <p><strong>Disclosure of Personal Information for a Business Purpose:</strong> In the preceding twelve (12) months, Company has
                disclosed the following categories of personal information for a business purpose: <br />Category A: Identifiers.<br />Category B: California Customer Records personal information categories.<br />Category C: Commercial information.<br />Category D: Internet or other similar network activity.<br />Category E: Inferences drawn from other personal information.</p>
              <p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
              <ul>
                <li>Service providers</li>
                <li>Data aggregators, such as analytical services for business performance and measurements</li>
                <li>Third parties with whom you direct us to share your personal information</li>
              </ul>
              <p>Your Consumer Rights and Choices: The CCPA provides Consumers with specific rights regarding their personal
                information. This section describes your CCPA rights and explains how to exercise those rights.</p>
              <p><strong>Access to Specific Information and Data Portability Rights:</strong> You have the right to request that BuyGroup disclose
                our collection and use of your personal information over the past 12 months. Once we receive and confirm your
                verifiable consumer request (<em>see Exercising Access, Data Portability, and Deletion Rights</em>), we will disclose to you:</p>
              <ul>
                <li>The categories of personal information we collected about you.</li>
                <li>The categories of sources for the personal information we collected about you.</li>
                <li>Our business or commercial purpose for collecting that personal information.</li>
                <li>The categories of third parties with whom we share that personal information.</li>
                <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
              </ul>
              <p>If we disclosed your personal information for a business purpose, a list containing disclosures for a business
                purpose, identifying the personal information categories that each category of recipient obtained.</p>

              <p><strong>Deletion Request Rights:</strong> You have the right to request that BuyGroup delete any of your personal information
                that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your
                verifiable consumer request (see Exercising Access, Data Portability, and Deletion Rights), we will delete (and
                direct our service providers to delete) your personal information from our records, unless an exception applies. In
                accordance with the CCPA, we may deny your deletion request under certain circumstances, and will inform you of
                the basis for the denial, which may include, but is not limited to, if retaining the information is necessary for us or
                our service provider(s) to:</p>
              <ul>
                <li>Complete the transaction for which we collected the personal information, provide a good or service that
                  you requested, take actions reasonably anticipated within the context of our ongoing business
                  relationship with you, or otherwise perform our contract with you.</li>
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute
                  those responsible for such activities.</li>
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your
                  relationship with us.</li>
                <li>Comply with a legal obligation.</li>
                <li>Make other internal and lawful uses of that information that are compatible with the context in which you
                  provided it.</li>
                <li>We could not verify your identity, or we have determined that you are not entitled to exercise the right
                  you are attempting to exercise because you are not a “consumer” as that term is defined under the CCPA.</li>
              </ul>
              <p><strong>Exercising Access, Data Portability, and Deletion Rights:</strong> To exercise the access, data portability, and deletion
                rights described above, please submit a verifiable consumer request to us via email to <a href="mailto:support@buygroup.com">support@buygroup.com</a>. Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf,
                may make a verifiable consumer request related to your personal information.You may also make a verifiable
                consumer request on behalf of your minor child. You may only make a verifiable consumer request for access or
                data portability twice within a 12-month period. The verifiable consumer request must:</p>
              <ul>
                <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we
                  collected personal information or an authorized representative.</li>
                <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond
                  to it.</li>
              </ul>
              <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or
                authority to make the request and confirm the personal information relates to you. Making a verifiable consumer
                request does not require you to create an account with us.
                We will only use personal information provided in a verifiable consumer request to verify the requestor's identity
                or authority to make the request.</p>
              <p><strong>Response Timing and Format:</strong> We endeavor to respond to a verifiable consumer request within forty-five (45) days
                of its receipt. If we require more time , we will inform you of the reason and extension period in writing. If you
                have an account with us, we will deliver our written response to that account. If you do not have an account with
                us, we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will
                only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will
                also explain the reasons we cannot comply with a request, if applicable. We do not charge a fee to process or
                respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we
                determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost
                estimate before completing your request.</p>
              <p><strong>Non-Discrimination:</strong> We will not discriminate against you for exercising any of your CCPA rights. </p>
              <p><strong>Other California Privacy Rights:</strong> California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of
                our Website that are California residents to request certain information regarding our disclosure of personal
                information to third parties for their direct marketing purposes. To make such a request, please send an email to
                support@buygroup.com.</p>
              <p><strong>Changes to Our CCPA Privacy Notice:</strong> BuyGroup reserves the right to amend this privacy notice at our discretion
                and at any time. When we make changes to this privacy notice, we will post the updated notice on the Website
                and update the notice's Effective Date above. Your continued use of our Website or App or interaction with us
                through other methods following the posting of changes and/or our directing you to the updated CCPA Privacy
                Notice constitutes your acceptance of such changes.</p>
              <p><strong>Contact Information.</strong> To ask questions or comment about this privacy policy and our privacy practices, contact us
                at: <a href="mailto: support@buygroup.com">support@buygroup.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
/* Copyright Information: Created by BuyGroup */