/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { City } from "country-state-city";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import { toast } from "react-toastify";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
  Button,
  Col,
} from "reactstrap";
const AddShippingAddress = (props) => {
  const {
    openmodal,
    initialValues,
    handleAddShippingAddress,
    colsedModal,
    isLoadingButton,
  } = props;

  const history = useHistory();

  console.log("props.initialValues11", props.initialValues.idd);

  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "userInitialValues" store user initial value */
  const [userInitialValues, setUserInitialValues] = useState(
    props.initialValues
  );

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState(
    props.initialValues.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  console.log("props.initialValues?.phone", props.initialValues?.phone_number);

  useEffect(() => {
    getCountryList();
  }, []);

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("country", selectedCountry.label);
    setFieldTouched("country", false);
    setFieldValue("state", "");
    setFieldValue("city", "");

    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("state", selectedState.label);
    setFieldValue("city", "");
    setFieldTouched("state", false);

    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("city", selectCity.label);
    setFieldTouched("city", false);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   */
  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("phone_number", selectphone);
  };

  const Submit = (customerAddress) => {
    console.log("customerAddress", customerAddress);
    handleAddShippingAddress(customerAddress);
  };

  return (
    <div>
      <Modal
        isOpen={openmodal}
        toggle={colsedModal}
        style={{ overlay: { opacity: 0.1 } }}
      >
        <ModalHeader toggle={colsedModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Add Supplier
          </h5>
        </ModalHeader>

        <Formik initialValues={initialValues} onSubmit={Submit}>
          {({ setFieldValue, handleBlur, values, setFieldTouched, errors }) => (
            <Form>
              <ModalBody>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    First Name
                  </Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="first_name"
                  />
                  <ErrorMessage name="first_name">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Last Name
                  </Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="last_name"
                  />
                  <ErrorMessage name="last_name">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Phone Number
                  </Label>

                  <PhoneInput
                    defaultCountry="US"
                    international={false}
                    value={phoneValue}
                    onBlur={handleBlur}
                    maxLength={14}
                    name="phone_number"
                    className="form-control"
                    onChange={(value) =>
                      handlePhoneNumber(value, setFieldValue)
                    }
                  />

                  <ErrorMessage name="phone_number">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Country
                  </Label>

                  <Select
                    value={countriesValue}
                    noOptionsMessage={() => "No country found"}
                    onChange={(value) =>
                      handleCountry(value, setFieldValue, setFieldTouched)
                    }
                    options={countries}
                    name="country"
                    placeholder="Select Country"
                  />

                  <ErrorMessage name="country">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    State
                  </Label>

                  <Select
                    value={statesValues}
                    noOptionsMessage={() =>
                      noDataStateMessage
                        ? "No State Found"
                        : "First select country"
                    }
                    onChange={(value) =>
                      handleState(value, setFieldValue, setFieldTouched)
                    }
                    options={filterStates}
                    name="state"
                    placeholder="Select State"
                  />

                  <ErrorMessage name="state">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    City
                  </Label>

                  <Select
                    value={cityValues}
                    defaultValue={{
                      value: "",
                      label: props.initialValues.city,
                    }}
                    noOptionsMessage={() =>
                      noDataCityMessage ? "No City Found" : "First select state"
                    }
                    onChange={(value) =>
                      handleCity(value, setFieldValue, setFieldTouched)
                    }
                    options={filterCity}
                    name="city"
                    placeholder="Select City"
                  />

                  <ErrorMessage name="city">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Street
                  </Label>
                  <Field type="text" className="form-control" name="street" />

                  <ErrorMessage name="street">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Zip Code
                  </Label>
                  <Field type="text" className="form-control" name="zip_code" />
                  <ErrorMessage name="zip_code">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isLoadingButton}
                >
                  {isLoadingButton ? "Please Wait..." : "Add"}
                </Button>

                <Button type="button" color="light" onClick={colsedModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddShippingAddress;
/* Copyright Information: Created by BuyGroup */