/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Container, Col } from "reactstrap";
import Header from "./Header";
import TopBarDark from "./topbar-dark";
import HomeContainer from "./HomeContainer";
import PrivateRoutes from "../../routes/PrivateRoutes";
import HeaderTwo from "../../components/headers/header-two";
import FooterTwo from "../../components/footers/footer-two";
import Sidebar from "../../sidebar/Sidebar";
class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false,
    };
  }
  toggleButton = () => {
    this.setState((prevState) => ({ toggleMenu: !prevState.toggleMenu }));
  };

  render() {
    return (
      <>
        <React.Fragment>
          <HeaderTwo />
          <div className="clearfix mt-5 mb-5">
            <Container>
              <HomeContainer
                handleMainClass={this.state.toggleMenu}
                {...this.props}
              />
            </Container>
          </div>
          <FooterTwo />
        </React.Fragment>
      </>
    );
  }
}

export default Home;
/* Copyright Information: Created by BuyGroup */
