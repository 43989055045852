import React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from "./ValidationSchema";

const AddCard = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Modal className="add_card_popup" isOpen={props.modal} centered>
      <ModalHeader toggle={props.hideModal}>Add Card</ModalHeader>
      <Formik
        initialValues={{
          card_number: "",
          default_card: false,
          card_holder_name: "",
          cvv: "",
          valid_thru: "",
        }}
        onSubmit={(values, actions) => {
          console.log("values:- --------------------------------------------", values);
          const cardElement = elements.getElement(CardNumberElement,CardExpiryElement,CardCvcElement);
          console.log("cardElement :- ",cardElement);
          stripe.createToken(cardElement).then((data)=>{

            console.log("stripe.createToken ",data);
            const input = {
              "card_holder_name":values.card_holder_name,
              "default_card": values.default_card,
              "card_details": data.token 
            }

            props.handleSubmitCard(input, actions);


          }).catch((error)=>{
            console.log("stripe.createToken error ",error);
          });

          
          // props.handleSubmitCard(values, actions);
        }}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="card_number">Card Number</Label>
                <CardNumberElement
                  id="card-number-element"
                  showIcon={true}
                  className="form-control"
                  options={{ hidePostalCode: true }}
                  onChange={(e) => {
                    handleChange({
                      target: { name: "card_number", value: e.complete },
                    });
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="card_number" component="div" className="err-msg" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="valid_thru">Valid thru</Label>
                <CardExpiryElement
                  className="form-control"
                  onChange={(e) => {
                    handleChange({
                      target: { name: "valid_thru", value: e.complete },
                    });
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="valid_thru" component="div" className="err-msg" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="cvv">CVV</Label>
                <CardCvcElement
                  className="form-control"
                  onChange={(e) => {
                    handleChange({
                      target: { name: "cvv", value: e.complete },
                    });
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="cvv" component="div" className="err-msg" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="card_holder_name">Card Holder Name</Label>
                <Field
                  type="text"
                  className="form-control"
                  name="card_holder_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.card_holder_name}
                />
                <ErrorMessage name="card_holder_name" component="div" className="err-msg" />
              </FormGroup>

              <FormGroup check>
                <Label check>
                  <Field
                    type="checkbox"
                    name="default_card"
                    className="form-check-input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.default_card}
                  />
                  Use this as default card for payment
                </Label>
                <ErrorMessage name="default_card" component="div" className="err-msg" />
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Button
                className="btn btn-solid btn-xs"
                type="submit"
                disabled={props.loading}
              >
                {props.loading ? "Please Wait..." : "Add"}
              </Button>
              <Button
                type="button"
                className="btn btn-secondary btn-xs"
                onClick={props.hideModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCard;
/* Copyright Information: Created by BuyGroup */
