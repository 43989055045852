/* Copyright Information: Created by BuyGroup */
import { FETCH_DATA_SUCCESS_CARD, REMOVE_CARD, ADD_CARD } from "./actions";
import { cardList, removeCard, addCardList } from "../services/CardServices";

export const fetchCardSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS_CARD,
  card: data,
});

/* This function call cardList Api */
export const getCard = (callBack) => {
  return (dispatch) => {
    cardList()
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCardSuccess(res.data));
          callBack();
        }
      })
      .catch((error) => {
        console.log("error", error);
        callBack();
      });
  };
};

export const removeCardSuccess = (id) => ({
  type: REMOVE_CARD,
  id: id,
});

/* This function call delete card Api */
export const deleteCard = (id, callBack) => {
  return (dispatch) => {
    removeCard(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(removeCardSuccess(id));
          callBack();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
};

// Add Cart
export const addCardSuccess = (data, group_id) => ({
  type: ADD_CARD,
  data: data,
});

/* This function call add card Api */
export const CardAdd = (data, callback) => {
  return (dispatch) => {
    addCardList(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(addCardSuccess(res.data[0]));
          callback(res);
        }
      })
      .catch((error) => {
        console.log("error", error);
        callback(error);
      });
  };
};
/* Copyright Information: Created by BuyGroup */