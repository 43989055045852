/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationAddSchema } from "./CustomerValidationSchema";
import { City } from "country-state-city";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import CountryListJson from "../../Json/CountryListJson";
import StateListJson from "../../Json/StateListJson";
import { userRegistration } from "../../services/AuthServices";
import { toast } from "react-toastify";
import { Row, Col, Button, FormGroup, Label, Container } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FormLabel } from "react-bootstrap";
const Register = (props) => {
  const history = useHistory();
  const initialValues = {
    address_type: "BILLING_ADDRESS",
    company_name: "",
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    country: "United States",
    street: "",
    zip_code: "",
    password: "",
    confirmPassword: "",
    customer_type: "Individual",
    is_tax_exempt: false,
    other_docs: [],
    tax_exempt_doc: [],
  };

  // state variable in upload document File
  const [documentFile, setDocumentFile] = useState([]);

  // state variable in files
  const [files, setFiles] = useState([]);

  // state variable in copy of files Data
  const [filesData, setFilesData] = useState([]);

  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "userInitialValues" store user initial value */
  const [userInitialValues, setUserInitialValues] = useState(initialValues);

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState({
    value: "US",
    label: "United States",
    });

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState("");

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState("");

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState();

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  useEffect(() => {
    getCountryList();
    const countryisoCode = countriesValue.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");

  }, []);

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("country", selectedCountry.label);
    setFieldTouched("country", false);
    setFieldValue("state", "");
    setFieldValue("city", "");

    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("state", selectedState.label);
    setFieldValue("city", "");
    setFieldTouched("state", false);

    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("city", selectCity.label);
    setFieldTouched("city", false);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   */
  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("phone_number", selectphone);
  };

  // Upload pdf file than convert to base 64 and store the data in the state
  const readPDF = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);
      let valid = false;
      var sCurExtension = getFiles[0];

      const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

      if ((checkFileTyype == ".pdf", ".doc", ".docx")) {
        valid = true;
      } else {
        valid = false;
      }

      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  file_name: file.name,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (pdf) => {
            /* Once all promises are resolved, update state with PDF URI array */

            setDocumentFile(pdf);
            setFieldValue("tax_exempt_doc", pdf);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };

  // Upload multiple image than convert to base 64 and store the data in the state
  const readURI = (e, setFieldValue) => {
    // setUploadButton(true);

    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      let valid = false;
      for (var j = 0; j < getFiles.length; j++) {
        var sCurExtension = getFiles[j];

        const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

        if ((checkFileTyype == ".pdf", ".doc", ".docx")) {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  file_name: file.name,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (images) => {
            /* Once all promises are resolved, update state with image URI array */

            const mergeFiles = files.concat(images);
            setFiles(mergeFiles);
            setFilesData(mergeFiles);
            setFieldValue("other_docs", mergeFiles);
            // setUploadButton(false);
            e.target.value = "";
          },
          (error) => {
            console.error(error);
          }
        );
      }
      return false;
    } else {
      console.log("Hi Else");
    }
  };

  // Delete Images after uploading
  const handleDeleteTaxExempt = (e, index, setFieldValue) => {
    const copyFiles = [...documentFile];
    copyFiles.splice(index, 1);
    setDocumentFile(copyFiles);
    setFieldValue("tax_exempt_doc", copyFiles);
  };

  // Delete Images after uploading
  const handleDeleteImage = (e, index, setFieldValue) => {
    const copyFiles = [...files];
    copyFiles.splice(index, 1);
    setFiles(copyFiles);
    setFieldValue("other_docs", copyFiles);
  };
  // const b64toBlob = async (b64Data) => {
  //   const resp = await fetch(fileURI);
  //   const imageBody = await resp.blob();
  //   pdfUrl.push(imageBody)
  //   return imageBody;
  // }

  // const b64toBlob = async (b64Data, contentType = "") => {
  //   const base64Response = await fetch(`data:${contentType};base64,${b64Data}`);
  //   const blob = await base64Response.blob();
  //   console.log("blob", blob);
  //   return blob;
  // };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    console.log("base64", blob);
    return blob;
  };

  /**
   * This function send New Suppliers data
   * @param  {customerData} customerData data Object
   */

  const onSubmit = (customerData, onSubmitProps) => {
    console.log("customerData", customerData);
    if (customerData.title === "") {
      delete customerData.title;
    }
    if (customerData.company_name == "") {
      delete customerData.company_name;
    }
    setIsLoadingButton(true);
    const uploadOtherDocumentArray = customerData.other_docs.map(function (x) {
      return {
        mime_type: x.mime_type,
        file_name: x.file_name,
      };
    });
    customerData.other_docs = uploadOtherDocumentArray;
    const uploadDocumentArray = customerData.tax_exempt_doc.map(function (x) {
      return {
        mime_type: x.mime_type,
        file_name: x.file_name,
      };
    });
    customerData.tax_exempt_doc = uploadDocumentArray;
    // console.log("customerData", customerData);
    // return;
    userRegistration(customerData)
      .then((res) => {
        if (res.status === 200) {
          const getLenght = res.data.tax_doc_uploadUrls.length;
          res.data.tax_doc_uploadUrls.map((url, index) => {
            const getData = documentFile[index];
            const contentType = getData.mime_type;
            const b64Data = getData.path;
            const replaceDataImage = b64Data.replace(
              /data:application.*;base64,/,
              ""
            );
            const blob = b64toBlob(replaceDataImage, contentType);
            fetch(url.uploadURL, {
              method: "PUT",
              body: blob,
            })
              .then(function () {
                if (getLenght === index + 1) {
                  toast.success("File Uploaded Successfully", {
                    autoClose: 3000,
                    hideProgressBar: true,
                  });
                }
              })
              .catch(function (error) {
                console.log("error", error);
              });
          });
          res.data.other_docs_uploadUrls.map((url, index) => {
            const getData = filesData[index];

            const contentType = getData.mime_type;
            const b64Data = getData.path;
            const replaceDataImage = b64Data.replace(
              /^data:application\/[a-z]+;base64,/,
              ""
            );
            console.log("getData1", getData);
            console.log("url1", url);
            console.log("replaceDataImage2", replaceDataImage);
            const blob = b64toBlob(replaceDataImage, contentType);
            console.log("blob1", blob);
            fetch(url.uploadURL, {
              method: "PUT",
              body: blob,
            })
              .then(function () {
                console.log("Document upload done");
                setUserInitialValues(initialValues);
                history.push("/login");
              })
              .catch(function (error) {
                console.log("error", error);
              });
          });
          setIsLoadingButton(false);
          setUserInitialValues(initialValues);
          history.push("/login");
          toast.success(res.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        setIsLoadingButton(false);
        console.log("error.message", error.message);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        // onSubmitProps.resetForm();
        setUserInitialValues(initialValues);
      });
  };

  // handleCountry(countriesValue, setFieldValue, setFieldTouched);

  return (
    <div className="container-fluid">
      <section className="register-page login-page section-b-space">
        <div></div>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center" style={{ marginBottom: "25px" }}>
                <img src={logo} alt="" className="img-fluid" />
              </div>
              <h3 className="text-center">Create account</h3>
              <p className="text-center lead_login_txt">
                Already have an account{" "}
                <Link
                  to={{
                    pathname: "/login",
                  }}
                >
                  Login
                </Link>
              </p>
              <div className="theme-card">
                <Formik
                  initialValues={userInitialValues}
                  onSubmit={(values, onSubmitProps) => {
                    console.log(values, values);
                    onSubmit(values, onSubmitProps);
                  }}
                  validationSchema={validationAddSchema}
                >
                  {({
                    setFieldValue,
                    handleBlur,
                    setFieldTouched,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => {

                    // handleCountry(countriesValue, setFieldValue, setFieldTouched);

                    return (<Form className="theme-form">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              First Name
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="first_name"
                            />
                            <ErrorMessage name="first_name">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Last Name
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="last_name"
                            />
                            <ErrorMessage name="last_name">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>
                      </Row>
                      {
                        values.customer_type == 'Business' ? ( 
                          <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Company Name
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="company_name"
                            />
                            {/* <ErrorMessage name="company_name">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage> */}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              title
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="title"
                            />
                            {/* <ErrorMessage name="title">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage> */}
                          </FormGroup>
                        </Col>
                      </Row>
                        ) : null 
                      }
                      
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Phone Number
                            </Label>

                            <PhoneInput
                              defaultCountry="US"
                              international={false}
                              value={phoneValue}
                              onBlur={handleBlur}
                              maxLength={14}
                              name="phone_number"
                              className="form-control"
                              onChange={(value) =>
                                handlePhoneNumber(value, setFieldValue)
                              }
                            />

                            <ErrorMessage name="phone_number">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Email
                            </Label>

                            <Field
                              type="email"
                              className="form-control"
                              name="email"
                            />

                            <ErrorMessage name="email">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col md="6">
                          <FormGroup disabled>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Country
                            </Label>

                            <Select
                              value={countriesValue}
                              noOptionsMessage={() => "No country found"}
                              // onChange={(value) =>
                              //   handleCountry(
                              //     value,
                              //     setFieldValue,
                              //     setFieldTouched
                              //   )
                              // }
                              // options={countries}
                              onChange={(value) =>(
                                console.log("")
                              )}
                              name="country"
                              placeholder="Select Country"
                              disabled
                            />

                            <ErrorMessage name="country">
                              {(errorMsg) => (
                                <label className="err-msg">{errorMsg}</label>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              State
                            </Label>

                            <Select
                              value={statesValues}
                              noOptionsMessage={() =>
                                noDataStateMessage
                                  ? "No State Found"
                                  : "First select country"
                              }
                              onChange={(value) =>
                                handleState(
                                  value,
                                  setFieldValue,
                                  setFieldTouched
                                )
                              }
                              options={filterStates}
                              name="state"
                              placeholder="Select State"
                            />

                            <ErrorMessage name="state">
                              {(errorMsg) => (
                                <label className="err-msg">{errorMsg}</label>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <Row>

                      <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              State
                            </Label>

                            <Select
                              value={statesValues}
                              noOptionsMessage={() =>
                                noDataStateMessage
                                  ? "No State Found"
                                  : "First select country"
                              }
                              onChange={(value) =>
                                handleState(
                                  value,
                                  setFieldValue,
                                  setFieldTouched
                                )
                              }
                              options={filterStates}
                              name="state"
                              placeholder="Select State"
                            />

                            <ErrorMessage name="state">
                              {(errorMsg) => (
                                <label className="err-msg">{errorMsg}</label>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>


                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              City
                            </Label>

                            <Select
                              value={cityValues}
                              noOptionsMessage={() =>
                                noDataCityMessage
                                  ? "No City Found"
                                  : "First select state"
                              }
                              onChange={(value) =>
                                handleCity(
                                  value,
                                  setFieldValue,
                                  setFieldTouched
                                )
                              }
                              options={filterCity}
                              name="city"
                              placeholder="Select City"
                            />

                            <ErrorMessage name="city">
                              {(errorMsg) => (
                                <label className="err-msg">{errorMsg}</label>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>

                        
                      </Row>
                      <Row>

                      <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Street
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="street"
                            />

                            <ErrorMessage name="street">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>


                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Zip Code
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="zip_code"
                            />
                            <ErrorMessage name="zip_code">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>

                        
                      </Row>
                      <Row>

                      <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Password
                            </Label>
                            <Field
                              type="password"
                              className="form-control"
                              name="password"
                            />
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>

                        
                        <Col md="6">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Confirm Password
                            </Label>
                            <Field
                              type="password"
                              className="form-control"
                              name="confirmPassword"
                            />
                            <ErrorMessage name="confirmPassword">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Customer Type
                            </Label>
                            <div role="group" aria-labelledby="my-radio-group">
                              <FormLabel className="mr-5">
                                <Field
                                  type="radio"
                                  name="customer_type"
                                  value="Individual"
                                />
                                Individual
                              </FormLabel>
                              <FormLabel>
                                <Field
                                  type="radio"
                                  name="customer_type"
                                  value="Business"
                                />
                                Business
                              </FormLabel>
                            </div>
                          </FormGroup>
                        </Col>
                        {values.customer_type == "Business" ? (
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="recipient-name"
                                className="col-form-label"
                              >
                                Tax Exempt?
                              </Label>
                              <div
                                role="group"
                                aria-labelledby="checkbox-group"
                              >
                                <FormLabel className="mr-5">
                                  <Field type="checkbox" name="is_tax_exempt" />
                                  Tax Exempt
                                </FormLabel>
                              </div>
                            </FormGroup>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      {values.customer_type == "Business" &&
                      values.is_tax_exempt ? (
                        <Row>
                          <Col md="12">
                            <FormGroup className="form-group mb-3 row">
                              <Label className="col-form-label">
                                Upload Tax Document / Certificate
                              </Label>
                              <input
                                className="show-for-sr"
                                type="file"
                                id="tax_exempt_doc"
                                name="tax_exempt_doc[]"
                                accept="application/pdf, .doc, .docx"
                                onChange={(e) => readPDF(e, setFieldValue)}
                              />
                              <label htmlFor="tax_exempt_doc">
                                <i className="fa fa-plus"></i> Upload Document
                              </label>
                              <ErrorMessage name="tax_exempt_doc">
                                {(msg) => (
                                  <div className="input-feedback">{msg}</div>
                                )}
                              </ErrorMessage>
                              <div className="col-xl-12 col-sm-12">
                                <div className="clearfix  show_pdf_name">
                                  {documentFile.map((pdfURI, index) => {
                                    return (
                                      <li className="uploaded-file">
                                        <p
                                          className="truncate"
                                          title={pdfURI.file_name}
                                        >
                                          {pdfURI.file_name}
                                        </p>
                                        <span
                                          onClick={(e) =>
                                            handleDeleteTaxExempt(
                                              e,
                                              index,
                                              setFieldValue
                                            )
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                      </li>
                                    );
                                  })}
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="form-group mb-3 row">
                              <Label className="col-form-label">
                                Upload Other Document
                              </Label>
                              <>
                                <input
                                  className="show-for-sr"
                                  type="file"
                                  name="other_docs[]"
                                  multiple
                                  accept="application/pdf, .doc, .docx"
                                  onChange={(e) => readURI(e, setFieldValue)}
                                  id="file"
                                />
                                <label htmlFor="file">
                                  <i className="fa fa-plus"></i> Upload Other
                                  Document
                                </label>
                              </>

                              <ul className="photo-container">
                                {files.map((imageURI, index) => {
                                  return (
                                    <li className="uploaded-file">
                                      <p
                                        className="truncate"
                                        title={imageURI.file_name}
                                      >
                                        {imageURI.file_name}
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleDeleteImage(
                                            e,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col md="12">
                          <p>
                            <i>
                              This address will be your billing and shipping
                              address at initial setup. If you want to update,
                              you can do it later in your profile later
                              separately.
                            </i>
                          </p>
                        </Col>
                      </Row>
                      <Button
                        className="btn btn-solid"
                        color="primary"
                        type="submit"
                        disabled={isLoadingButton}
                      >
                        {isLoadingButton ? "Please Wait..." : "Submit"}
                      </Button>
                    </Form>)
                  }}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default Register;
/* Copyright Information: Created by BuyGroup */
