/* Copyright Information: Created by BuyGroup */
import Api from "../Api";
export const categoryList = () => {
  return Api.get("category")
    .then((res) => res.data)
    .catch((err) => console.log("error in category list data ", err));
};
export const getGroups = (filterData) => {
  return Api.post("customer/group-list", filterData).then((res) => res.data);
};

export const getSearchGroups = (filterData) => {
  return Api.get(`customer/product-search/${filterData}`).then(
    (res) => res.data
  );
};
/* Copyright Information: Created by BuyGroup */