/* Copyright Information: Created by BuyGroup */
import Roles from "../config/Roles";

export function getAuthRedirectUrl() {
  let role = JSON.parse(localStorage.getItem("role"));
  switch (role[0]) {
    case Roles.CUSTOMER:
      return "/";
    case Roles.ADMIN:
      return "/";

    default:
      return "/";
  }
}
/* Copyright Information: Created by BuyGroup */