/* Copyright Information: Created by BuyGroup */
import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";

const PrivacyPolicyMobile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="policy-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div className="logo-format">
              <img src={logo} alt="" className="img-fluid" />
            </div>
            <h1>Privacy Policy for BuyGroup</h1>
            <h5>
              <strong>Last Updated At : </strong> 04/03/2024
            </h5>
            <br></br>
            <div class="policy-content2">
              <h4>Introduction</h4>
              <p>
                Consolidated Purchasing, LLC (dba “BuyGroup”) and its affiliates
                are committed to protecting your privacy. This policy describes
                how we collect, use, and share your information when you use our
                mobile app and visit our website,{" "}
                <a href="mailto:support@buygroup.com">support@buygroup.com</a>.
              </p>

              <br />
              <div>
                <h4>Information Collection and Use :</h4>

                <p>
                  <strong>Personal Data: </strong> Upon signing up for our
                  Service, we collect certain user data including email address,
                  phone number, city, state, document (optional) and zip code.
                  This information is collected to facilitate account creation,
                  provide personalized services, and improve user experience.
                </p>

                <p>
                  {" "}
                  <strong>Files and Document: </strong>BuyGroup requires
                  files and documents (optional) permission to upload tax document and other documents.{" "}
                </p>

                <p>
                  <strong>Access Request Purpose String: </strong>We request
                  access to your personal data to provide you with a personalized
                  experience and improve our app's functionality. Your data will
                  be securely transmitted to our servers for analysis and
                  enhancement of our services.
                </p>

                <p>
                  <strong>Payment Processing: </strong>Our application utilizes
                  Stripe for payment processing. As part of this process, credit
                  card information is securely transmitted to Stripe in accordance
                  with their PCI DSS (Payment Card Industry Data Security
                  Standard) compliance requirements. Stripe is a certified PCI
                  Level 1 Service Provider, ensuring the highest level of security
                  for handling payment information. We prioritize the protection
                  of your sensitive data and adhere to industry standards to
                  safeguard your privacy and security.
                </p>
              </div>

              <br />
              <div>
              <h4>Use of Data</h4>
              <p>
                We use the collected data for various purposes, including but
                not limited to:
              </p>

              <ul>
                <li>To provide and maintain our App</li>
                <li>To provide customer support</li>
                <li>To monitor the usage of our App</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>
              </div>

              <br />
              <div>
                <h4>Disclosure of Data</h4>
                <p>
                  <strong>Legal Requirements:</strong> Consolidated Purchasing,
                  LLC (dba “BuyGroup”) may disclose your Personal Data in the good
                  faith belief that such action is necessary to:
                </p>

                <ul>
                  <li>To comply with a legal obligation</li>
                  <li>
                    To protect and defend the rights or property of Consolidated
                    Purchasing
                  </li>
                  <li>
                    To prevent or investigate possible wrongdoing in connection
                    with the App
                  </li>
                  <li>
                    To protect the personal safety of users of the App or the
                    public
                  </li>
                  <li>To protect against legal liability</li>
                </ul>

                <p>
                  You have the right to be informed about the personal data we
                  collect, the purpose of the processing, and the rights you have
                  over your data. As a user of BuyGroup, you have the following
                  data protection rights:
                </p>

                <ul>
                  <li>
                    Right to Access: You can request copies of your personal data
                    that we hold.
                  </li>
                  <li>
                    Right to Rectification: You have the right to request
                    corrections to your information if it is inaccurate or
                    incomplete.
                  </li>
                  <li>
                    Right to Erasure: You can request the deletion of your
                    personal data under certain conditions.
                  </li>
                  <li>
                    Right to Restrict Processing: You can request that we restrict
                    the processing of your personal data under certain conditions.
                  </li>
                  <li>
                    Right to Object to Processing: You have the right to object to
                    the processing of your personal data under certain conditions.
                  </li>
                  <li>
                    Right to Data Portability: You can request to transfer your
                    personal data to another organization or directly to you under
                    certain conditions.
                  </li>
                </ul>
                <p>
                  If you wish to exercise any of these rights, please contact us
                  using the contact details provided below.
                </p>
              </div>
              <br />

              <div>
                <h4>Security of Data:</h4>
                <p>The security of your data is
                  important to us but remember that no method of transmission over
                  the Internet, or method of electronic storage is completely
                  secure. While we strive to use commercially acceptable means to
                  protect your Personal Data, we cannot guarantee its absolute
                  security.
                </p>
              </div>


              <div>
                <h4>Account Deletion Option:</h4>
                <p>
                  To exercise the
                  access, data portability, and deletion rights described above,
                  please submit a verifiable consumer request to us via email to{" "}
                  <a href="mailto:support@buygroup.com">support@buygroup.com</a>.
                  The verifiable consumer request must:
                </p>
                <ul>
                  <li>
                    Provide sufficient information that allows us to reasonably
                    verify you are the person about whom we collected personal
                    information or an authorized representative.
                  </li>
                  <li>
                    Describe your request with sufficient detail that allows us to
                    properly understand, evaluate, and respond to it.
                  </li>
                </ul>
              </div>


              <div>
                <h4>Changes to Our Privacy Policy</h4>
                <p> We may update
                  our privacy policy from time to time. If we make material
                  changes to how we treat our Users' personal information, we will
                  post the new privacy policy on this page and notify you by email
                  and/or text message to the email address and/or phone number
                  specified in your account. The date the privacy policy was last
                  revised is identified at the top of the page. You are
                  responsible for ensuring we have an up-to-date active and
                  deliverable email address and/or phone number for you and for
                  periodically visiting this privacy policy to check for any
                  changes.
                </p>
              </div>

              <div>
                <h4>Contact Information</h4>
                <p>
                  To ask questions or
                  comment about this privacy policy and our privacy practices,
                  contact us at:{" "}
                  <a href="mailto: support@buygroup.com">support@buygroup.com</a>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyMobile;
/* Copyright Information: Created by BuyGroup */