/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const cardList = () => {
  return Api.get("card")
    .then((res) => res.data)
    .catch((err) => console.log("error in card list data ", err));
};

export const addCardList = (data) => {
  return Api.post("card", data).then((res) => res.data);
};

export const removeCard = (id) => {
  return Api.delete(`card/${id}`)
    .then((res) => res.data)
    .catch((err) => console.log("error in remove card ", err));
};
export const updateCard = (id) => {
  return Api.put(`card/${id}`).then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
