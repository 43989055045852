/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import NavBar from "./common/navbar";
import { Container, Row, Col } from "reactstrap";
import logo from "../../assets/images/logo.png";
import SearchOverlay from "./common/search-overlay";
import { Link, useHistory } from "react-router-dom";
import CartIcon from "../../assets/images/carticon.png";
import { isLoggedIn } from "../../utils/index";
import { connect } from "react-redux";
import { getCart } from "../../actions/cart";
import { getSearchGroups } from "../../services/HomeServices";
import Autosuggest from "react-autosuggest";
const HeaderTwo = (props) => {
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setTimeout(function () {
      document.querySelectorAll(".loader-wrapper").style = "display:none";
    }, 2000);
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      props.getCart();
    }
  }, [props.location]);

  const handleChangeAutoSuggest = (event, { newValue }) => {
    if (newValue === "") {
      history.push("/");
    }
    setSearchValue(newValue);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        history.push(`/search/${event.target.value}`);
      }
    }
  };
  const getGroupList = (product_name) => {
    setLoading(true);
    let filterData = {
      limit: 5,
      product_name: product_name,
    };

    getSearchGroups(product_name)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          setSuggestionList(response.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const languages = [
    {
      name: "C",
      year: 1972,
    },
    {
      name: "Elm",
      year: 2012,
    },
    {
      name: "jav",
      year: 2012,
    },
  ];

  const onSuggestionsFetchRequested = (value) => {
    getGroupList(value.value);
  };
  const onSuggestionsClearRequested = (value) => {
    setSuggestionList([]);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.name;
  const renderSuggestion = (suggestion) => {
    return <div className="suggestion-box">{suggestion.name}</div>;
  };
  history.listen((location, action) => {
    // location is an object like window.location
    if (location.pathname.includes("search")) {
    } else {
      setSearchValue("");
    }
  });
  const inputProps = {
    placeholder: "Search the product...",
    value: searchValue,
    onChange: handleChangeAutoSuggest,
    onKeyDown: handleKeyDown,
    className: "form-control",
  };
  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setSearchValue(suggestionValue);
    history.push(`/search/${suggestionValue}`);
  };
  const onClickSearch = () => {
    history.push(`/search/${searchValue}`);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div>
      <header id="sticky" className={`${props.headerClass}`}>
        <div className="mobile-fix-option"></div>
        <div className="header_wrapper">
          <div className="main_header">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="header_wrap d-flex align-items-center justify-content-between">
                    <div className="logo">
                      <Link
                        to={{
                          pathname: "/",
                        }}
                      >
                        <img src={logo} alt="" className="img-fluid" />
                      </Link>
                    </div>

                    <div className="nav-search desktop_search">
                      <div className="input-group">
                        <Autosuggest
                          suggestions={suggestionList}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          onSuggestionSelected={onSuggestionSelected}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                        />
                        <div></div>

                        <div className="input-group-append">
                          <button
                            className="btn search_btn"
                            type="button"
                            onClick={onClickSearch}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="header_right d-flex align-items-center">
                      {isLoggedIn() ? (
                        <>
                          <div className="account_head_icon ml-4 main-navbar-cart">
                            <ul className="nav-menu">
                              <li className="">
                                <Link
                                  to={`/cart`}
                                  className="cart_icon nav-link"
                                >
                                  <div className="acc_icon_head">
                                    <img
                                      src={CartIcon}
                                      alt="Cart"
                                      className="img-fluid media"
                                    />
                                    <span className="cart_value">
                                      {props.cart.line_item_list &&
                                        props.cart.line_item_list.length}
                                    </span>
                                  </div>
                                  <p>My Cart</p>
                                </Link>
                                <ul
                                  className="nav-submenu show-div shopping-cart"
                                  style={{
                                    display: "none",
                                  }}
                                >
                                  {props.cart.length > 0
                                    ? props.cart.map((item) => {
                                        return (
                                          <>
                                            <li>
                                              <div className="media">
                                                <div className="media-body">
                                                  <h4>
                                                    <span>
                                                      {item.qty}&nbsp;x&nbsp;
                                                      {item.total}
                                                    </span>
                                                  </h4>
                                                </div>
                                              </div>
                                              <div className="close-circle">
                                                <a>
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                  ></i>
                                                </a>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      })
                                    : "No data Available"}
                                  <div>
                                    <li>
                                      <div className="buttons view-cart">
                                        <a href="#">view cart</a>
                                      </div>
                                    </li>
                                  </div>
                                </ul>
                              </li>
                            </ul>
                          </div>
                          <ul className="d-flex align-items-center account_menus">
                            <li className="onhover-dropdown mobile-account">
                              <Link className="nav-link">
                                <i className="fa fa-user pr-2"></i>
                                <span className="account_text">
                                  Account
                                </span>{" "}
                                <span className="fa fa-angle-down ml-1"></span>
                              </Link>
                              <ul className="onhover-show-div">
                                <li>
                                  <Link to="/dashboard">Dashboard</Link>
                                </li>
                                <li></li>
                                <li>
                                  <a href="#" onClick={handleLogout}>
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <ul className="d-flex align-items-center account_menus">
                          <li>
                            <Link to="/login">Sign In</Link>
                          </li>
                          <li>
                            <Link to="/register">Register</Link>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header_wrapper_navbar">
            <Container>
              <Row>
                <Col>
                  <div className="main-nav-center">
                    <NavBar />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
      <SearchOverlay />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  getCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTwo);
/* Copyright Information: Created by BuyGroup */