/* Copyright Information: Created by BuyGroup */
import {
  FETCH_DATA_SUCCESS_CARD,
  REMOVE_CARD,
  ADD_CARD,
} from "../actions/actions";

const initialState = {
  card: [],
};

function cardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA_SUCCESS_CARD:
      return {
        card: action.card,
      };
    case REMOVE_CARD:
      return { card: state.card.filter((c) => c.id !== action.id) };
    case ADD_CARD:
      return {
        card: state.card.concat([action.data]),
      };
    default:
      return state;
  }
}

export default cardReducer;
/* Copyright Information: Created by BuyGroup */