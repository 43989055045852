/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CardDeleteConfirmation = (props) => {
  return (
    <Modal isOpen={props.modal} centered>
      <ModalHeader toggle={props.hideModal}>Confirmation</ModalHeader>
      <ModalBody>Are you sure you want to delete this.</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="btn btn-solid btn-xs"
          onClick={() => props.handleDeleteCard(props.cardId)}
          disabled={props.loading}
        >
          {props.loading ? "Loading ..." : "Yes, Delete it"}
        </Button>
        <Button color="secondary" onClick={props.hideModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CardDeleteConfirmation;
/* Copyright Information: Created by BuyGroup */
