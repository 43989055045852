/* Copyright Information: Created by BuyGroup */
import React from "react";
import anxiety from "../../assets/images/UserVerification/anxiety.png";
const UnVerified = () => {
  return (
    <div>
    <div className="email-container">
			<div className="main-content">
      <div align="center">	
					<div className="" style={{position: "relative",height: "140px"}}>
						<img className="animate" style={{display:"block",fontSize:"0px",border:"0px"}} src={anxiety} alt="logo" width="100" />

					</div>
					<div className="img-effect"></div>
        </div>
        <div align="center">
					<h1 > Ohh No...</h1>
					<h4 >Your email not verified!!</h4>
				</div>
      </div>
      </div>
      <div className="bottom-text">
			   You can close this window and head back to application!
		  </div>
    </div>
  );
};

export default UnVerified;
/* Copyright Information: Created by BuyGroup */