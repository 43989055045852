/* Copyright Information: Created by BuyGroup */
import { FETCH_DATA_SUCCESS_CATEGORY } from "../actions/actions";

const initialState = {
  category: [],
};

function catgoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA_SUCCESS_CATEGORY:
      return {
        category: action.category,
      };
    default:
      return state;
  }
}

export default catgoryReducer;
/* Copyright Information: Created by BuyGroup */
