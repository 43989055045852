/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import HomePage from "../../Home/HomePage";
import Category from "../Category/Category";
import Cart from "../../Cart/Cart";
import ViewProduct from "../product/ViewProduct";
import SearchProduct from "../../SearchProduct/SearchProduct";
import Account from "../../Account/Account";
import ContactUs from "../Pages/ContactUs";
import FAQ from "../Pages/FAQ";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsCondition from "../Pages/TermsCondition";
import Checkout from "../../Checkout/Checkout";
import PlaceOrderSuccess from "../../Checkout/PlaceOrderSuccess";
import BillingAddress from "../../Account/ManageAddress/BillingAddress";
import ShippingAdress from "../../Account/ManageAddress/ShippingAdress";

export default class HomeContainer extends React.Component {

  render() {
    return (
      <div
        className={
          this.props.handleMainClass ? "main-container min-height" : "main-container full min-height"
        }
        id="main-container"
      >
        <div>
          <Switch>
            <Route exact path="/category/:id" component={Category} />
            <Route exact path="/search/:name" component={SearchProduct} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/order-success" component={PlaceOrderSuccess} />
            <Route exact path="/dashboard" component={Account} />
            <Route exact path="/product/:id" component={ViewProduct} />
            <Route exact path="/faq" component={FAQ} />
            {/* <Route exact path="/privacy-policy" component={PrivacyPolicy} /> */}
            {/* <Route exact path="/termscondition" component={TermsCondition} /> */}
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/billingAddress" component={BillingAddress} />
            <Route exact path="/shippingAdress" component={ShippingAdress} />

            <Route path="/" component={HomePage} />
          </Switch>
        </div>
      </div>
    );
  }
}
/* Copyright Information: Created by BuyGroup */