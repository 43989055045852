/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
// import Link from "next/link";
import { MENUITEMS } from "../../constant/menu";
import { Container, Row } from "reactstrap";
// import { useTranslation } from "react-i18next";
// import { useRouter } from "next/router";
import { categoryList } from "../../../services/HomeServices";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getCategory } from "../../../actions/category";

const NavBar = (props) => {
  const [navClose, setNavClose] = useState({ right: "-100%" });
  const [ mobileView , setMobileView ] = useState(false);
  const router = useHistory();
  const [menuItemList, setMenuItemList] = useState([]);
  console.log("window.location.pathname :- ",window.location.pathname);
  const currentPath = window.location.pathname ;
  useEffect(() => {
    if (window.innerWidth < 750) {
      setNavClose({ right: "-100%" });
      setMobileView(false);
    }
    if (window.innerWidth < 1199) {
      setNavClose({ right: "-100%" });
      setMobileView(false);
    }
    props.getCategory(getCategoryList);
  }, []);

  /*fetch categoryList data from categoryListApi */

  const getCategoryList = (categoryData) => {
    let menuItemlist = [{ title: "Home", type: "sub", path: "/" }];
    let lastMenuItem = {};
    let childrenForLast = [];
    categoryData.map((category, index) => {
      let menuItem = {};
      let menuChildren = [];

      menuItem.title = category.category_name;
      menuItem.type = "sub";
      menuItem.path = `/category/${category.category_id}`;

      category.sub_category.map((subCategory, subIndex) => {
        let childrenMenuItem = {};
        childrenMenuItem.title = subCategory.category_name;
        childrenMenuItem.type = "sub";
        childrenMenuItem.path = `/category/${subCategory.category_id}`;

        menuChildren.push(childrenMenuItem);
      });
      menuItem.children = menuChildren;
      if (index < 3) {
        menuItemlist.push(menuItem);
      } else {
        lastMenuItem.title = "More";
        lastMenuItem.type = "sub";
        let subMenu = {};

        let subMenuChildrenList = [];
        subMenu.title = category.category_name;
        subMenu.type = "sub";
        subMenu.path = `/category/${category.category_id}`;
        category.sub_category.map((subCategory, subIndex) => {
          let subMenuChildren = {};
          subMenuChildren.title = subCategory.category_name;
          subMenuChildren.type = "link";
          subMenuChildren.path = `/category/${subCategory.category_id}`;
          subMenuChildrenList.push(subMenuChildren);
        });

        subMenu.children = subMenuChildrenList;

        childrenForLast.push(subMenu);

        lastMenuItem.children = childrenForLast;
      }

      //menuItemlist.push(menuItem)
    });
    menuItemlist.push(lastMenuItem);
    setMenuItemList(menuItemlist);
  };

  const openNav = () => {
    setNavClose({ right: "0px" });
    setMobileView(true);
    // if (router.asPath == "/layouts/Gym")
    //   document.querySelector("#topHeader").classList.add("zindex-class");
  };

  const closeNav = () => {
    setNavClose({ right: "-100%" });
    setMobileView(false);
    // if (router.asPath == "/layouts/Gym")
    //   document.querySelector("#topHeader").classList.remove("zindex-class");
  };
  // eslint-disable-next-line

  const handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function (value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };

  const [mainmenu, setMainMenu] = useState();

  const setNavActive = (item) => {
    MENUITEMS.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = false;
          }
        });
      }
    });

    // setMainMenu({ mainmenu: MENUITEMS });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      menuItemList.forEach((a) => {
        if (menuItemList.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: menuItemList });
  };

  const openMblNav = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu"))
      event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function (value) {
        value.classList.remove("opensubmenu");
      });
      document
        .querySelector(".mega-menu-container")
        .classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  return (
    <div>
      <div 
      className={ mobileView ? "overlay123456" : ""}
      onClick={
        closeNav.bind(this)
      }></div>
      <div className="main-navbar ">
        <div id="mainnav">
          <div className="toggle-nav" onClick={openNav.bind(this)}>
            <i className="fa fa-bars sidebar-bar"></i>
          </div>
          <ul className="nav-menu" style={navClose}>
            <li className="back-btn" onClick={closeNav.bind(this)}>
              <div className="mobile-back text-right">
                <span>Back</span>
                <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
              </div>
            </li>
            {menuItemList.map((menuItem, i) => {
              return (
                <li
                  key={ `menuItemList`+ i}
                  className={` ${menuItem.megaMenu ? "mega-menu" : ""}`}
                >
                  <Link
                    // to={menuItem.path}
                    onClick={()=>{
                      closeNav();
                      router.push(menuItem.path)
                    }}
                    className={
                      menuItem.path === window.location.pathname
                        ? "disabled-link"
                        : "nav-link"
                    }
                  >
                    {" "}
                    {menuItem.title}
                    {menuItem.children && menuItem.children.length > 0 && (
                      <span className="sub-arrow"></span>
                    )}
                  </Link>
                  {menuItem.children && (
                    <ul className="nav-submenu">
                      {menuItem.children.map((childrenItem, index) => {
                        return (
                          <li
                            key={ `menuItem.children`+index}
                            className={`${
                              childrenItem.children ? "sub-menu " : ""
                            }`}
                          >
                            {childrenItem.type === "sub" ? (
                              <Link
                                // to={childrenItem.path}
                                // onClick={() => toggletNavActive(childrenItem)}
                                onClick={()=>{
                                  closeNav();
                                  router.push(childrenItem.path)
                                }}
                              >
                                {childrenItem.title}
                                {childrenItem.tag === "new" ? (
                                  <span className="new-tag">new</span>
                                ) : (
                                  ""
                                )}
                                {childrenItem.children && (
                                  <i className="fa fa-angle-right pl-2"></i>
                                )}
                              </Link>
                            ) : (
                              ""
                            )}
                            {childrenItem.type === "link" ? (
                              <Link 
                              // to={childrenItem.path} 
                              onClick={()=>{
                                closeNav();
                                router.push(childrenItem.path)
                              }}>
                                <a>
                                  {childrenItem.title}
                                  {childrenItem.tag === "new" ? (
                                    <span className="new-tag">new</span>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </Link>
                            ) : (
                              ""
                            )}
                            {childrenItem.children ? (
                              <ul
                                className={`nav-sub-childmenu ${
                                  childrenItem.active ? "menu-open " : "active"
                                }`}
                              >
                                <div>
                                  {childrenItem.children.map(
                                    (childrenSubItem, key) => (
                                      <li key={ `childrenItem.children` + key}>
                                        {childrenSubItem.type === "link" ? (
                                          <Link 
                                          // to={childrenSubItem.path}
                                          onClick={()=>{
                                            closeNav();
                                            router.push(childrenItem.path)
                                          }}
                                          >
                                            {childrenSubItem.title}
                                            {childrenSubItem.tag === "new" ? (
                                              <span className="new-tag">
                                                new
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </Link>
                                        ) : (
                                          ""
                                        )}
                                      </li>
                                    )
                                  )}
                                </div>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    category: state.category.category,
  };
};

const mapDispatchToProps = {
  getCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
/* Copyright Information: Created by BuyGroup */