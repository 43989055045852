/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { customerDetail } from "../services/AccountServices";
import { placeOrderDetails } from "../services/PlaceOrderServices";
import { CardAdd } from "../actions/card";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import { getCard } from "../actions/card";
import { useHistory } from "react-router-dom";
import AddCard from "../Account/Cards/AddCard";
import { getCart } from "../actions/cart";
import { isLoggedIn } from "../utils/index";
import UpdateCustomer from "../Account/UpdateCustomer";
import SelectAddress from "./SelectAddress";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

toast.configure();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = (props) => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);

  const [isCradLoading, setCradLoading] = useState(true);

  /* loader for card */
  const [isLoadingCard, setLoadingCard] = useState(true);

  /* loader for isLoadingPlaceOrder */
  const [isLoadingPlaceOrder, setIsLoadingPlaceOrder] = useState(false);

  /* const "userInfo" store user information */
  const [userInfo, setUserInfo] = useState({});

  /* const "cardID" store id default card */
  const [cardID, setCardID] = useState("");

  /* const  "showAddModal" responsible for open and closed add card modal*/
  const [showAddModal, setShowAddModal] = useState(false);

  /* loader for submit */
  const [submitLoading, setSubmitLoading] = useState(false);

  /* store selected address id  */
  const [selectAddress, setSelectAddress] = useState();

  const [reload, setreload] = useState(true);

  const [outOfStock, setOutOfStock] = useState([]);

  const [addCardMessage, setAddCardMessage] = useState(false);

  const [initialValues, setInitialValues] = useState({
    card_holder_name: "",
    card_number: "",
    cvv: "",
    default_card: false,
    month: "",
    year: "",
  });

  const addressInitialValues = {
    address_select: selectAddress,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCustomerDetail();
    props.getCard(listCallBack);
  }, [reload]);

  useEffect(() => {
    listCallBack();
  }, [isLoadingCard]);

  useEffect(() => {
    console.log("reload");
    setCradLoading(true);
    if (isLoggedIn()) {
      if (selectAddress !== undefined) {
        props.getCart(getCartCallBack, selectAddress);
      }
    } else {
      props.history.push(`/login`);
    }
  }, [selectAddress, reload]);

  console.log("selectAddress", selectAddress);
  const getCartCallBack = (res) => {
    console.log("getCartCallBack", getCartCallBack);
    setCradLoading(false);
  };

  /* This Function will give Customer detail */

  const getCustomerDetail = () => {
    // setLoading(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const UserId = user.customer_id;
    customerDetail(UserId)
      .then((response) => {
        if (response.status == 200) {
          setUserInfo(response.data);
          console.log("responce", response.data);
          response.data?.addressInfo.map((address) => {
            if (address.address_type === "BILLING_ADDRESS") {
              setSelectAddress(address.address_id);
            }
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  console.log("selectAddress", selectAddress);

  const listCallBack = () => {
    setTimeout(() => {
      const getID =
        props.card && props.card.filter((card) => card.default_card == true);
      setCardID(getID[0]?.id);
      setLoadingCard(false);
    }, 3000);
  };

  /* open add card modal */
  const showAddModalFunc = (e) => {
    e.preventDefault();
    setShowAddModal(true);
  };
  console.log("reload", reload);
  /* This Function will Add New card */
  const handleSubmitCard = (values, actions) => {
    setSubmitLoading(true);
    props.CardAdd(values, addCallBack);
  };

  const addCallBack = (response) => {
    if (response.status === 200) {
      setShowAddModal(false);
      setAddCardMessage(false);
      if (response.data[0].default_card) {
        setCardID(response.data[0].id);
      }
      toast.success(response.message, {
        autoClose: 3000,
        hideProgressBar: true,
      });

      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
      toast.error(response.data?.message, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  /* This Function will close add card modal */
  const hideAddModal = () => {
    setShowAddModal(false);
  };

  /* This Function Will Place order */
  const orderPlaced = (e) => {
    e.preventDefault();
    const data = {
      card_id: cardID,
    };

    console.log("data", data);

    if (data.card_id !== undefined) {
      setIsLoadingPlaceOrder(true);
      setAddCardMessage(false);
      placeOrderDetails(data)
        .then((response) => {
          if (response.status == 200) {
            toast.success(response.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
            setIsLoadingPlaceOrder(false);
            history.push("/order-success");
          }
        })
        .catch((error) => {
          setOutOfStock(error.data.data.out_of_stock);
          window.scrollTo(0, 0);
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setIsLoadingPlaceOrder(false);
        });
    } else {
      setAddCardMessage(true);
    }
  };

  /** This Function Will truncateString the long 
    @param  string string 
  */
  const truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  const handleReload = () => {
    setreload(!reload);
  };

  return (
    <>
      <div>
        {isLoadingPlaceOrder ? (
          <div className="col-md-12">
            <div className="custom-loader">
              <Spinner animation="border" />
            </div>
            <p className="text-center mt-3">Please wait...</p>
          </div>
        ) : (
          <>
            {isLoading == false && isCradLoading == false ? (
              <div className="row">
                <div className="col-lg-4">
                  <div className="shipping_adddress">
                    <SelectAddress
                      userInfo={userInfo}
                      initialValues={addressInitialValues}
                      selectAddress={(address_id) =>
                        setSelectAddress(address_id)
                      }
                      reload={handleReload}
                    />
                  </div>
                </div>
                <div className="col-lg-8 checkout_detail_column">
                  {outOfStock.length > 0 ? (
                    <>
                      {outOfStock.map((item) => {
                        return (
                          <div className="outofstock-error alert-danger">
                            {item.message}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  <div className="checkout-details">
                    <div className="d-flex flex-wrap align-items-center justify-content-between shipping_header_checkout">
                      <h3>Order Detail</h3>
                    </div>
                    <div className="order-box table-responsive" 
                    style={{"overflow-x":"auto"}}>
                      <table className="table table-responsive-1 table-bordered">
                        <thead className="">
                          <tr className="">
                            <th style={{"width": "100px"}}>Name</th>
                            <th style={{"width": "100px"}} >Price</th>
                            <th style={{"width": "40px"}}>Qty</th>
                            <th style={{"width": "100px"}}>Total</th>
                            <th style={{"width": "100px"}}>Shipping</th>
                            <th style={{"width": "100px"}}>Tax</th>
                            <th style={{"width": "120px"}}>Grand Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.cart.line_item_list.length > 0 &&
                            props.cart.line_item_list.map((item) => {
                              return (
                                <tr className="">
                                  <td style={{"width": "100px"}} >
                                    {truncateString(item.buy_group.name, 40)}
                                  </td>
                                  <td style={{"width": "100px"}}>
                                    $
                                    {parseFloat(item.price).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                  <td style={{"width": "40px"}}>{item.qty}</td>
                                  <td style={{"width": "100px"}}>
                                    $
                                    {parseFloat(
                                      item.qty * item.price
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td style={{"width": "100px"}}>
                                    $
                                    {parseFloat(item.shipping).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                  <td style={{"width": "100px"}}>
                                    $
                                    {parseFloat(item.tax_price).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                  <td style={{"width": "120px"}}>
                                    $
                                    {parseFloat(
                                      item.price * item.qty +
                                        item.tax_price +
                                        parseInt(item.shipping)
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="order-box table-responsive">
                    <ul className="sub-total">
                        <li>
                          Total Price
                          <span className="count text-right">
                            $
                            {parseFloat(props.cart.total).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </li>
                        <li>
                          Total Tax
                          <span className="count text-right">
                            $
                            {parseFloat(props.cart.total_tax).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </li>
                        <li>
                          Total Shipping
                          <span className="count text-right">
                            $
                            {parseFloat(
                              props.cart.total_shipping
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </li>
                      </ul>
                      <ul className="sub-total">
                        <li>
                          Subtotal
                          <span className="count text-right">
                            $
                            {parseFloat(props.cart.grand_total).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>

                    {isLoadingCard ? (
                      <div className="custom-loader">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <div className="checkout_card_list">
                        <div className="d-flex flex-wrap align-items-center justify-content-between shipping_header_checkout">
                          <h3>Choose Card</h3>
                          <a
                            href="#"
                            onClick={(e) => showAddModalFunc(e)}
                            className="btn btn-solid "
                          >
                            Add new card
                          </a>
                        </div>
                        <ul>
                          {props.card &&
                            props.card.map((card, index) => {
                              return (
                                <li>
                                  <div class="inputGroup">
                                    <input
                                      id={`radio_card_id_${index}`}
                                      name="radio"
                                      type="radio"
                                      value={card.id}
                                      defaultChecked={card.default_card}
                                      onChange={() => setCardID(card.id)}
                                    />
                                    <label for={`radio_card_id_${index}`}>
                                      <h5>{card.card_holder_name}</h5>
                                      <p>**** **** **** {card.card_number}</p>
                                    </label>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                    {showAddModal ? (
                      <Elements stripe={stripePromise}>
                      <AddCard
                        modal={showAddModal}
                        handleSubmitCard={handleSubmitCard}
                        hideModal={hideAddModal}
                        loading={submitLoading}
                        // initialValues={initialValues}
                      />
                    </Elements>
                      
                    ) : null}
                    <div
                      style={{
                        padding: "10px 0",
                        background: "#f8f8f8",
                        color: "black",
                        width: "35%",
                      }}
                    >
                      {addCardMessage && (
                        <p style={{ fontSize: "16px", color: "red" }}>
                          Please Select or Add New Card
                        </p>
                      )}
                    </div>

                    <div className="payment-box">
                      <div className="text-right">
                        {cardID !== "" ? (
                          <button
                            type="submit"
                            className="btn-solid btn"
                            onClick={(e) => orderPlaced(e)}
                          >
                            Place Order
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12">
                <div className="custom-loader">
                  <Spinner animation="border" />
                </div>
                <p className="text-center mt-3">Please wait...</p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    card: state.card.card,
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  getCard,
  getCart,
  CardAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
/* Copyright Information: Created by BuyGroup */
