/* Copyright Information: Created by BuyGroup */
export const FETCH_DATA_SUCCESS_CATEGORY = "fetch_data_success";
export const FETCH_DATA_SUCCESS_CART = "fetch_cart_success";
export const REMOVE_CART = "remove_cart_success";
export const ADD_CART = "add_cart_success";
export const EDIT_CART = "editcart";
export const FETCH_DATA_SUCCESS_CARD = "fetch_card_success";
export const REMOVE_CARD = "remove_card_success";
export const ADD_CARD = "add_card_success";
export const EDIT_CARD = "editcard";
export const SAVE_PRICE = "SAVE_PRICE";
/* Copyright Information: Created by BuyGroup */