/* Copyright Information: Created by BuyGroup */
import { SAVE_PRICE } from "./actions";
export const fetchPriceSuccess = (data) => {
  return {
    type: SAVE_PRICE,
    price: data.price,
    category_id: data.category_id,
  };
};

export const savePrice = (data) => {
  return (dispatch) => {
    dispatch(fetchPriceSuccess(data));
  };
};
/* Copyright Information: Created by BuyGroup */
