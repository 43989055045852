/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const placeOrderDetails = (data) => {
  return Api.post("placeorder", data).then((res) => res.data);
};

export const orderInvoice = (data) => {
  return Api.post("order/invoice", data).then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
