/* Copyright Information: Created by BuyGroup */
import * as Yup from "yup";
export const validationAddSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  // company_name: Yup.string().required("Please enter comapny name"),
  // title: Yup.string().required("Please enter title"),
  phone_number: Yup.string()
    .max(13, "invalid phone number")
    .required("Please enter phone number"),
  street: Yup.string().required("Please enter street"),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  country: Yup.string().required("Please select country"),
  zip_code: Yup.number()
    .typeError("Must be a number")
    .required("Please enter zip code"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter email address"),
  is_tax_exempt: Yup.boolean(),
  tax_exempt_doc: Yup.mixed().when("is_tax_exempt", {
    is: true,
    then: Yup.mixed()
      .required("A file is required")
      .test(
        "fileFormat",
        "We only support pdf, doc & docx format for this file upload.",
        (value) => {
          console.log("value", value);
          return (
            value &&
            [
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "docx",
              "application/msword",
            ].includes(value[0]?.mime_type)
          );
        }
      ),
  }),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: Yup.string()
    .required("Please enter your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const validationUpdateSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  phone_number: Yup.string()
    .max(13, "invalid phone number")
    .required("Please enter phone number"),
  street: Yup.string().required("Please enter street"),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  country: Yup.string().required("Please enselect country"),
  zip_code: Yup.number()
    .typeError("Must be a number")
    .required("Please enter zip code"),
});
/* Copyright Information: Created by BuyGroup */
