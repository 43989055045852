/* Copyright Information: Created by BuyGroup */
import * as Yup from "yup";

export const validationUpdateSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  phone_number: Yup.string()
    .max(13, "invalid phone number")
    .required("Please enter phone number"),
  street: Yup.string().required("Please enter street"),
  city: Yup.string().required("Please enter city"),
  state: Yup.string().required("Please enter state"),
  country: Yup.string().required("Please enter country"),
  zip_code: Yup.number()
    .typeError("Must be a number")
    .required("Please enter zip code"),
  is_tax_exempt: Yup.boolean(),
  tax_exempt_doc_value: Yup.boolean(),
  new_tax_exempt_doc: Yup.mixed().when(
    ["is_tax_exempt", "tax_exempt_doc_value"],
    {
      is: (is_tax_exempt, tax_exempt_doc_value) =>
        is_tax_exempt && tax_exempt_doc_value == false,
      then: Yup.mixed()
        .required("A file is required")
        .test(
          "fileFormat",
          "We only support pdf, doc & docx format for this file upload.",
          (value) => {
            return (
              value &&
              [
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "docx",
                "application/msword",
              ].includes(value[0]?.mime_type)
            );
          }
        ),
    }
  ),
});
/* Copyright Information: Created by BuyGroup */
