/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Verified from "./Verified";
import { toast } from "react-toastify";
import UnVerified from "./UnVerified";
import { VerifyUserAccount } from "../../services/AuthServices";
toast.configure();
const VerifyAccount = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsValue = params.get("token");
  const [isloading, setIsloading] = useState(true);
  const [isValid, setIsValid] = useState(true);

  /* Verified user is valid or not */
  useEffect(() => {
    setIsloading(true);
    const data = {
      token: paramsValue,
    };

    VerifyUserAccount(data)
      .then((res) => {
        if (res.status === 200) {
          setIsValid(true);

          setIsloading(false);
        } else {
          setIsValid(false);
          setIsloading(false);
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          setIsValid(false);
          setIsloading(false);
        } else {
          setIsValid(false);
          setIsloading(false);
        }
      });
  }, []);

  return (
    <>
      {isloading ? (
        <div className="email-loader">
          <Spinner animation="border" />
        </div>
      ) : isValid ? (
        <Verified />
      ) : (
        <UnVerified />
      )}
    </>
  );
};

export default VerifyAccount;
/* Copyright Information: Created by BuyGroup */