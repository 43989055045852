/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { updateCustomer } from "../services/AccountServices";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UpdateAddress from "./UpdateAddress";
import {
  AddUserAddress,
  UpdateUserAddress,
} from "../services/AuthServices";
import { toast } from "react-toastify";
import UpdateShipingAddress from "./UpdateShipingAddress";
import {
 
  Button,

} from "reactstrap";
import AddShippingAddress from "./AddShippingAddress";

const SelectAddress = (props) => {
  const shippingAddressInitialValues = {
    address_type: "SHIPPING_ADDRESS",
    first_name: "",
    last_name: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    street: "",
    zip_code: "",
  };

  const { userInfo, initialValues, selectAddress, reload } = props;

  const [
    openUpdateBillingAddressModal,
    setOpenUpdateBillingAddressModal,
  ] = useState(false);

  const [billingIntialValue, setBillingIntialValue] = useState({});

  const [
    openAddShippingAddressModal,
    setOpenAddShippingAddressModal,
  ] = useState(false);

  const [
    openUpdateShippingAddressModal,
    setOpenUpdateShippingAddressModal,
  ] = useState(false);

  const [shippingUpadteIntialValue, setShippingUpdateIntialValue] = useState(
    {}
  );

  const [isLoadingButton, setisLoadingButton] = useState(false);

  console.log("billingIntialValue", billingIntialValue);

  const handleSelect = (e, setFieldValue) => {
    setFieldValue("address_select", e.target.value);
    selectAddress(e.target.value);
  };
  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  console.log("userInfo",userInfo)

  useEffect(() => {
    userInfo.addressInfo.map((address, index) => {
      if (address.address_type === "BILLING_ADDRESS") {
        setBillingIntialValue(address);
      }
      if (address.address_type === "SHIPPING_ADDRESS") {
        setShippingUpdateIntialValue(address);
      }
    });
  }, []);

  console.log("billingIntialValue",billingIntialValue)
  console.log("shippingUpadteIntialValue",shippingUpadteIntialValue)

  const UpdateBillingAddress = (customerData) => {
    setisLoadingButton(true);
    const userlocalStorageData = localStorage.getItem("user");
    const userData = JSON.parse(userlocalStorageData);

    console.log("customerData", customerData);
    customerData = {
      address_id: customerData.address_id,
      address_type: customerData.address_type,
      id: customerData.id,
      title: customerData.title,
      company_name: customerData.company_name,
      first_name: customerData.first_name,
      last_name: customerData.last_name,
      phone_number: customerData.phone_number,
      country: customerData.country,
      state: customerData.state,
      city: customerData.city,
      street: customerData.street,
      zip_code: customerData.zip_code,
    };

    if (customerData.title === "" || customerData.title === null) {
      delete customerData.title;
    }
    if (
      customerData.company_name === "" ||
      customerData.company_name === null
    ) {
      delete customerData.company_name;
    }

    updateCustomer(customerData)
      .then((response) => {
        if (response.status === 200) {
          userData.first_name = customerData.first_name;
          userData.company_name = customerData.company_name;
          userData.title = customerData.title;
          userData.phone_number = customerData.phone_number;

          userData.name =
            customerData.first_name + " " + customerData.last_name;
          localStorage.setItem("user", JSON.stringify(userData));

          toast.success(response?.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setisLoadingButton(false);
          setOpenUpdateBillingAddressModal(false);
          reload();
        }
      })
      .catch((error) => {
        setisLoadingButton(false);
        toast.error(error?.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  console.log()

  const handleAddShippingAddress = (preapreCustomerData) => {
    setisLoadingButton(true)
    AddUserAddress(preapreCustomerData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setisLoadingButton(false);
          setOpenAddShippingAddressModal(false);
          reload();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setisLoadingButton(false);
        toast.error(error.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  const UpdateShippingAddress = (UpdatedCustomerData) => {
    delete UpdatedCustomerData.customer_id
    setisLoadingButton(true)
    UpdateUserAddress(UpdatedCustomerData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
        setisLoadingButton(false);
        setOpenUpdateShippingAddressModal(false);
        reload();
      })
      .catch((error) => {
        console.log("error", error);

        toast.error(error.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };
  return (
    <div>
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue }) => (
          <Form>
            {userInfo.addressInfo.map((address) => {
              if (address.address_type === "BILLING_ADDRESS") {
                return (
                  <div className="addressSelect">
                    <div>
                      <Field
                        type="radio"
                        name="address_select"
                        onChange={(e) => handleSelect(e, setFieldValue)}
                        value={address.address_id}
                      />
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                      <strong>Billing Address</strong>
                      <div>
                        {address.first_name +
                          " " +
                          address.last_name +
                          " ," +
                          " " +
                          formatPhoneNumber(address.phone_number)}
                      </div>
                      <div>
                        {address.street +
                          "," +
                          address.city +
                          "," +
                          address.state +
                          "," +
                          address.country +
                          "," +
                          address.zip_code}
                      </div>
                    </div>
                    <div>
                      <span
                        onClick={() => setOpenUpdateBillingAddressModal(true)}
                      >
                        <i
                          className="fa fa-pencil"
                          style={{
                            width: 35,
                            fontSize: 20,
                            paddingLeft: "15px",
                            color: "#999",
                          }}
                        ></i>
                      </span>
                    </div>
                  </div>
                );
              }

              if (address.address_type === "SHIPPING_ADDRESS") {
                return (
                  <div className="addressSelect">
                    <div>
                      <Field
                        type="radio"
                        name="address_select"
                        onChange={(e) => handleSelect(e, setFieldValue)}
                        value={address.address_id}
                      />
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                      <strong>Shipping Address</strong>
                      <div>
                        {address.first_name +
                          " " +
                          address.last_name +
                          ", " +
                          " " +
                          formatPhoneNumber(address.phone_number)}
                      </div>
                      <div>
                        {address.street +
                          "," +
                          address.city +
                          "," +
                          address.state +
                          "," +
                          address.country +
                          "," +
                          address.zip_code}
                      </div>
                    </div>
                    <div>
                      <span
                        onClick={() => setOpenUpdateShippingAddressModal(true)}
                      >
                        <i
                          className="fa fa-pencil"
                          style={{
                            width: 35,
                            fontSize: 20,
                            paddingLeft: "15px",
                            color: "#999",
                          }}
                        ></i>
                      </span>
                    </div>
                  </div>
                );
              }
            })}
          </Form>
        )}
      </Formik>
      {console.log("userInfo.addressInfo.lenght", userInfo.addressInfo.lenght)}
      {userInfo.addressInfo.length === 1 && (
        <Button
        className="btn btn-solid"
          onClick={() => {
            setOpenAddShippingAddressModal(true);
          }}
         
        >
          add shipping address
        </Button>
      )}
      {openUpdateBillingAddressModal && (
        <UpdateAddress
          openmodal={openUpdateBillingAddressModal}
          initialValues={userInfo}
          UpdateBillingAddress={UpdateBillingAddress}
          colsedModal={() => setOpenUpdateBillingAddressModal(false)}
          isLoadingButton={isLoadingButton}
        />
      )}
      {openAddShippingAddressModal && (
        <AddShippingAddress
          openmodal={openAddShippingAddressModal}
          initialValues={shippingAddressInitialValues}
          handleAddShippingAddress={handleAddShippingAddress}
          colsedModal={() => setOpenAddShippingAddressModal(false)}
          isLoadingButton={isLoadingButton}
        />
      )}
      {openUpdateShippingAddressModal&&
      <UpdateShipingAddress
        openmodal={openUpdateShippingAddressModal}
        initialValues={shippingUpadteIntialValue}
        UpdateShippingAddress={UpdateShippingAddress}
        colsedModal={() => setOpenUpdateShippingAddressModal(false)}
        isLoadingButton={isLoadingButton}
      />
}
    </div>
  );
};

export default SelectAddress;
/* Copyright Information: Created by BuyGroup */