/* Copyright Information: Created by BuyGroup */
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import catgoryReducer from "../reducers/catgoryReducer";
import cartReducer from "../reducers/cartReducer";
import cardReducer from "../reducers/cardReducer";
import savePriceReducer from "../reducers/savePriceReducer";

const initialState = {};
const store = createStore(
  combineReducers({
    category: catgoryReducer,
    cart: cartReducer,
    card: cardReducer,
    savePrice:savePriceReducer,
  }),
  initialState,
  applyMiddleware(thunk)
);
export default store;
/* Copyright Information: Created by BuyGroup */