/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { updateCustomer } from "../services/AccountServices";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationUpdateSchema } from "./CustomerValidationSchema";
import { City } from "country-state-city";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import Select from "react-select";
import PhoneInput from "react-phone-number-input/input";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, FormGroup, Label, ModalBody, ModalFooter, Col } from "reactstrap";
import { FormLabel } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const UpdateCustomer = (props) => {
  const { initialValues } = props;
  console.log("initialValuesprops", initialValues);
  const history = useHistory();
  let location = useLocation();

  // state variable in upload document File
  const [taxDocumentFile, setTaxDocumentFile] = useState(
    initialValues.tax_exempt_doc
  );
  const [documentUrl, setDocumentUrl] = useState(null);
  const [isDisabledDocument, setIsDisabledDocument] = useState(true);

  // state variable in files
  const [otherFiles, setOtherFiles] = useState(initialValues.other_docs);
  const [newFiles, setNewFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);

  // state variable in copy of files Data
  const [filesData, setFilesData] = useState([]);
  // /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState(initialValues.phone_number);

  /* const "genderValue" store  selected gender Value   */
  const [genderValue, setGenderValue] = useState();

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  const [isDisableUpdate, setIsDisableUpdate] = useState(false);

  const genderOption = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  useEffect(() => {
    getCountryList();
    getStateList();
    getCityList();
  }, []);

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /* This function will  set all state for default country  */

  const getStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.country) {
        return country;
      }
    });
    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;
    setCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.country,
    });
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.country) {
        return country;
      }
    });
    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;
    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });
    const defaultstateIsoCode = getdefaultState[0]?.isoCode;
    setStatesValues({ vlaue: defaultstateIsoCode, label: initialValues.state });
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode,
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("country", selectedCountry.label);
    setFieldTouched("country", false);
    setFieldValue("state", "");
    setFieldValue("city", "");

    const countryisoCode = selectedCountry.value;

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode == countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("state", selectedState.label);
    setFieldValue("city", "");
    setFieldTouched("state", false);

    const stateisoCode = selectedState.value;

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("city", selectCity.label);
    setFieldTouched("city", false);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   */

  const handlePhoneNumber = (selectphone, setFieldValue, setFieldTouched) => {
    setPhoneValue(selectphone);
    setFieldValue("phone_number", selectphone);
  };

  /** / This function handle gender value
   * @param  {genderValue} genderValue string value
   */

  const handlegender = (genderValue, setFieldValue) => {
    setGenderValue(genderValue);
    setFieldValue("gender", genderValue.label);
  };

  // Upload pdf file than convert to base 64 and store the data in the state
  const readPDF = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);
      let valid = false;
      var sCurExtension = getFiles[0];

      const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

      if ((checkFileTyype == ".pdf", ".doc", ".docx")) {
        valid = true;
      } else {
        valid = false;
      }

      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  doc_filename: file.name,
                  newIndex: uuidGenrate(index),
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (pdf) => {
            /* Once all promises are resolved, update state with PDF URI array */
            console.log("pdf", pdf);
            setTaxDocumentFile(pdf);
            setFieldValue("new_tax_exempt_doc", pdf);
            e.target.value = "";
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };

  // Upload multiple image than convert to base 64 and store the data in the state
  const readURI = (e, setFieldValue) => {
    // setUploadButton(true);

    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      let valid = false;
      for (var j = 0; j < getFiles.length; j++) {
        var sCurExtension = getFiles[j];

        const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

        if ((checkFileTyype == ".pdf", ".doc", ".docx")) {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  doc_filename: file.name,
                  newIndex: uuidGenrate(index),
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (images) => {
            /* Once all promises are resolved, update state with image URI array */
            console.log("images", images);
            const mergeFiles = otherFiles.concat(images);
            console.log("mergeFiles", mergeFiles);

            setOtherFiles(mergeFiles);
            setNewFiles(images);
            setFieldValue("other_docs", mergeFiles);
            setFieldValue("new_other_docs", images);
            // setUploadButton(false);
            e.target.value = "";
          },
          (error) => {
            console.error(error);
          }
        );
      }
      return false;
    } else {
      console.log("Hi Else");
    }
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    console.log("base64", blob);
    return blob;
  };

  /* This Function update customer detail */
  const onSubmit = (customerData) => {
    setIsDisableUpdate(true);
    const userlocalStorageData = localStorage.getItem("user");
    const userData = JSON.parse(userlocalStorageData);

    customerData = {
      address_id: customerData.address_id,
      address_type: customerData.address_type,
      id: customerData.id,
      title: customerData.title,
      company_name: customerData.company_name,
      first_name: customerData.first_name,
      last_name: customerData.last_name,
      phone_number: customerData.phone_number,
      country: customerData.country,
      state: customerData.state,
      city: customerData.city,
      street: customerData.street,
      zip_code: customerData.zip_code,
      other_docs: customerData.other_docs ? customerData.other_docs : [],
      is_tax_exempt: customerData.is_tax_exempt,
      tax_exempt_doc: customerData.tax_exempt_doc
        ? customerData.tax_exempt_doc
        : [],
      new_tax_exempt_doc: customerData.new_tax_exempt_doc || [],
      new_other_docs: customerData.new_other_docs || [],
      delete_tax_doc: customerData.delete_tax_doc || [],
      delete_other_docs: customerData.delete_other_docs || [],
    };
    let uploadNewDocument = customerData.other_docs
      .filter(function (doc) {
        return doc.path !== undefined;
      })
      .map(function (doc) {
        return {
          mime_type: doc.mime_type,
          doc_filename: doc.doc_filename,
        };
      });

    console.log("uploadNewDocument", uploadNewDocument);
    // const joinOtherDoc =
    //   customerData.other_docs &&
    //   customerData.other_docs.map(function (x) {
    //     return {
    //       mime_type: x.mime_type,
    //     };
    //   });
    customerData.new_other_docs = uploadNewDocument;
    // customerData.other_docs = joinOtherDoc;

    const uploadDocumentArray =
      customerData.new_tax_exempt_doc &&
      customerData.new_tax_exempt_doc.map(function (x) {
        return {
          mime_type: x.mime_type,
        };
      });
    // customerData.tax_exempt_doc = uploadDocumentArray;
    console.log("customerData", customerData);
    if (customerData.title === "") {
      delete customerData.title;
    }
    if (customerData.company_name == "") {
      delete customerData.company_name;
    }
    updateCustomer(customerData)
      .then((res) => {
        if (res.status === 200) {
          setIsDisableUpdate(false);
          userData.first_name = customerData.first_name;
          userData.company_name = customerData.company_name;
          userData.title = customerData.title;
          userData.phone_number = customerData.phone_number;
          userData.zip_code = customerData.zip_code;
          userData.name =
            customerData.first_name + " " + customerData.last_name;
          localStorage.setItem("user", JSON.stringify(userData));

          console.log("response", res.data);
          const getLenght = res.data.taxDoc ? res.data.taxDoc.length : 0;
          console.log("getLenght", getLenght);
          if (getLenght == 0) {
            console.log("if");
            toast.success(res?.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
          } else {
            console.log("else");
            res.data.taxDoc.map((url, index) => {
              const getData = taxDocumentFile[index];

              const contentType = getData.mime_type;
              const b64Data = getData.path;
              const replaceDataImage = b64Data.replace(
                /data:application.*;base64,/,
                ""
              );
              console.log("getData", getData);
              console.log("url", url);
              console.log("replaceDataImage1", replaceDataImage);
              const blob = b64toBlob(replaceDataImage, contentType);
              console.log("blob", blob);

              fetch(url.uploadURL, {
                method: "PUT",
                body: blob,
              })
                .then(function () {
                  if (getLenght === index + 1) {
                    toast.success(
                      "File uploaded and Customer information updated successfully",
                      {
                        autoClose: 3000,
                        hideProgressBar: true,
                      }
                    );
                  }
                })
                .catch(function (error) {
                  console.log("error", error);
                });
            });
          }
          const otherDocs = res.data.other_docs_uploadUrls
            ? res.data.other_docs_uploadUrls.length
            : 0;
          if (otherDocs != 0) {
            res.data.other_docs_uploadUrls.map((url, index) => {
              const getData = newFiles[index];

              const contentType = getData.mime_type;
              const b64Data = getData.path;
              const replaceDataImage = b64Data.replace(
                /^data:application\/[a-z]+;base64,/,
                ""
              );
              console.log("getData1", getData);
              console.log("url1", url);
              console.log("replaceDataImage2", replaceDataImage);
              const blob = b64toBlob(replaceDataImage, contentType);
              console.log("blob1", blob);
              fetch(url.uploadURL, {
                method: "PUT",
                body: blob,
              })
                .then(function () {
                  console.log("Document upload done");
                })
                .catch(function (error) {
                  console.log("error", error);
                });
            });
          }
          location.pathname === "/dashboard"
            ? history.push("/")
            : history.push("/checkout");
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setIsDisableUpdate(false);
        toast.error(error?.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  const uuidGenrate = (val) => {
    let number = Math.floor(100000000 + Math.random() * 900000000);
    return Math.floor(100000000 + Math.random(val) * 900000000);
  };

  // Delete Images after uploading
  const handleDeleteTaxExempt = (e, setFieldValue, taxDocumentFile) => {
    setFieldValue("delete_tax_doc", taxDocumentFile);
    setFieldValue("new_tax_exempt_doc", []);
    setFieldValue("tax_exempt_doc", []);
    setTaxDocumentFile([]);
    // setDocumentUrl("");
    // setIsDisabledDocument(false);
  };

  //  Delete Images after uploading
  const handleDeleteOtherDoc = (e, index, new_index, setFieldValue) => {
    const copyFiles = [...otherFiles];
    console.log("copyFiles", copyFiles);
    const getObject = copyFiles[index];
    // check key is exist or not
    if (getObject.doc_id !== undefined) {
      const deletedIds = {
        doc_id: getObject.doc_id,
      };
      let prepareDataDeleteFiles = deleteFiles.concat(deletedIds);
      setDeleteFiles(prepareDataDeleteFiles);
      console.log("prepareDataDeleteFiles", prepareDataDeleteFiles);
      setFieldValue("delete_other_docs", prepareDataDeleteFiles);
    } else {
      if (getObject.newIndex != undefined) {
        let filterData = newFiles.filter((x) => x.newIndex != new_index);

        setNewFiles(filterData);
        setFieldValue("new_other_docs", filterData);
      }
    }
    copyFiles.splice(index, 1);
    setOtherFiles(copyFiles);
    setFieldValue("other_docs", copyFiles);
  };

  return (
    <div className="profile_update_form_vs">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationUpdateSchema}
        enableReinitialize
      >
        {({
          setFieldValue,
          handleBlur,
          setFieldTouched,
          values,
          errors,
          touched,
          dirty,
        }) => (
          <>
            {/* {console.log("Values handle Change", values)} */}
            {console.log("errors handle Change", errors)}
            <Form>
              <ModalBody className="row">
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">First Name</Label>
                    <Field
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                    />
                    <ErrorMessage name="first_name">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">Last Name</Label>
                    <Field
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="last_name"
                    />
                    <ErrorMessage name="last_name">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">Company Name</Label>
                    <Field
                      type="text"
                      className="form-control"
                      id="company_name"
                      name="company_name"
                    />
                    {/* <ErrorMessage name="company_name">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage> */}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">Title</Label>
                    <Field
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                    />
                    {/* <ErrorMessage name="title">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage> */}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Phone Number
                    </Label>

                    <PhoneInput
                      country="US"
                      value={phoneValue}
                      onBlur={handleBlur}
                      id="phone_number"
                      maxLength={14}
                      name="phone_number"
                      className="form-control"
                      onChange={(value) =>
                        handlePhoneNumber(value, setFieldValue, setFieldTouched)
                      }
                    />
                    {errors ? (
                      <label className="err-msg">{errors.phone_number}</label>
                    ) : null}
                    {/* <ErrorMessage name="phone_number">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage> */}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">Email</Label>

                    <Field
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      disabled
                    />

                    <ErrorMessage name="email">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Country
                    </Label>

                    <Select
                      value={countriesValue}
                      noOptionsMessage={() => "No country found"}
                      defaultValue={{ value: "", label: initialValues.country }}
                      onChange={(value) =>
                        handleCountry(value, setFieldValue, setFieldTouched)
                      }
                      options={countries}
                      name="country"
                      placeholder="Select Country"
                    />

                    <ErrorMessage name="country">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      State
                    </Label>

                    <Select
                      value={statesValues}
                      noOptionsMessage={() =>
                        noDataStateMessage
                          ? "No State Found"
                          : "First select country"
                      }
                      defaultValue={{ value: "", label: initialValues.state }}
                      onChange={(value) =>
                        handleState(value, setFieldValue, setFieldTouched)
                      }
                      options={filterStates}
                      name="state"
                      placeholder="Select State"
                    />

                    <ErrorMessage name="state">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      City
                    </Label>

                    <Select
                      value={cityValues}
                      defaultValue={{ value: "", label: initialValues.city }}
                      noOptionsMessage={() =>
                        noDataCityMessage
                          ? "No City Found"
                          : "First select state"
                      }
                      onChange={(value) =>
                        handleCity(value, setFieldValue, setFieldTouched)
                      }
                      options={filterCity}
                      name="city"
                      placeholder="Select City"
                    />

                    <ErrorMessage name="city">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">Street</Label>

                    <Field
                      type="text"
                      className="form-control"
                      id="street"
                      name="street"
                    />

                    <ErrorMessage name="street">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">Zip Code</Label>
                    <Field
                      type="text"
                      id="zip_code"
                      className="form-control"
                      name="zip_code"
                    />
                    <ErrorMessage name="zip_code">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </Col>
                <Col md="6">
                  {initialValues.customer_type == "Business" ? (
                    <Col md="12">
                      <FormGroup>
                        <Label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Tax Exempt?
                        </Label>
                        <div role="group" aria-labelledby="checkbox-group">
                          <FormLabel className="mr-5">
                            <Field
                              type="checkbox"
                              disabled={taxDocumentFile[0]?.is_verified}
                              name="is_tax_exempt"
                            />
                            Tax Exempt
                          </FormLabel>
                        </div>
                      </FormGroup>
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>
                {initialValues.customer_type == "Business" &&
                values.is_tax_exempt ? (
                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group mb-3">
                        <Label className="col-form-label">
                          Upload Tax Document / Certificate
                        </Label>
                        <>
                          <input
                            type="file"
                            className="show-for-sr"
                            name="new_tax_exempt_doc[]"
                            accept="application/pdf,.doc,.docx"
                            onChange={(e) => readPDF(e, setFieldValue)}
                            id="Taxfile"
                            disabled={taxDocumentFile[0]?.is_verified}
                          />
                          <label htmlFor="Taxfile">
                            <i className="fa fa-plus"></i> Upload Document
                          </label>
                          {values.expiration_message ? (
                            <p className="exp_msg">
                              {values.expiration_message}
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                        <ErrorMessage name="new_tax_exempt_doc">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                        {taxDocumentFile.length > 0 ? (
                          <div className="d-flex">
                            <div className="show_document uploaded-file">
                              {taxDocumentFile.map((item, index) => {
                                return (
                                  <>
                                    <div className="pr-2">
                                      <a href={item.docUrl} target="_blank">
                                        {item.doc_filename}
                                      </a>
                                    </div>
                                    <div className="pl-2">
                                      {values.expiration_message ? (
                                        ""
                                      ) : (
                                        <>
                                          {item.is_verified ? (
                                            <p class="document_status btn btn-sm btn-success rounded">
                                              Approved
                                            </p>
                                          ) : item.is_rejected ? (
                                            <>
                                              <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="tooltip-top">
                                                    {item.reason}
                                                  </Tooltip>
                                                }
                                              >
                                                <p class="document_status btn btn-sm btn-light rounded">
                                                  Rejected
                                                  <i class="fa fa-info-circle ml-2"></i>
                                                </p>
                                              </OverlayTrigger>
                                            </>
                                          ) : (
                                            <p class="document_status btn btn-sm btn-warning rounded">
                                              Pending
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    {!item.is_verified ? (
                                      <span
                                        className="delete_tax_document"
                                        onClick={(e) =>
                                          handleDeleteTaxExempt(
                                            e,
                                            setFieldValue,
                                            taxDocumentFile
                                          )
                                        }
                                      >
                                        <i className="fa fa-close"></i>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}

                        <input type="hidden" name="delete_tax_doc" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group mb-3">
                        <Label className="col-form-label">
                          Upload Other Document
                        </Label>
                        <>
                          <input
                            className="show-for-sr"
                            type="file"
                            name="other_docs[]"
                            multiple
                            accept="application/pdf,.doc,.docx"
                            onChange={(e) => readURI(e, setFieldValue)}
                            id="file"
                          />
                          <label htmlFor="file">
                            <i className="fa fa-plus"></i> Upload Other Document
                          </label>
                        </>
                        {otherFiles.length > 0 ? (
                          <div className="col-xl-12 col-sm-12">
                            {otherFiles.map((item, index) => {
                              return (
                                <>
                                  <div className="show_document uploaded-file">
                                    <div className="pr-2">
                                      <a href={item.docUrl} target="_blank">
                                        {item.doc_filename}
                                      </a>
                                    </div>
                                    <div className="pl-2">
                                      {item.is_verified ? (
                                        <p class="document_status btn btn-sm btn-success rounded">
                                          Approved
                                        </p>
                                      ) : item.is_rejected ? (
                                        <>
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip id="tooltip-top">
                                                {item.reason}
                                              </Tooltip>
                                            }
                                          >
                                            <p class="document_status btn btn-sm btn-light rounded">
                                              Rejected
                                              <i class="fa fa-info-circle ml-2"></i>
                                            </p>
                                          </OverlayTrigger>
                                        </>
                                      ) : (
                                        <p class="document_status btn btn-sm btn-warning rounded">
                                          Pending
                                        </p>
                                      )}
                                    </div>
                                    {!item.is_verified ? (
                                      <span
                                        onClick={(e) =>
                                          handleDeleteOtherDoc(
                                            e,
                                            index,
                                            item.newIndex,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                        <input
                          className="hidden"
                          type="hidden"
                          name="new_other_docs"
                        />
                        <input
                          className="hidden"
                          type="hidden"
                          name="delete_other_docs"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-solid "
                  type="submit"
                  disabled={isDisableUpdate || !dirty}
                >
                  {isDisableUpdate ? "Please Wait" : "Update"}
                </button>
              </ModalFooter>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default UpdateCustomer;
/* Copyright Information: Created by BuyGroup */
