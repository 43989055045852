/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import ShippingAdress from "./ShippingAdress";
import { AllUserAddress } from "../../services/AuthServices";
const UserAddress = () => {
  const userInitialValues = {
    address_type: "SHIPPING_ADDRESS",
    first_name: "",
    last_name: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    street: "",
    zip_code: "",
  };

  const [isLoading, setLoading] = useState(true);

  /* const shippingAddress store shipping address */
  const [shippingAddress, setShippingAddress] = useState(userInitialValues);

  useEffect(() => {
    GetAllUserAddress();
  }, []);

  const GetAllUserAddress = () => {
    const token = localStorage.getItem("token");
    AllUserAddress()
      .then((response) => {
        response.data.map((UserData) => {
          if (UserData?.address_type === "SHIPPING_ADDRESS") {
            setShippingAddress(UserData);
          }
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("errorgrt", error);
      });
  };

  return (
    <div>
      {isLoading ? (
        <div className="custom-loader">
          <Spinner animation="border" />
        </div>
      ) : (
        <ShippingAdress initialValues={shippingAddress} />
      )}
    </div>
  );
};

export default UserAddress;
/* Copyright Information: Created by BuyGroup */
