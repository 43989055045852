/* Copyright Information: Created by BuyGroup */
import {
  FETCH_DATA_SUCCESS_CART,
  REMOVE_CART,
  ADD_CART,
  EDIT_CART,
} from "./actions";
import {
  cartList,
  removeCart,
  addCartList,
  updateCartList,
} from "../services/CartServices";
import { toast } from "react-toastify";

export const fetchCartSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS_CART,
  cart: data,
});

/* This function call cartList Api */
export const getCart = (callBack, selectAddressId) => {
  return (dispatch) => {
    cartList(selectAddressId)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCartSuccess(res.data));
          callBack(res.message);
        }
      })
      .catch((error) => {
        toast.error(error.data, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };
};

export const removeCartSuccess = (id) => ({
  type: REMOVE_CART,
  id: id,
});

/* This function call delete cart Api */
export const deleteCart = (id, callBack) => {
  return (dispatch) => {
    removeCart(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(removeCartSuccess(id));

          callBack();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
};

// Add Cart
export const addCartSuccess = (data, group_id) => ({
  type: ADD_CART,
  data: data,
  groupId: group_id,
});

/* This function call add cart Api */
export const AddCart = (data, group_id, callback) => {
  return (dispatch) => {
    addCartList(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(addCartSuccess(res.data[0], group_id));
          callback(res.message, "SUCCESS");
        }
      })
      .catch((error) => {
        callback(error.data.message, "ERROR");
        console.log("error", error);
      });
  };
};

// Edit cart
export const editCartSuccess = (data, id) => ({
  type: EDIT_CART,
  data: data,
  id: id,
});

/* This function call edit cart Api */
export const editCart = (id, data, callback, e) => {
  return (dispatch) => {
    updateCartList(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(editCartSuccess(res.data, id));

          callback(e, res);
        }
      })
      .catch((error) => {
        console.log("error", error);
        callback(e, error);
      });
  };
};
/* Copyright Information: Created by BuyGroup */
