/* Copyright Information: Created by BuyGroup */
import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import { AddCart, getCart } from "../../actions/cart";
import { useHistory } from "react-router-dom";
import { isLoggedIn } from "../../utils/index";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const Product = (props) => {
  /* const "lodingAddCart" check user is login or not */
  const [lodingAddCart, setLoadingAddCart] = useState(false);

  const history = useHistory();

  /* add Quantity to card of user */
  const addedCart = (e, group_id, callBack) => {
    e.preventDefault();
    if (isLoggedIn()) {
      setLoadingAddCart(true);
      const data = {
        qty: 1,
        group_id: group_id,
      };

      // return;
      props.AddCart(data, group_id, addedCartCallBack);
    } else {
      history.push("/login");
    }
  };

  const addedCartCallBack = (message, type) => {
    if (type === "ERROR") {
      toast.error(message, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: true,
      });
      props.getCart(updateCartCountCallBack);
    }
  };

  const updateCartCountCallBack = () => {
    setLoadingAddCart(false);
  };

  const truncateString = (string, limit) => {
    if (string?.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  return (
    <>
      <div className="col-xl-3 col-6 col-grid-box">
        <Link
          to={{
            pathname: "/product/" + props.group.group.id,
          }}
        >
          <div className="product">
            <div>
              <div className="product-box product-wrap">
                <div className="img-wrapper">
                  <div className="lable-block">
                    <div className="front">
                      <img
                        className="img-responsive"
                        src={props.group.path}
                        alt="not found"
                      />
                    </div>
                  </div>
                  <div className="cart-info cart-wrap">
                    <button
                      title="Add to cart"
                      className="addtocart_button"
                      onClick={(e) => addedCart(e, props.group.group.id)}
                      disabled={lodingAddCart}
                    >
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                      {lodingAddCart ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        "Add to Cart"
                      )}
                    </button>
                  </div>
                </div>

                <div className="product-detail">
                  <div>
                    <div className="rating"></div>
                    <h6 title={props.group.group.product_name}>
                      {truncateString(props.group.group.product_name, 40)}
                    </h6>

                    {props.group.group.price ==
                    props.group.group.discounted_price ? (
                      <h4>
                        $
                        {parseFloat(props.group.group.price).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </h4>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <h4>
                          $
                          {parseFloat(
                            props.group.group.discounted_price
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h4>
                        <h4
                          style={{
                            textDecorationLine: "line-through",
                            fontWeight: "400",
                            color: "#777777",
                          }}
                        >
                          $
                          {parseFloat(props.group.group.price).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  AddCart,
  getCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
/* Copyright Information: Created by BuyGroup */
