/* Copyright Information: Created by BuyGroup */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { forgotPassword } from "../../services/AuthServices";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Button, FormGroup, Label, Row, Col, Container } from "reactstrap";
const ForgotPassword = () => {
  /* schema  for forgotPassword */
  const forgotSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter email address"),
  });

  //This const "isLoading" is used for enable and disable the button of login
  const [isLoading, setLoading] = useState(false);

  /**  This function will call on forgot passowrd button click
   *@param {userData} userData
   */
  const onSubmit = (userData, onSubmitProps) => {
    setLoading(true);
    forgotPassword(userData)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
        onSubmitProps.resetForm();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        onSubmitProps.resetForm();
      });
  };
  return (
    <div>
      <div className="container-fluid">
        <section className="login-page section-b-space">
          <Container>
            <Row>
              <Col lg="12">
              <div className="text-center" style={{marginBottom:"25px"}}>
              <img src={logo} alt="" className="img-fluid" />
            </div>
                <h3 className="text-center mb-3">Forgot Password</h3>
                <p className="text-center lead_login_txt">
                  Enter your email and we'll send you a link to reset your
                  password.
                </p>

                <div className="theme-card">
                  <Formik
                    initialValues={{ email: "" }}
                    onSubmit={(values, onSubmitProps) => {
                      onSubmit(values, onSubmitProps);
                    }}
                    validationSchema={forgotSchema}
                  >
                    {({}) => (
                      <Form className="form-horizontal auth-form">
                        <FormGroup>
                          <Label
                            htmlFor="recipient-name"
                            className="col-form-label"
                          >
                            Email
                          </Label>

                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                          />

                          <ErrorMessage name="email">
                            {(errorMsg) => (
                              <label className="err-msg">{errorMsg}</label>
                            )}
                          </ErrorMessage>
                        </FormGroup>
                        <Row className="align-items-center mt-4 my_account_footer">
                          <Col md="6">
                            <Button
                              type="submit"
                              classname="btn btn-primary"
                              color="primary"
                              disabled={isLoading}
                            >
                              {isLoading ? "Loading.." : "Submit"}
                            </Button>
                          </Col>
                          <Col md="6" className="forgot-link">
                            <Link to="/login">Back to Login</Link>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ForgotPassword;
/* Copyright Information: Created by BuyGroup */
