/* Copyright Information: Created by BuyGroup */
import React, { Fragment } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import visa from "../../../public/assets/images/icon/visa.png";
import mastercard from "../../../public/assets/images/icon/mastercard.png";
import paypal from "../../../public/assets/images/icon/paypal.png";
import americanexpress from "../../../public/assets/images/icon/american-express.png";
import discover from "../../../public/assets/images/icon/discover.png";

const CopyRight = ({ layout, fluid }) => {
  const d = new Date();
  const n = d.getFullYear();
  return (
    <Fragment>
      <div className={`sub-footer ${layout}`}>
        <Container fluid={fluid}>
          <Row>
            <Col xl="12" md="12" sm="12">
              <div className="footer-end text-center">
                <p>
                  <i className="fa fa-copyright" aria-hidden="true"></i>&nbsp;
                  Copyright {n} BuyGroup All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default CopyRight;
/* Copyright Information: Created by BuyGroup */
