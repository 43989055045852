/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const login = (user) => {
  return Api.post("user/login", user).then((res) => res.data);
};

export const userRegistration = (userdata) => {
  return Api.post("customer_signup", userdata).then((res) => res.data);
};
export const forgotPassword = (userData) => {
  return Api.post(
    "/user/forgotPassword?requested_from=customer",
    userData
  ).then((res) => res.data);
};

export const resetPassword = (userData) => {
  return Api.put("user/resetPassword", userData).then((res) => res.data);
};

export const tokenExpire = (token) => {
  return Api.post("user/check/token-expire", token)
    .then((res) => res.data)
    .catch((err) => console.log("error in token Expire ", err));
};

export const VerifyUserAccount = (userData) => {
  return Api.put("user/verify", userData).then((res) => res.data);
};

export const AddUserAddress = (NewUserDate) => {
  return Api.post("address", NewUserDate).then((res) => res.data);
};
export const UpdateUserAddress = (NewUserDate) => {
  return Api.put("address", NewUserDate).then((res) => res.data);
};

export const AllUserAddress = () => {
  return Api.get("address").then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
