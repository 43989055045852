/* Copyright Information: Created by BuyGroup */
import {
  FETCH_DATA_SUCCESS_CART,
  REMOVE_CART,
  ADD_CART,
  EDIT_CART,
} from "../actions/actions";

const initialState = {
  cart: {},
};

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA_SUCCESS_CART:
      return {
        cart: action.cart,
      };
    case REMOVE_CART:

    case ADD_CART:
      return {
        cart: state.cart,
      };

    case EDIT_CART:
      return {
        cart: action.data,
      };

    default:
      return state;
  }
}

export default cartReducer;
/* Copyright Information: Created by BuyGroup */
