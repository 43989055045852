/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationAddSchema } from "./validationAddressSchema";
import { City } from "country-state-city";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import CountryListJson from "../../Json/CountryListJson";
import StateListJson from "../../Json/StateListJson";
import { AddUserAddress, UpdateUserAddress } from "../../services/AuthServices";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Container,
  ModalFooter,
  ModalBody,
} from "reactstrap";
const ShippingAdress = (props) => {
  const history = useHistory();

  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "userInitialValues" store user initial value */
  const [userInitialValues, setUserInitialValues] = useState(
    props.initialValues
  );

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState(
    props.initialValues.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  useEffect(() => {
    getCountryList();
    if (props.initialValues?.id) {
      getStateList();
      getCityList();
    }
  }, []);

  const getStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === props.initialValues.country) {
        return country;
      }
    });
    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;
    setCountriesValue({
      value: defaultCountryIsoCode,
      label: props.initialValues.country,
    });
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === props.initialValues.country) {
        return country;
      }
    });
    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;
    const getdefaultState = allStateList.filter((state) => {
      if (
        props.initialValues.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });
    const defaultstateIsoCode = getdefaultState[0].isoCode;
    setStatesValues({
      vlaue: defaultstateIsoCode,
      label: props.initialValues.state,
    });
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode,
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
  };

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("country", selectedCountry.label);
    setFieldTouched("country", false);
    setFieldValue("state", "");
    setFieldValue("city", "");

    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("state", selectedState.label);
    setFieldValue("city", "");
    setFieldTouched("state", false);

    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("city", selectCity.label);
    setFieldTouched("city", false);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   */
  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("phone_number", selectphone);
  };

  /**
   * This function send New Suppliers data
   * @param  {customerData} customerData data Object
   */

  const onSubmit = (customerData, onSubmitProps) => {
    setIsLoadingButton(true);

    const preapreCustomerData = {
      address_type: customerData.address_type,
      first_name: customerData.first_name,
      last_name: customerData.last_name,
      phone_number: customerData.phone_number,
      street: customerData.street,
      city: customerData.city,
      state: customerData.state,
      country: customerData.country,
      zip_code: customerData.zip_code,
    };

    if (customerData?.id) {
      // preapreCustomerData.id=customerData.id

      let UpdatedCustomerData = JSON.parse(JSON.stringify(preapreCustomerData));
      UpdatedCustomerData.address_id = customerData.id;

      UpdateUserAddress(UpdatedCustomerData)
        .then((response) => {
          if (response.status === 200) {
            setIsLoadingButton(false);
            console.log("shipping response", response);
            localStorage.setItem(
              "shipping_zip_code",
              response.data[0]?.zip_code
            );
            toast.success(response.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
          history.push("/");
          setUserInitialValues("");
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoadingButton(false);
          toast.error(error.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });

          onSubmitProps.resetForm();
          setUserInitialValues(props.initialValues);
          setPhoneValue("");
          setCountriesValue("");
          setStatesValues("");
          setCityValues("");
        });
    } else {
      AddUserAddress(preapreCustomerData)
        .then((response) => {
          if (response.status === 200) {
            setIsLoadingButton(false);

            toast.success(response.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
          history.push("/");
          setUserInitialValues("");
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoadingButton(false);
          toast.error(error.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          onSubmitProps.resetForm();
          setUserInitialValues(props.initialValues);
          setPhoneValue("");
          setCountriesValue("");
          setStatesValues("");
          setCityValues("");
        });
    }
  };

  return (
    <Row>
      <Col lg="12">
        <div className="profile_update_form_vs">
          <Formik
            initialValues={userInitialValues}
            onSubmit={(values, onSubmitProps) => {
              onSubmit(values, onSubmitProps);
            }}
            validationSchema={validationAddSchema}
          >
            {({ setFieldValue, handleBlur, setFieldTouched, errors }) => (
              <Form className="theme-form">
                <ModalBody className="row">
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        First Name
                      </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="first_name"
                      />
                      <ErrorMessage name="first_name">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Last Name
                      </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="last_name"
                      />
                      <ErrorMessage name="last_name">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Phone Number
                      </Label>

                      <PhoneInput
                        defaultCountry="US"
                        international={false}
                        value={phoneValue}
                        onBlur={handleBlur}
                        maxLength={14}
                        name="phone_number"
                        className="form-control"
                        onChange={(value) =>
                          handlePhoneNumber(value, setFieldValue)
                        }
                      />

                      <ErrorMessage name="phone_number">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Country
                      </Label>

                      <Select
                        value={countriesValue}
                        noOptionsMessage={() => "No country found"}
                        onChange={(value) =>
                          handleCountry(value, setFieldValue, setFieldTouched)
                        }
                        options={countries}
                        name="country"
                        placeholder="Select Country"
                      />

                      <ErrorMessage name="country">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        State
                      </Label>

                      <Select
                        value={statesValues}
                        noOptionsMessage={() =>
                          noDataStateMessage
                            ? "No State Found"
                            : "First select country"
                        }
                        onChange={(value) =>
                          handleState(value, setFieldValue, setFieldTouched)
                        }
                        options={filterStates}
                        name="state"
                        placeholder="Select State"
                      />

                      <ErrorMessage name="state">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        City
                      </Label>

                      <Select
                        value={cityValues}
                        defaultValue={{
                          value: "",
                          label: props.initialValues.city,
                        }}
                        noOptionsMessage={() =>
                          noDataCityMessage
                            ? "No City Found"
                            : "First select state"
                        }
                        onChange={(value) =>
                          handleCity(value, setFieldValue, setFieldTouched)
                        }
                        options={filterCity}
                        name="city"
                        placeholder="Select City"
                      />

                      <ErrorMessage name="city">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Street
                      </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="street"
                      />

                      <ErrorMessage name="street">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Zip Code
                      </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="zip_code"
                      />
                      <ErrorMessage name="zip_code">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn btn-solid"
                    color="primary"
                    type="submit"
                    disabled={isLoadingButton}
                  >
                    {isLoadingButton
                      ? "Please Wait..."
                      : props.initialValues?.id
                      ? "Update"
                      : "ADD"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
};
export default ShippingAdress;
/* Copyright Information: Created by BuyGroup */
