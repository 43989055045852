/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import Product from "../product/Product";
import { getGroups } from "../../services/HomeServices";
import Sidebar from "../../sidebar/Sidebar";
import { connect } from "react-redux";
const Category = (props) => {
  // this state is used for loading spinner
  const [isLoading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const params = new URLSearchParams(window.location.search);
  const paramsMin = params.get("min");
  const paramsMax = params.get("max");
  const zipCode = params.get("zipCode") || '';
  useEffect(() => {
    setGroupList([]);
    getGroupList();
  }, [props.match.params.id, paramsMin, paramsMax, props]);
  const getGroupList = () => {
    setLoading(true);
    let filterData = {
      limit: 10,
      offset: offset,
      category: props.match.params.id,
      zip_code: zipCode,
    };
    let categoryData = props.category.find(
      (category) => category.category_id === props.match.params.id
    );
    if (categoryData !== undefined) {
      setCategoryName(categoryData.category_name);
    }
    if (paramsMin !== null) {
      filterData.min = paramsMin;
    }
    if (paramsMax !== null) {
      filterData.max = paramsMax;
    }
    getGroups(filterData)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setCount(response.data.length);
          setGroupList(response.data);
          setOffset(0);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const loadMore = () => {
    setOffset(offset + 1);
    loadGroupList(offset + 1);
  };
  const loadGroupList = (offsetValue) => {
    setShowLoading(true);
    let filterData = {
      limit: 10,
      offset: offsetValue,
      category: props.match.params.id,
      zip_code: zipCode,
    };
    if (paramsMin !== null) {
      filterData.min = paramsMin;
    }
    if (paramsMax !== null) {
      filterData.max = paramsMax;
    }
    getGroups(filterData)
      .then((response) => {
        if (response.status === 200) {
          setShowLoading(false);
          let newGroups = [...groupList];
          Array.prototype.push.apply(newGroups, response.data);
          setCount(response.data.length);
          setGroupList(newGroups);
        }
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col sm="3" className="collection-filter">
          <Sidebar minValue={paramsMin} maxValue={paramsMax} />
        </Col>
        <Col lg="9" sm="12" xs="12" className="product-grid-view">
          <div className="theme-card theme-card-icon">
            <h5 class=""></h5>
            <div className="hidden">
              <i className="fa fa-th grid-layout-view mr-2"></i>
              <i className="fa fa-list-ul list-layout-view"></i>
            </div>
          </div>
          {isLoading ? (
            <div className="custom-loader">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="product_listing">
              {/* <Col md="3">{paramsMin !==null||paramsMax!==null?"Price $ "+paramsMin +" To " +paramsMax:""}</Col>
              <Col md="3">{categoryName!==""&& "Category :"+categoryName}</Col> */}
              <div className="product-wrapper-grid">
                {groupList.length > 0 ? (
                  <>
                    <div className="row">
                      {groupList.map((group, index) => {
                        return <Product group={group} />;
                      })}
                    </div>
                    <div>
                      {count === 10 && (
                        <button
                          onClick={loadMore}
                          type="submit"
                          disabled={showLoading}
                          className="btn btn-solid"
                        >
                          {showLoading ? "loading..." : "Load More"}
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="NotAvailableBox">
                    <p>No Groups Available</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    category: state.category.category,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Category);
/* Copyright Information: Created by BuyGroup */
