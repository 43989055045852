/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect, useRef } from "react";
import { Collapse, Button, Row, Col, Label, Input } from "reactstrap";
import { Field } from "formik";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getCategory } from "../actions/category";
import { savePrice } from "../actions/savePrice";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useHistory } from "react-router";

const CategoryFilter = (props) => {
  const backClick = () => {
    document.getElementById("filter").style.left = "-365px";
  };

  let { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const paramsMin = params.get("min");
  const paramsMax = params.get("max");
  const zipCodeParams = params.get("zipCode") || '';
  let zipCode = zipCodeParams;
  let [zipCodeState, setZipCodeState] = useState(zipCode)
  const [price, setPrice] = useState({ min: paramsMin, max: paramsMax });
  const [category_id, setCategory] = useState(props.category_id);
  const history = useHistory();
  const [isBrandOpen, setIsBrandOpen] = useState(true);
  const toggleBrand = () => setIsBrandOpen(!isBrandOpen);
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let data = { price: props.price, category_id: category_id };


    redirectClear();
  }, [props.minValue, props.maxValue, props.zipCode]);

  const priceChange = (value) => {

    value.min = value.min < 0 ? 0 : value.min;
    setPrice(value);
    let data = { price: value, category_id: category_id };
    props.savePrice(data);
  };

  const redirectClear = () => {
    if (paramsMax === null || paramsMin === null) {
      setPrice({ min: 0, max: null });
      let data = { price: { min: 0, max: null }, category_id: category_id };
      props.savePrice(data);
    }
  };

  const onCompletePrice = (price) => {
    const pathname = window.location.search;
    console.log("pathname :- ", pathname);
    let url;
    if (pathname.includes("zipCode")) {
      url = window.location.pathname + `?zipCode=${zipCode}&min=${price.min}&max=${price.max}`
    } else {
      url = window.location.pathname + `?min=${price.min}&max=${price.max}`
    }
    history.push(url);
  };

  const onCompleteZipCode = (zipCode) => {
    const pathname = window.location.search;

    let url;
    if (pathname.includes("min")) {
      url = window.location.pathname + `?zipCode=${zipCode}&min=${price.min}&max=${price.max}`
    } else {
      url = window.location.pathname + `?zipCode=${zipCode}`
    }
    history.push(url);
  };

  /* This Function will clear the filter */
  const clearFilter = () => {
    setPrice({ min: 0, max: null });
    let data = { price: { min: 0, max: null }, category_id: category_id };
    props.savePrice(data);
    history.push("/");
  };

  const [timeoutId, setTimeoutId] = useState();

  const debounce = () => {
    return (zipCode) => {
      clearTimeout(timeoutId);
      setTimeoutId(setTimeout(() => {
        onCompleteZipCode(zipCode);
      }, 2000))
    };
  };

  const debouncedOnChangeComplete = debounce();

  return (
    <>

      <Row>
        <Col md="6">
          <div className="theme-card">
            <h5 className="">Filter</h5>
          </div>
        </Col>
        <Col md="6">
          <div className="theme-card justify-content-end">
            <Button onClick={clearFilter} type="submit" color="primary">
              Clear
            </Button>
          </div>
        </Col>
      </Row>

      {price.max > 0 && (
        <div className="theme-card">
          {/* <h6 className="">Price: {price.min + " To " + price.max}</h6> */}
        </div>
      )}

      <div className="zip-code-format">
        <Label
          htmlFor="recipient-name"
          className="col-form-label"
        >
          Zip Code
        </Label>
        <Input
          type="number"
          className="form-control zip-code-format"
          name="zipCode"
          placeholder="Search by Zip Code"
          onChange={(event) => {
            if (event.target.value) {
              setZipCodeState(event.target.value)
              debouncedOnChangeComplete(event.target.value)
            } else {
              zipCode = ''
              debouncedOnChangeComplete('')
            }
          }}
        />
      </div>

      <div className="collection-filter-block creative-card creative-inner">
        <div className="collection-mobile-back" onClick={backClick}>
          <span className="filter-back">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            back
          </span>
        </div>
        <div className="collection-collapse-block border-0 open">
          <h3 className="collapse-block-title" onClick={toggleBrand}>
            Category
          </h3>
          <Collapse isOpen={isBrandOpen}>
            <div className="collection-collapse-block-content">
              <div className="collection-brand-filter">
                <ul className="category-list">
                  {props.category.map((category, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/category/${category.category_id}`}
                          //  className={category.category_id===id&&"siderBar-select"}
                          style={{
                            color: category.category_id == id ? "red" : "",
                          }}
                        >
                          {category.category_name}
                        </Link>
                        <ul className="sub-category-list">
                          {category.sub_category.map(
                            (subCategory, subIndex) => {
                              return (
                                <>
                                  <li key={subIndex}>
                                    <Link
                                      to={`/category/${subCategory.category_id}`}
                                      style={{
                                        color:
                                          subCategory.category_id == id
                                            ? "red"
                                            : "",
                                      }}
                                    >
                                      --{subCategory.category_name}
                                    </Link>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="collection-collapse-block border-0 open">
        <h3 className="collapse-block-title">price</h3>
        <div className="collection-collapse-block-content">
          <div className="wrapper mt-3">
            <div className="range-slider">
              <InputRange
                maxValue={5000}
                minValue={0}
                value={price}
                onChange={(value) => priceChange(value)}
                onChangeComplete={() => onCompletePrice(price)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log("state :----", state);
  return {
    category: state.category.category,
    price: state.savePrice.price,
    category_id: state.savePrice.category_id,
  };
};

const mapDispatchToProps = {
  getCategory,
  savePrice,
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilter);
/* Copyright Information: Created by BuyGroup */