/* Copyright Information: Created by BuyGroup */
import { SAVE_PRICE } from "../actions/actions";
const initialState = {
  price: { min: 0, max: null },
  category_id: "",
};

function savePriceReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_PRICE:
      return {
        price: action.price,
        category_id: action.category_id,
      };
    default:
      return state;
  }
}
export default savePriceReducer;
/* Copyright Information: Created by BuyGroup */
