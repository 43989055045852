/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
toast.configure();
export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  render() {
    const { isloading, error } = this.props;
    return (
      <>
        <div className="page-wrapper">
          <div className="authentication-box">
            <Container>
              <Row>
                <Col className="col-md-12 p-0 card-right">
                  <div className="text-center" style={{ marginBottom: "25px" }}>
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <h3 className="text-center">Reset Password</h3>
                  <p className="text-center lead_login_txt">
                    Please enter new password.
                  </p>
                  <Card className="tab2-card">
                    <CardBody>
                      {
                        <Formik
                          initialValues={{
                            new_password: "",
                            confirm_password: "",
                            token: this.props.queryParam,
                          }}
                          onSubmit={(values) => {
                            // return;
                            this.props.onSubmit(values);
                          }}
                          validationSchema={Yup.object().shape({
                            new_password: Yup.string()
                              .required("Password is required")
                              .matches(
                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                              ),
                            confirm_password: Yup.string().when(
                              "new_password",
                              {
                                is: (val) =>
                                  val && val.length > 0 ? true : false,
                                then: Yup.string()
                                  .required("Password is required")
                                  .oneOf(
                                    [Yup.ref("new_password")],
                                    "Password and Confirm Password should be same"
                                  ),
                              }
                            ),
                          })}
                        >
                          {(props) => {
                            const {
                              errors,
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              touched,
                            } = props;
                            return (
                              <form onSubmit={handleSubmit}>
                                {this.props.error !== "" ? (
                                  <p className="alert alert-danger">
                                    {this.props.error}
                                  </p>
                                ) : (
                                  ""
                                )}

                                <div className={`form-group`}>
                                  <label>New Password</label>
                                  <input
                                    type="password"
                                    name="new_password"
                                    onBlur={handleBlur}
                                    className={
                                      errors.new_password &&
                                      touched.new_password
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                    placeholder=""
                                    onChange={handleChange}
                                  />

                                  {errors.new_password &&
                                    touched.new_password && (
                                      <div className="input-feedback error_field">
                                        {errors.new_password}
                                      </div>
                                    )}
                                </div>
                                <div className={`form-group`}>
                                  <label>Confirm New Password</label>
                                  <input
                                    type="password"
                                    name="confirm_password"
                                    onBlur={handleBlur}
                                    className={
                                      errors.confirm_password &&
                                      touched.confirm_password
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                    placeholder=""
                                    onChange={handleChange}
                                  />

                                  {errors.confirm_password &&
                                    touched.confirm_password && (
                                      <div className="input-feedback error_field">
                                        {errors.confirm_password}
                                      </div>
                                    )}
                                </div>

                                <Row className="align-items-center mt-4 my_account_footer">
                                  <Col md="6">
                                    <Button
                                      classname="btn btn-primary"
                                      type="submit"
                                      color="primary"
                                      disabled={isloading}
                                    >
                                      {props.isLoading ? "Loading.." : "Submit"}
                                    </Button>
                                  </Col>
                                  <Col md="6" className="forgot-link">
                                    <Link to="/login">Back to Login</Link>
                                  </Col>
                                </Row>
                              </form>
                            );
                          }}
                        </Formik>
                      }{" "}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
/* Copyright Information: Created by BuyGroup */
