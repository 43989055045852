/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCard, deleteCard, CardAdd } from "../../actions/card";
import { Spinner } from "reactstrap";
import CardDeleteConfirmation from "./CardDeleteConfirmation";
import AddCard from "./AddCard";
import { toast } from "react-toastify";
import EditCard from "./EditCard";
import { updateCard } from "../../services/CardServices";
toast.configure();
const Cards = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  /* const "showModal"  responsible for open and close delete card modal */
  const [showModal, setShowModal] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  /* const " cardId" store card Id */
  const [cardId, setCardId] = useState("");

  /* const "showAddModal" reponsible for open and close add card modal*/
  const [showAddModal, setShowAddModal] = useState(false);

  /* const "initialValues" store initialvalue of card */
  const [initialValues, setInitialValues] = useState({
    card_holder_name: "",
    card_number: "",
    cvv: "",
    default_card: false,
    month: "",
    year: "",
  });
  /* const "showAddModal" reponsible for open and close add card modal*/
  const [openEditCard, setopenEditCard] = useState(false);

  /* const "editCardId" store Id of card that we want to edit */
  const [editCardId, seteditCardId] = useState();

  /* const "defaultCardStatus" store status of card */
  const [defaultCardStatus, setDefaultCardStatus] = useState();

  const [editcardLoading, seteditcardLoading] = useState(false);

  useEffect(() => {
    props.getCard(listCallBack);
  }, []);

  const listCallBack = () => {
    setIsLoading(false);
  };

  /* This Function open delete card modal */
  const modalShow = (e, id) => {
    e.preventDefault();
    setShowModal(true);
    setCardId(id);
  };

  /*This Function close delete card modal */
  const hideModal = () => {
    setShowModal(false);
    setCardId("");
  };

  /* This  Function will delete the card of respective Id */
  const handleDeleteCard = (id) => {
    setSubmitLoading(true);
    props.deleteCard(id, callbackHandleDeleteCard);
  };

  const callbackHandleDeleteCard = () => {
    setShowModal(false);
    setCardId("");
    setSubmitLoading(false);
    toast.success("Successfully removed your card.", {
      autoClose: 3000,
      hideProgressBar: true,
    });
  };
  /* This function will open add card modal */
  const showAddModalFunc = (e) => {
    e.preventDefault();
    setShowAddModal(true);
  };

  /* This Function will add new card */
  const handleSubmitCard = (values, actions) => {
    setSubmitLoading(true);
    const data = {
      card_holder_name: values.card_holder_name,
      card_number: values.card_number,
      expire_date: `${values.month}/${values.year.toString().substr(-2)}`,
      cvv: values.cvv,
      default_card: values.default_card,
    };

    props.CardAdd(data, addCallBack);
  };

  const addCallBack = (message) => {
    if (message.data !== undefined) {
      toast.error(message.data.message, {
        autoClose: 3000,
        hideProgressBar: true,
      });
      setSubmitLoading(false);
      setShowAddModal(false);
    } else {
      setSubmitLoading(false);

      toast.success("Successfully add your card.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  /* This Function will close add card modal */
  const hideAddModal = () => {
    setShowAddModal(false);
  };

  const handleEditCard = (id, cardStatus) => {
    setopenEditCard(true);
    seteditCardId(id);
    setDefaultCardStatus(cardStatus);
  };

  /* This Function Will Update the card */
  const UpdateCard = (value) => {
    seteditcardLoading(true);
    updateCard(value.id)
      .then((response) => {
        seteditcardLoading(false);
        props.getCard(listCallBack);
        setopenEditCard(false);
      })
      .catch((error) => {
        seteditcardLoading(false);
        setopenEditCard(false);
      });
  };
  return (
    <>
      <div className="dashboard_card_listing">
        <div className="tab_header_vs d-flex align-items-center justify-content-between mb-3">
          <h3>Manage Saved Cards</h3>
          <a
            href="#"
            onClick={(e) => showAddModalFunc(e)}
            className="btn btn-solid "
          >
            Add new card
          </a>
        </div>
        <div className="card_box_ul d-flex justify-content-between flex-wrap ">
          {isLoading ? (
            <div className="custom-loader w-100 py-5">
              <Spinner animation="border" />
            </div>
          ) : props.card.length > 0 ? (
            props.card.map((card) => {
              return (
                <>
                  <div
                    className={`card_grid_box ${
                      card.default_card ? "isActive" : ""
                    }`}
                  >
                    <div className="card_box_head">
                      <h5>{card.card_holder_name}</h5>
                      <ul className="d-flex card_action_btn_vs">
                        <li>
                          <a href="#" onClick={(e) => modalShow(e, card.id)}>
                            <i className="fa fa-trash"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card_box_head">
                      <p>**** **** **** {card.card_number}</p>
                      <ul className="d-flex card_action_btn_vs">
                        <li>
                          <span
                            onClick={() =>
                              handleEditCard(card.id, card.default_card)
                            }
                          >
                            <i
                              className="fa fa-pencil"
                              style={{
                                width: 35,
                                fontSize: 20,
                                paddingLeft: "15px",
                              }}
                            ></i>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="NotAvailableBox" style={{ width: "100%" }}>
              <p>No card found</p>
            </div>
          )}
          {showModal ? (
            <CardDeleteConfirmation
              modal={showModal}
              handleDeleteCard={handleDeleteCard}
              hideModal={hideModal}
              cardId={cardId}
              loading={submitLoading}
            />
          ) : null}
          {showAddModal ? (
            <AddCard
              modal={showAddModal}
              handleSubmitCard={handleSubmitCard}
              hideModal={hideAddModal}
              loading={submitLoading}
              initialValues={initialValues}
            />
          ) : null}
          {openEditCard && (
            <EditCard
              openEditCard={openEditCard}
              editCardId={editCardId}
              defaultCardStatus={defaultCardStatus}
              editcardLoading={editcardLoading}
              closedEditCard={() => setopenEditCard(false)}
              UpdateCard={UpdateCard}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    card: state.card.card,
  };
};

const mapDispatchToProps = {
  getCard,
  deleteCard,
  CardAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
/* Copyright Information: Created by BuyGroup */
