/* Copyright Information: Created by BuyGroup */
import React from "react";
import CategoryFilter from "./CategoryFilter";

const Sidebar = (props) => {
    return (
    <>
      <CategoryFilter
        category_id={props.category_id}
        minValue={props.minValue}
        maxValue={props.maxValue}
        zipCode={props.zipCode}
      />
    </>
  );
};

export default Sidebar;
/* Copyright Information: Created by BuyGroup */