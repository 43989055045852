/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const cartList = (selectAddressId) => {
  console.log(selectAddressId);
  if (selectAddressId !== undefined) {
    return Api.get(`cart/view?address_id=${selectAddressId}`)
      .then((res) => res.data)
      .catch((err) => console.log("error in cart list data ", err));
  } else {
    return Api.get("cart/view")
      .then((res) => res.data)
      .catch((err) => console.log("error in cart list data ", err));
  }
};

export const removeCart = (id) => {
  return Api.delete(`cart/${id}`)
    .then((res) => res.data)
    .catch((err) => console.log("error in remove cart ", err));
};

export const addCartList = (data) => {
  return Api.post("cart", data).then((res) => res.data);
};

export const updateCartList = (data) => {
  return Api.put("cart/update", data).then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
