/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Row, Col, Spinner } from "reactstrap";
import Product from "../components/product/Product";
import { getGroups } from "../services/HomeServices";
import Sidebar from "../sidebar/Sidebar";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      groupList: [],
      count: 0,
      isLoading: false,
      showLoading: false,
      paramsMin: null,
      paramsMax: null,
      price: { min: 0, max: null },
      category_id: props.match.params.id,
      zipCode: ''
    };
  }

  componentDidMount() {
    this.getGroupList();
    const params = new URLSearchParams(window.location.search);
    const paramsMin = params.get("min");
    const paramsMax = params.get("max");
    const zipCode = params.get("zipCode") || '';

    let price = { min: 0, max: null };
    if (paramsMax !== null) {
      price.max = paramsMax;
    }
    if (paramsMin !== null) {
      price.min = paramsMin;
    }
    this.setState({ price: price, zipCode });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getGroupList();
    }
  }
  // this function call api to get groups list
  getGroupList = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsMin = params.get("min");
    const paramsMax = params.get("max");
    const zipCode = params.get("zipCode") || "";
    this.setState({
      isLoading: true,
      paramsMin: paramsMin,
      paramsMax: paramsMax,
      category_id: this.props.match.params.id,
      zipCode: zipCode
    });
    let filterData = { limit: 12, offset: 0, zip_code: zipCode };
    if (paramsMax !== null) {
      filterData.max = paramsMax;
    }
    if (paramsMin !== null) {
      filterData.min = paramsMin;
    }
    getGroups(filterData)
      .then((response) => {
        if (response.data !== undefined) {
          this.setState({
            groupList: response.data,
            isLoading: false,
            count: response.data.length,
            offset: 0,
          });
        }
      })
      .catch((error) => {
        console.log("error in group List", error);
        this.setState({ isLoading: false });
      });
  };
  loadMore = () => {
    this.setState({ offset: this.state.offset + 1 }, () => {
      this.loadMoreGroupList();
    });
  };

  /* This Function will call api and Load More Group list */
  loadMoreGroupList = () => {
    this.setState({ showLoading: true });
    const params = new URLSearchParams(window.location.search);
    const paramsMin = params.get("min");
    const paramsMax = params.get("max");
    let zipCode = params.get("zipCode") || '';
    let filterData = {
      limit: 12,
      offset: this.state.offset,
      zip_code: zipCode,
    };
    if (paramsMax !== null) {
      filterData.max = paramsMax;
    }
    if (paramsMin !== null) {
      filterData.min = paramsMin;
    }
    getGroups(filterData)
      .then((response) => {
        if (response.data !== undefined) {
          let groupList = [...this.state.groupList];

          let newArray = groupList.concat(response.data);
          this.setState({
            groupList: newArray,
            showLoading: false,
            count: response.data.length,
          });
        }
      })
      .catch((error) => {
        console.log("error in group List", error);
        this.setState({ ishowLoadingsLoading: false });
      });
  };

  render() {
    return (
      <>
        <Row>
          <Col sm="3" className="collection-filter">
            <Sidebar
              minValue={this.state.paramsMin}
              maxValue={this.state.paramsMax}
              zipCode={this.state.zipCode}
            />
          </Col>
          <Col lg="9" sm="12" xs="12" className="product-grid-view">
            <div className="theme-card theme-card-icon">
              <h5 class=""></h5>
              <div className="hidden">
                <i className="fa fa-th grid-layout-view mr-2"></i>
                <i className="fa fa-list-ul list-layout-view"></i>
              </div>
            </div>
            {this.state.isLoading ? (
              <div className="custom-loader">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="product_listing">
                {/* <Col md="3">{this.state.paramsMin !==null||this.state.paramsMax!==null?"Price $ "+this.state.paramsMin +" To " +this.state.paramsMax:""}</Col> */}
                <div className="product-wrapper-grid">
                  {this.state.groupList.length > 0 ? (
                    <div className="row">
                      {this.state.groupList.map((group, index) => {
                        return <Product group={group} key={index} />;
                      })}
                    </div>
                  ) : (
                    <div className="NotAvailableBox">
                      <p>No Groups Available</p>
                    </div>
                  )}
                  {this.state.count === 12 && (
                    <div className="text-center">
                      <button
                        onClick={this.loadMore}
                        type="submit"
                        disabled={this.state.showLoading}
                        color="primary"
                        className="btn btn-solid"
                      >
                        {this.state.showLoading ? "loading..." : "Load More"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default HomePage;
/* Copyright Information: Created by BuyGroup */
