/* Copyright Information: Created by BuyGroup */
import * as Yup from "yup";
export const validationAddSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  phone_number: Yup.string()
    .max(13, "invalid phone number")
    .required("Please enter phone number"),
  street: Yup.string().required("Please enter street"),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  country: Yup.string().required("Please select country"),
  zip_code: Yup.number()
    .typeError("Must be a number")
    .required("Please enter zip code"),
});

export const validationUpdateSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  phone_number: Yup.string()
    .max(13, "invalid phone number")
    .required("Please enter phone number"),
  street: Yup.string().required("Please enter street"),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  country: Yup.string().required("Please enselect country"),
  zip_code: Yup.number()
    .typeError("Must be a number")
    .required("Please enter zip code")
   });
/* Copyright Information: Created by BuyGroup */