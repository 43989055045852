/* Copyright Information: Created by BuyGroup */
import "./App.scss";
import "./assets/scss/app.scss";
import Login from "./components/auth/login";
import Invoice from "./Invoice/Invoice";
import Register from "./components/auth/Register";
import ContactUsMobile from "./components/Pages/ContactUsMobile";
import FAQMobile from "./components/Pages/FAQMobile";
import PrivacyPolicyMobile from "./components/Pages/PrivacyPolicyMobile";
import TermsConditionMobile from "./components/Pages/TermsConditionMobile";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Home from "./components/common/Home";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import VerifyAccount from "./components/auth/VerifyAccount"
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import TermsCondition from "./components/Pages/TermsCondition";
function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/invoice" component={Invoice} />
          <Route exact path="/mobile/contactus" component={ContactUsMobile} />
          {/* <Route
            exact
            path="/mobile/termscondition"
            component={TermsConditionMobile}
          /> */}
          <Route
            exact
            path="/mobile/privacy-policy"
            component={PrivacyPolicyMobile}
          />
          <Route exact path="/mobile/faq" component={FAQMobile} />
         <Route exact path="/user/verify" component={VerifyAccount} /> 
         <Route exact path="/privacy-policy" component={PrivacyPolicy} />
         <Route exact path="/terms-of-use" component={TermsCondition} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
/* Copyright Information: Created by BuyGroup */