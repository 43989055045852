/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import { getProductDetail } from "../../services/ProductServices";
import ProductSlider from "./ProductSlider";
import Moment from "react-moment";
import Quantity from "./Quantity";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { editCart } from "../../actions/cart";
import { useHistory } from "react-router-dom";
toast.configure();

const ViewProduct = (props) => {
  
  /* Loader for product detail page */
  const [showLoader, setShowLoader] = useState(false);

  /* const "productData" store product detail*/
  const [productData, setProductData] = useState({});

  const history = useHistory();

  /**This Function Will give product detail
   * @param id string
   *  */

  const getProductDetailList = (id) => {
    setShowLoader(true);
    getProductDetail(id)
      .then((res) => {
        if (res.status === 200) {
          setProductData(res.data);
          setTimeout(() => {
            setShowLoader(false);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          toast.error(error.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setShowLoader(false);
          props.history.push("/app/products");
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetailList(props.match.params.id);
  }, []);

  const dateFormatter = (data) => <Moment format="MM/DD/YYYY">{data}</Moment>;

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {showLoader ? (
              <div className="custom-loader">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                <Row className="product-adding display-flex">
                  <Col className="col-md-5">
                    <div className="clearfix">
                      <ProductSlider
                        sliderImages={productData.productImage}
                        imageUrlKey="imageUrl"
                      />
                    </div>
                  </Col>
                  <Col className="col-md-7">
                    <h5>
                      <strong>{productData.product_name}</strong>
                    </h5>
                    <p
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {productData.description}
                    </p>
                    <Row>
                      <Col md="4" sm="6">
                        <div>
                          <h5>
                            <strong>Price </strong>
                          </h5>
                          <div className="clearfix product_detail_value">
                           
                             {productData.price ==
                            productData.discounted_price ? (
                      <h4>
                        $
                        {parseFloat(productData.price).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </h4>
                    ) : (
                      <div className="d-flex">
                        <h4>
                        <strong>
                          $
                          {parseFloat(
                            productData.discounted_price
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </strong>
                        </h4>
                        <h4 style={{ textDecorationLine: "line-through",textDecorationStyle: 'solid',marginLeft:"10px",color:"#777777" }}>
                          $
                          {parseFloat(productData.price).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                    )}
                          </div>
                        </div>
                      </Col>
                      <Col md="4" sm="6">
                        <div>
                          <h5>
                            <strong>Category</strong>
                          </h5>
                          <div className="clearfix product_detail_category">
                            <p>{productData.category}</p>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <h5>
                            <strong>Deal Ends On </strong>
                          </h5>
                          <div className="clearfix product_detail_category">
                            <p>
                              <strong>
                                {dateFormatter(productData.end_date)}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div>
                          <h5>
                            <strong>Total orders placed by all buyers</strong>
                          </h5>
                          <div className="clearfix product_detail_category">
                            <p>{productData.quantity_booked}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {productData.status !== "Closed" && (
                      <Quantity id={props.match.params.id} history={history} />
                    )}
                    {productData.documentUrl != undefined ? (
                      <Row>
                        <Col md="12">
                          <div>
                            <h5>
                              <strong>Document </strong>
                            </h5>
                            {productData.documentUrl.length ? (
                              <a
                                href={productData.documentUrl[0].path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {productData.document_file}
                              </a>
                            ) : (
                              "Document is not available"
                            )}
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <div>
                        <h5 className="mt-3">
                          <strong>Price Range </strong>
                        </h5>
                        <table className="table table-border table-striped">
                          <thead>
                            <tr>
                              <th>Order Quantity</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productData.levels !== undefined &&
                              productData.levels.map(
                                (item, index, elements) => {
                                  const levelObject = elements[index - 1];
                                  const levelCount =
                                    levelObject?.quantity_level + 1;

                                  const lastObject =
                                    elements[elements.length - 1];
                                  const lastcount = lastObject?.quantity_level;

                                  const currentGroup =
                                    productData.quantity_booked >=
                                      (index === 0 ? 0 : levelCount) &&
                                    productData.quantity_booked <=
                                      item.quantity_level;

                                  const lastcurrentGroup =
                                    lastcount < productData.quantity_booked &&
                                    lastcount === item.quantity_level;

                                  return (
                                    <tr
                                      className={
                                        currentGroup
                                          ? "currentGrouplevel"
                                          : lastcurrentGroup
                                          ? "currentGrouplevel"
                                          : ""
                                      }
                                    >
                                      <td>
                                        {index === 0 ? 1 : levelCount} -{" "}
                                        {item.quantity_level}
                                      </td>
                                      <td>
                                        $
                                        {parseFloat(
                                          item.discounted_price
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  editCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);
/* Copyright Information: Created by BuyGroup */