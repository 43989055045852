/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { getOrderDetails, customerDetail } from "../services/AccountServices";
import UpdateCustomer from "./UpdateCustomer";
import Cards from "./Cards/Cards";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import UserAddress from "./ManageAddress/UserAddress";
toast.configure();
const Account = (props) => {
  const userInitialValues = {
    address_type: "BILLING_ADDRESS",
    id: "",
    address_id: "",
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    street: "",
    zip_code: "",
    other_docs: [],
    is_tax_exempt: false,
  };

  /* const "activeTab" store active tab value */
  const [activeTab, setActiveTab] = useState("1");

  const [isLoading, setLoading] = useState(false);

  /* const "orderList" store order list  */
  const [orderList, setOrderList] = useState([]);

  /* const "initialValues" store user initialvalue data */
  const [initialValues, setUserInitialValues] = useState(userInitialValues);

  const [isDetailLoading, setDetailLoding] = useState(false);

  const [isErrorLoading, setIsErrorLoading] = useState(false);

  useEffect(() => {
    getorders();
    getCustomerDetail();
  }, []);

  /* This Function will give customer detail */
  const getCustomerDetail = () => {
    setDetailLoding(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const UserId = user.customer_id;
    customerDetail(UserId)
      .then((response) => {
        if (response.status === 200) {
          console.log("response", response);
          if (response.data.company_name === null) {
            response.data.company_name = "";
          }
          if (response.data.title === null) {
            response.data.title = "";
          }
          setUserInitialValues({
            ...response.data,
            tax_exempt_doc_value:
              response.data.tax_exempt_doc.length > 0 ? true : false,
            new_tax_exempt_doc: [],
            delete_tax_doc: [],
            new_other_docs: [],
            delete_other_docs: [],
          });
          setDetailLoding(true);
        }
      })
      .catch((error) => {
        toast.error(error, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsErrorLoading(true);
      });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  /* This function will give order detail of customer */
  const getorders = () => {
    setLoading(true);
    let data = { status: null };
    if (activeTab !== "2") setActiveTab("2");
    getOrderDetails(data)
      .then((response) => {
        if (response.status === 200) {
          setOrderList(response.data.order_list);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  /* This function truncateString the longer  string */

  const truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  return (
    <div className="row profile_tabs_vs">
      <div className="col-lg-3 col-md-12">
        <Nav tabs className="profileTab_uline">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Profile Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                getorders();
              }}
            >
              My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Saved Cards
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              Manage Shipping Address
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="col-lg-9 col-md-12">
        <TabContent activeTab={activeTab} className="pr-tab">
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                {isDetailLoading ? (
                  isErrorLoading ? (
                    <h4>No Data Found</h4>
                  ) : (
                    <UpdateCustomer initialValues={initialValues} />
                  )
                ) : (
                  <div className="custom-loader">
                    <Spinner animation="border" />
                  </div>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" className="myorder">
            {isLoading ? (
              <div className="custom-loader">
                <Spinner animation="border" />
              </div>
            ) : orderList.length > 0 ? (
              orderList.map((order, index) => {
                return (
                  <Row key={order.order_line_id}>
                    <Col sm="12">
                      <Card body className="mb-4">
                        <div className="product_order_grid">
                          <div className="p_order_img">
                            <img
                              className="img-responsive"
                              src={order.buy_group.product_image}
                              alt="not found"
                            />
                          </div>
                          <div className="prod_order_content">
                            <Link
                              to={`/product/${order.buy_group.buy_group_id}`}
                            >
                              <div
                                style={{
                                  color: "#ff4c3b",
                                  paddingBottom: "5px",
                                }}
                              >
                                {truncateString(
                                  order.buy_group.product_name,
                                  170
                                )}
                              </div>
                            </Link>
                            <div className="d-flex align-items-center flex-wrap">
                              <h4>
                                ${" "}
                                {parseFloat(order.price).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </h4>
                            </div>

                            <ul className="d-flex align-items-center flex-wrap">
                              <li>
                                <strong>Quantity:</strong> {order.quantity}
                              </li>
                              <li>
                                <strong>Tax:</strong> $
                                {parseFloat(order.tax_price).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </li>
                              <li>
                                <strong>Shipping:</strong> $
                                {parseFloat(order.shipping).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </li>
                              <li>
                                <strong>Total:</strong> $
                                {parseFloat(
                                  order.price * order.quantity +
                                    order.tax_price +
                                    parseInt(order.shipping)
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </li>
                            </ul>
                            <ul className="d-flex align-items-center flex-wrap">
                              <li>
                                <strong>Tracking Company:</strong>{" "}
                                {order.buy_group.tracking_company_name}
                              </li>
                              <li>
                                <strong>Tracking Number:</strong>{" "}
                                {order.buy_group.tracking_number}
                              </li>
                            </ul>
                            <ul className="d-flex align-items-center flex-wrap">
                              <li>
                                <strong>Address:</strong>{" "}
                                {order.buy_group.street +
                                  "," +
                                  order.buy_group.city +
                                  "," +
                                  order.buy_group.state +
                                  "," +
                                  order.buy_group.country +
                                  "," +
                                  order.buy_group.zip_code}
                              </li>
                            </ul>
                            <ul className="d-flex align-items-center flex-wrap">
                              <li>
                                <strong>Order Id:</strong> {order.order_id}
                              </li>
                            </ul>
                          </div>
                          <div className="prod_order_content_action">
                            <span
                              className={`product_order_status ${order.buy_group.status}`}
                            >
                              {order.buy_group.status !== "in_progress"
                                ? order.buy_group.status
                                : "In Progress"}
                            </span>
                            <span className="order_date">
                              <label>Order Date</label>
                              <p>
                                <Moment format="MM/DD/YYYY">
                                  {order.buy_group.date_created}
                                </Moment>
                              </p>
                            </span>
                            {order.invoice_id !== "NULL" &&
                            order.buy_group.status === "completed" ? (
                              <>
                                <a
                                  href={order.invoice_url}
                                  target="_blank"
                                  className="btn btn-xs btn-solid invoice_button"
                                >
                                  Invoice
                                </a>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div className="NotAvailableBox">
                <p>No Orders Available</p>
              </div>
            )}
          </TabPane>
          <TabPane tabId="3">
            <Cards />
          </TabPane>
          <TabPane tabId="4">
            <UserAddress />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default Account;
/* Copyright Information: Created by BuyGroup */
