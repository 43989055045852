/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { AddCart, getCart } from "../../actions/cart";
import { isLoggedIn } from "../../utils/index";
class Quantity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 1,
      lodingAddCart: false,
    };
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addQuantity = this.addQuantity.bind(this);
    this.addCartCallBack = this.addCartCallBack.bind(this);
  }

  /* increse the quantity by one */
  increment = () => {
    this.setState((prevState) => ({
      value: ++prevState.value,
    }));
  };

  /*  decress the quantity by one */
  decrement = () => {
    this.setState((prevState) => ({
      value: prevState.value > 1 ? --prevState.value : 1,
    }));
  };

  /* set the number of quantity */
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  /* This function will add quantity to card */
  addQuantity = (e) => {
    const { history } = this.props;

    if (isLoggedIn()) {
      this.setState({
        lodingAddCart: true,
      });

      const data = {
        qty: parseInt(this.state.value),
        group_id: this.props.id,
      };
      this.props.AddCart(data, this.props.id, this.addCartCallBack);
    } else {
      // alert("User not logged in");
      if (history) {
        history.push("/login");
      }
    }
  };

  addCartCallBack = (response, type) => {
    if (type === "ERROR") {
      toast.error(response, {
        autoClose: 3000,
        hideProgressBar: true,
      });
      this.setState({
        lodingAddCart: false,
      });
    } else {
      this.props.getCart();
      toast.success(response, {
        autoClose: 3000,
        hideProgressBar: true,
      });
      this.setState({
        lodingAddCart: false,
      });
    }
  };

  render() {
    const { lodingAddCart } = this.state;
    return (
      <Row className="mt-2">
        <Col lg="3" md="4">
          <div className="mb-3">
            <div className="clearfix">
              <div className="parent_qty_div">
                <div className="quantity-input">
                  <button
                    className="quantity-input__modifier quantity-input__modifier--left"
                    onClick={this.decrement}
                  >
                    &mdash;
                  </button>
                  <input
                    className="quantity-input__screen"
                    type="number"
                    defaultValue={this.state.value}
                    value={this.state.value}
                    name="quantity"
                    readOnly
                    onChange={(e) => this.handleChange(e)}
                  />
                  <button
                    className="quantity-input__modifier quantity-input__modifier--right"
                    onClick={this.increment}
                  >
                    &#xff0b;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="8" md="8">
          <div className="mb-3">
            <div className="clearfix">
              <button
                className="btn btn-solid addtocart_button"
                onClick={(e) => this.addQuantity(e)}
                disabled={lodingAddCart}
              >
                <i className="fa fa-shopping-cart pr-2" aria-hidden="true"></i>
                {lodingAddCart ? (
                  <div className="custom-loader">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  "Add to Cart"
                )}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  AddCart,
  getCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Quantity);
/* Copyright Information: Created by BuyGroup */
