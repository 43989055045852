/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCart } from "../actions/cart";

function PlaceOrderSuccess(props) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getCart(updateCartCountCallBack);
  }, []);

  const updateCartCountCallBack = () => {
    setIsLoading(true);
  };

  return (
    <div>
      {isLoading ? (
        <div className="success-text">
          <i className="fa fa-check-circle" aria-hidden="true"></i>
          <h2>Thank you</h2>
          <p>Payment is successfully processsed and your order is on the way</p>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = {
  getCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderSuccess);
/* Copyright Information: Created by BuyGroup */
